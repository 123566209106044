<template>
  <div class="login-container">
    <div class="surface-card p-4 shadow-2 border-round w-full lg:w-4 mx-2">
        <div class="text-center mb-5">
            <div class="text-900 text-3xl font-medium mb-3">{{ $_('Logging you in...') }}</div>
        </div>

        <div class="text-center mt-5 mb-2">
            <ProgressSpinner/>
        </div>

    </div>
  </div>
</template>
  
<script>

export default {
  name: "AuthorizeView",
  props: {
    token: String
  },
  data() {
    return {
      login: "",
      password: "",
      loginInvalid: false,
      passwordInvalid: false,
      error: "",
      loading: false,
    }
  },
  mounted() {
    const token = this.$route.params.token;
    this.$store.dispatch("redirect", {
        token
      }).then((data) => {
        if(data.page)
          this.$router.push({ name: data.page });
        else
            this.$router.push({ name: "domains" });
      }).catch(() => {
        this.$router.push({ name: "login" });
      });
  },
  methods: {
    loginClick() {
      this.loginInvalid = false;
      this.passwordInvalid = false;
      this.error = "";
      this.loading = false;

      if(!this.login.trim().length) {
        this.loginInvalid = true;
        return;
      }
      if(!this.password.trim().length) {
        this.passwordInvalid = true;
        return;
      }

      this.loading = true;
      this.$store.dispatch("login", {
        login: this.login,
        password: this.password,
      }).then(() => {
        this.loading = false;
        this.$router.push({ name: "domains" });
      }).catch((error) => {
        switch(error.code) {
          case 403:
            this.error = this.$_("Invalid login or password.");
            break;
          default:
            this.error = this.$_("Unknown error.");
        }
        this.loading = false;
      })
    },
  },
  watch: {
    login() {
      this.loginInvalid = false;
    },
    password() {
      this.passwordInvalid = false;
    },
  }
}
</script>


<style>
.login-container
{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>