<template>
<div>
    <Toast ref="toast"/>
    <Dialog 
        v-model:visible="visible" 
        modal
        :draggable="false"
        :header="$_('Add a CRON job')"
        class="w-full lg:w-4 mx-2"
        >
        <div v-if="error">
            <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
        </div>
        <div>
            <label class="block text-left font-semibold text-gray-700 mb-1">{{ $_('Job execution frequency') }}:</label>
            <Dropdown v-model="settings1" :options="cronTypes" :placeholder="$_('Select job frequency')" class="w-full" optionLabel="name" optionValue="setting">
            </Dropdown>
        </div>
        <div class="mt-3" v-if="settings1=='_'">
            <label for="settingField" class="block text-left font-semibold text-gray-700 mb-1">{{ $_('Custom job settings') }}:</label>
            <InputText
                class="w-full"
                id="settingField"
                type="text"
                v-model="settings2"
                :invalid="settingsInvalid"
                placeholder="* * * * *"
            />
            <div v-if="settingsInvalid" class="text-left text-red-500">
                <small>{{  $_('You need to enter a valid job frequency.') }}</small>
            </div>
        </div>
        <div class="mt-3">
            <label for="commandField" class="block text-left font-semibold text-gray-700 mb-1">{{ $_('Command to be executed') }}:</label>
            <InputText
                class="w-full"
                id="commandField"
                type="text"
                v-model="command"
                :invalid="commandInvalid"
                :placeholder="$_('/usr/bin/php8.1 domains/mydomain.com/public_html/index.php')"
                ref="input"
            />
            <div v-if="commandInvalid" class="text-left text-red-500">
                <small>{{  $_('You need to enter a valid Linux command.') }}</small>
            </div>
        </div>
        <div class="flex justify-content-between gap-2 mt-4">
            <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"></Button>
            <Button type="button" :label="$_('Add job')" @click="action" :loading="loading"></Button>
        </div>
    </Dialog>
</div>
</template>
    
<script>
    
    import { cron } from "../../mixins/cron.js";

    export default {
      name: "CronAddDialog",
      mixins: [ cron ],
      data() {
        return {
          visible: false,
          settings1: "",
          settings2: "",
          settingsInvalid: false,
          command: "",
          commandInvalid: false,
          loading: false,
          error: "",
        }
      },
      computed: {
        types() {
            var ret = [{ label: "MySQL", value: "mysql" }];
            if(this.$store.getters["postgresAvailable"])
                ret.push({ label: "Postgres", value: "postgres" });
            return ret;
        }
      },
      methods: {
        show() {
            this.settings1 = "0 * * * *";
            this.settings2 = "0 * * * *";
            this.command = "";
            this.setttingsInvalid = false;
            this.commandInvalid = false;
            this.visible = true;
            this.error = "";
            this.$nextTick(() => {
                this.$refs["input"].$el.focus();
            });
        },
        hide() {
            this.visible = false;
        },
        action() {
            this.settingsInvalid = false;
            this.commandInvalid = false;
            this.error = "";
            
            if(!this.settings2.trim().length /*|| !this.settings2.trim().match(/^\s*($|#|\w+\s*=|(\*(?:\/\d+)?|(?:[0-5]?\d)(?:-(?:[0-5]?\d)(?:\/\d+)?)?(?:,(?:[0-5]?\d)(?:-(?:[0-5]?\d)(?:\/\d+)?)?)*)\s+(\*(?:\/\d+)?|(?:[01]?\d|2[0-3])(?:-(?:[01]?\d|2[0-3])(?:\/\d+)?)?(?:,(?:[01]?\d|2[0-3])(?:-(?:[01]?\d|2[0-3])(?:\/\d+)?)?)*)\s+(\*(?:\/\d+)?|(?:0?[1-9]|[12]\d|3[01])(?:-(?:0?[1-9]|[12]\d|3[01])(?:\/\d+)?)?(?:,(?:0?[1-9]|[12]\d|3[01])(?:-(?:0?[1-9]|[12]\d|3[01])(?:\/\d+)?)?)*)\s+(\*(?:\/\d+)?|(?:[1-9]|1[012])(?:-(?:[1-9]|1[012])(?:\/\d+)?)?(?:,(?:[1-9]|1[012])(?:-(?:[1-9]|1[012])(?:\/\d+)?)?)*|jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)\s+(\*(?:\/\d+)?|(?:[0-6])(?:-(?:[0-6])(?:\/\d+)?)?(?:,(?:[0-6])(?:-(?:[0-6])(?:\/\d+)?)?)*|mon|tue|wed|thu|fri|sat|sun)\s+|(@reboot|@yearly|@annually|@monthly|@weekly|@daily|@midnight|@hourly)\s+)([^\s]+)\s+(.*)$/)*/) {
                this.settingsInvalid = true;
                return;
            }
            if(!this.command.trim().length) {
                this.commandInvalid = true;
                return;
            }
    
            this.loading = true;
            this.$store.dispatch("cronAdd", { 
                settings: this.settings2,
                command: this.command
            }).then(() => {
                this.loading = false;
                this.visible = false;
                this.$refs["toast"].add({
                    severity: 'success', 
                    summary: this.$_("Operation performed successfully."), 
                    detail: this.$_("CRON job was added."), 
                    life: 3000
                });
            }).catch((error) => {
                switch(error.code) {
                    default:
                        this.error = this.$_("Unknown error.");
                }
                this.loading = false;
            });
        }
      },
      watch: {
        settings1(value) {
            if(value != "_") this.settings2 = value;
        },
        settings2() { this.settingsInvalid = false; },
        command() { this.commandInvalid = false; },
      }
    }
    </script>