<template>
    <div>
        <Toast ref="toast"/>
        <Dialog
            v-model:visible="visible" 
            modal
            :draggable="false"
            :header="$_('Remove the Web app')"
            class="w-full lg:w-4 mx-2"
            >
            <div v-if="error">
                <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
            </div>
            <label class="block text-left font-semibold text-gray-700 mb-2">{{ $_('Web app address') }}:</label>
            <div>
                {{ 'http://' + domain + folder }}
            </div>
            <div class="flex justify-content-between gap-2 mt-4">
                <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"/>
                <Button type="button" :label="$_('Remove Web app')" severity="danger" @click="action" :loading="loading"/>
            </div>
        </Dialog>
    </div>
    </template>
        
<script>

export default {
    name: "ProxyCreateDialog",
    data() {
        return {
          visible: false,
          domain: "",
          folder: "",
          loading: false,
          error: "",
        }
      },
      methods: {
        show(domain = "", folder = "") {
            this.domain = domain;
            this.folder = folder;
            this.visible = true;
            this.error = "";
        },
        hide() {
            this.visible = false;
        },
        action() {
            this.error = "";

            this.loading = true;
            this.$store.dispatch("proxyDelete", { 
                domain: this.domain,
                directory: this.folder,
            }).then(() => {
                this.loading = false;
                this.visible = false;
                this.$refs["toast"].add({
                    severity: 'success', 
                    summary: this.$_("Operation performed successfully."), 
                    detail: this.$_("Web app was removed") + ": http://" + this.domain + this.folder, 
                    life: 3000
                });
            }).catch((error) => {
                switch(error.code) {
                    default:
                        this.error = this.$_("Unknown error.");
                }
                this.loading = false;
            });
        }
      },
      watch: {
        folder() { this.folderInvalid = false; },
        login() { this.loginInvalid = false; },
        password() { this.passwordInvalid = false; },
        password2() { this.password2Invalid = false; },
      }
    }
</script>

<style>
.install-step {
    min-height: 250px;
}
</style>