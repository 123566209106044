<template>
<ViewRoot active="ssl">
  <DataTable :value="$store.getters['domains'].filter((item) => item.domain.includes(this.filter.trim().toLowerCase()))">
    <template #header>
      <IconField iconPosition="left" class="w-full">
        <InputIcon>
          <i class="pi pi-search"></i>
        </InputIcon>
        <InputText
          class="w-full"
          v-model="filter"
          :placeholder="$_('Search domains...')"
        />
      </IconField>
    </template>
    <template #empty>
      <div>{{  $_('No domain names match the search criteria.') }}</div>
    </template>
    <Column :header="$_('Domain name')">
      <template #body="slot">
        <div class="flex align-items-center">
          <i class="pi pi-lock-open text-gray-300 mr-2 md:hidden" v-if="slot.data.sslType == 'auto'"></i>
          <i class="pi pi-lock text-blue-500 mr-1 md:hidden" v-if="slot.data.sslType == 'letsencrypt'"></i>
          <i class="pi pi-lock text-green-500 mr-1 md:hidden" v-if="slot.data.sslType == 'custom'"></i>
          <div class="ellipsis ellipsis-250">{{ slot.data.domain }}</div>
        </div>
      </template>
    </Column>
    <Column :header="$_('Certificate status')" headerClass="hidden md:cell" bodyClass="hidden md:cell">
      <template #body="slot">
        <div v-if="slot.data.sslType == 'auto'">
          <i class="pi pi-lock-open text-gray-300 mr-2"></i>
          <span class="text-gray-300">{{ $_('Not generated') }}</span>
        </div>
        <div v-if="slot.data.sslType == 'letsencrypt'">
          <i class="pi pi-lock text-blue-500 mr-1"></i>
          {{ $_('Certificate generated') }}
        </div>
        <div v-if="slot.data.sslType == 'custom'">
          <i class="pi pi-lock text-green-500 mr-1"></i>
          {{ $_('Certificate uploaded') }}
        </div>
      </template>
    </Column>
    <Column :header="$_('Expires')" headerClass="hidden xl:cell" bodyClass="hidden xl:cell">
      <template #body="slot">
        <div v-if="slot.data.sslType != 'auto'">
          {{ slot.data.sslExpires.substr(0, 10) }}
        </div>
      </template>
    </Column>
    <Column>
      <template #body="slot">
        <div class="table-buttons hidden lg:block">
          <Button 
            :label="$_('Certificate operations')"
            severity="secondary"
            size="small"
            icon="pi pi-chevron-down"
            iconPos="right"
            @click="showMenu($event, slot.data.domain, true)"
          />
        </div>
        <a v-ripple class="cursor-pointer block lg:hidden text-700 p-ripple text-right" @click="showMenu($event, slot.data.domain, false)">
          <i class="pi pi-ellipsis-v"></i>
        </a>
      </template>
    </Column>
  </DataTable>
  <div class="text-left mt-3 mx-3 flex flex-column sm:flex-row">
    <Button
      :label="$_('Upload a certificate')"
      icon="pi pi-upload"
      @click="$refs['certificateUpload'].show()"
    />
    <Button
      severity="secondary"
      class="sm:ml-2 mt-2 sm:mt-0"
      :label="$_('Buy a certificate')"
      icon="pi pi-external-link"
      iconPos="right"
      @click="redirCertificate"
      />
  </div>
  <ContextMenu ref="contextMenu" :model="contextMenu"/>
  <CertificateGenerateDialog ref="certificateGenerate"/>
  <CertificateUploadDialog ref="certificateUpload"/>
</ViewRoot>
</template>

<script>

import ViewRoot from "../components/ViewRoot.vue";

import CertificateGenerateDialog from "../dialogs/certificate/CertificateGenerateDialog.vue";
import CertificateUploadDialog from "../dialogs/certificate/CertificateUploadDialog.vue";

export default {
  name: "SslView",
  components: {
    ViewRoot,
    CertificateGenerateDialog,
    CertificateUploadDialog,
  },
  mounted() {
    const redirect = this.$store.getters["redirect"];
    switch(redirect.action) {
      case "ssl":
        this.$refs["certificateUpload"].show();
        break;
    }
    this.$store.commit("redirectClear");
    this.$store.dispatch("keepalive");
  },
  data() {
    return {
      filter: "",
      contextDomain: "",
      contextMenu: [],
    }
  },
  methods: {
    showMenu(event, domain) {
      this.contextDomain = domain;

      this.contextMenu = [];
      this.contextMenu.push({ label: this.$_("Upload your certificate"), icon: "pi pi-upload", command: () => this.$refs["certificateUpload"].show(this.contextDomain) });

      var hasLetsencrypt = true;
      this.$store.getters["domains"].forEach((item) => {
        if(item.domain == domain) hasLetsencrypt = item.sslType == 'letsencrypt';
      });
      if(!hasLetsencrypt) {
        this.contextMenu.push({ label: this.$_("Generate free certificate"), icon: "pi pi-plus", command: () => this.$refs["certificateGenerate"].show(this.contextDomain) });
      }
      this.$refs["contextMenu"].show(event);
    },
    redirCertificate() {
      window.open(this.$store.getters["upsell"].certificate, "_blank");
    },
  }
}

</script>

<style>
.table-buttons {
  margin: -0.75rem 0;
  text-align: right;
}
</style>