<template>
    <div>
    <Toast ref="toast"/>
    <Dialog 
        v-model:visible="visible" 
        modal
        :draggable="false"
        :header="$_('Change the account password')"
        class="w-full lg:w-4 mx-2"
        >
        <div v-if="error">
            <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
        </div>
        <div>
            <label for="nameField" class="block text-left font-semibold text-gray-700 mb-2">{{ $_('E-mail address') }}:</label>
            <div>
                {{ name + '@' + domain }}
            </div>
        </div>
        <div class="mt-3">
            <label for="passwordField" class="block text-left font-medium mb-1 mt-3">{{ $_('Account password') }}:</label>
            <InputGroup>
                <InputText
                    id="passwordField"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="password"
                    :invalid="passwordInvalid"
                    class="w-full"
                    ref="input"
                />
                <Button severity="secondary" icon="pi pi-eye" @click="showPassword=true" v-if="!showPassword"/>
                <Button severity="secondary" icon="pi pi-eye-slash" @click="showPassword=false" v-if="showPassword"/>
            </InputGroup>
            <div v-if="passwordInvalid" class="text-left text-red-500">
                <small>{{  $_('Password must be at least 8 characters long.') }}</small>
            </div>
            <label for="password2Field" class="block text-left font-medium mb-1 mt-3">{{ $_('Repeat the password') }}:</label>
            <InputGroup>
                <InputText
                    id="password2Field"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="password2"
                    :invalid="password2Invalid"
                    class="w-full"
                />
                <Button severity="secondary" icon="pi pi-eye" @click="showPassword=true" v-if="!showPassword"/>
                <Button severity="secondary" icon="pi pi-eye-slash" @click="showPassword=false" v-if="showPassword"/>
            </InputGroup>
            <div v-if="password2Invalid" class="text-left text-red-500">
                <small>{{  $_('The passwords do not match.') }}</small>
            </div>
            <div class="mt-1">
                <Button severity="secondary" icon="pi pi-pencil" :label="$_('Generate password')" size="small" @click="generatePassword"/>
            </div>
        </div>
        <div class="flex justify-content-between gap-2 mt-4">
            <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"></Button>
            <Button type="button" :label="$_('Change password')" @click="action" :loading="loading"></Button>
        </div>
    </Dialog>
    </div>
</template>
    
<script>
    
    import { password } from "../../mixins/password.js";

    export default {
      name: "AccountPasswordDialog",
      mixins: [ password ],
      data() {
        return {
          visible: false,
          domain: "",
          name: "",
          loading: false,
          error: "",
        }
      },
      methods: {
        show(domain, name) {
            this.domain = domain;
            this.name = name;
            this.password = "";
            this.passwordInvalid = false;
            this.password2 = "";
            this.password2Invalid = false;
            this.showPassword = false;
            this.visible = true;
            this.error = "";
            this.$nextTick(() => {
                this.$refs["input"].$el.focus();
            });
        },
        hide() {
            this.visible = false;
        },
        action() {
            this.passwordInvalid = false;
            this.password2Invalid = false;
            this.error = "";
            
            if(this.password.trim().length < 8) {
                this.passwordInvalid = true;
                return;
            }            
            if(this.password2.trim() != this.password.trim()) {
                this.password2Invalid = true;
                return;
            }

            this.loading = true;
            this.$store.dispatch("emailAccountPassword", { 
                domain: this.domain,
                name: this.name,
                password: this.password
            }).then(() => {
                this.loading = false;
                this.visible = false;
                this.$refs["toast"].add({
                    severity: 'success', 
                    summary: this.$_("Operation performed successfully."), 
                    detail: this.$_("E-mail account password was changed") + ": " + this.name + "@" + this.domain, 
                    life: 3000
                });
            }).catch((error) => {
                switch(error.code) {
                    default:
                        this.error = this.$_("Unknown error.");
                }
                this.loading = false;
            });
        }
      },
      watch: {
        password() { this.passwordInvalid = false; },
        password2() { this.password2Invalid = false; },
      }
    }
    </script>