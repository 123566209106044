<template>
<ViewRoot active="advanced">
  <div class="hidden md:block">
    <TabMenu :model="tabMenu" :activeIndex="currentTab"/>
  </div>
  <div class="block md:hidden">
    <TabMenu :model="mobileMenu" :activeIndex="currentTab"/>
  </div>
  <div v-if="currentTab == 0">
      <DataTable :value="$store.getters['domains'].filter((item) => item.domain.includes(this.filter.trim().toLowerCase()))">
        <template #header>
          <IconField iconPosition="left" class="w-full">
            <InputIcon>
              <i class="pi pi-search"></i>
            </InputIcon>
            <InputText
              class="w-full"
              v-model="filter"
              :placeholder="$_('Search domains...')"
            />
          </IconField>
        </template>
        <template #empty>
          <div>{{  $_('No domain names match the search criteria.') }}</div>
        </template>
          <Column :header="$_('Domain name')">
            <template #body="slot">
                <div class="flex">
                    <div class="ellipsis ellipsis-400">{{ slot.data.domain }}</div>
                </div>
            </template>
          </Column>
          <Column field="phpVersion" :header="$_('PHP version')" headerClass="hidden lg:cell"></Column>
          <Column>
          <template #body="slot">
              <div class="table-buttons hidden lg:block">
              <Button 
                  :label="$_('Change PHP version')"
                  severity="secondary"
                  size="small"
                  icon="pi pi-sliders-h"
                  @click="$refs['domainPHP'].show(slot.data.domain)"
              />
              </div>
              <a v-ripple class="cursor-pointer block lg:hidden text-700 p-ripple text-right" @click="showMenu($event, slot.data.domain)">
              <i class="pi pi-ellipsis-v"></i>
              </a>
          </template>
          </Column>
      </DataTable>
    </div>
    <div v-if="currentTab == 1">
      <DataTable :value="$store.getters['cronJobs']" v-if="$store.getters['cronJobs'].length">
        <Column :header="$_('Frequency')" headerClass="hidden md:cell" bodyClass="hidden md:cell">
            <template #body="slot">
              {{ cronType(slot.data.settings) }}
            </template>
        </Column>
        <Column field="command" :header="$_('Command')">
        </Column>
        <Column>
          <template #body="slot">
            <div class="table-buttons hidden lg:block">
            <Button 
                :label="$_('CRON job operations')"
                severity="secondary"
                size="small"
                icon="pi pi-chevron-down"
                iconPos="right"
                @click="showCronMenu($event, slot.data.id, true)"
            />
            </div>
            <a v-ripple class="cursor-pointer block lg:hidden text-700 p-ripple text-right" @click="showCronMenu($event, slot.data.id, false)">
                <i class="pi pi-ellipsis-v"></i>
            </a>
          </template>
        </Column>
      </DataTable>
      <p v-else class="ml-3">
        {{  $_('You have not added any CRON jobs yet.') }}
      </p>
      <div class="text-left mt-3 mx-3 flex flex-column sm:flex-row">
        <Button
          :label="$_('Add a new CRON job')"
          icon="pi pi-plus"
          @click="$refs['cronAdd'].show()"
        />
      </div>
    </div>
    <div v-if="currentTab == 2">
      <div v-if="!$store.getters['sshAvailable']" class="p-3">
          <p class="mt-0 mb-2">
              {{  $_('Your current plan does not include Web apps.') }}
          </p>
          <Button severity="secondary" :label="$_('Upgrade your plan')" icon="pi pi-external-link" iconPos="right" @click="upgradePackage"/>
      </div>
      <div v-else>
        <DataTable :value="$store.getters['proxies']" v-if="$store.getters['proxies'].length">
          <Column :header="$_('Web app address')">
              <template #body="slot">
                  <div class="flex align-items-center">
                      <a :href="'http://' + slot.data.domain + slot.data.directory" target="_blank" class="text-primary">
                        <span class="hidden sm:inline">http://</span>{{ slot.data.domain + slot.data.directory }}
                      </a>
                      <CopyIcon :text="'http://' + slot.data.domain + slot.data.directory" :message="$_('Website address copied to clipboard.')"/>
                  </div>
              </template>
          </Column>
          <Column field="port" :header="$_('Port number')" headerClass="hidden md:cell" bodyClass="hidden md:cell">
          </Column>
          <Column>
            <template #body="slot">
              <div class="table-buttons hidden lg:block">
              <Button 
                  :label="$_('Web app operations')"
                  severity="secondary"
                  size="small"
                  icon="pi pi-chevron-down"
                  iconPos="right"
                  @click="showAppMenu($event, slot.data.domain, slot.data.directory, true)"
              />
              </div>
              <a v-ripple class="cursor-pointer block lg:hidden text-700 p-ripple text-right" @click="showAppMenu($event, slot.data.domain, slot.data.directory, false)">
                  <i class="pi pi-ellipsis-v"></i>
              </a>
            </template>
          </Column>
        </DataTable>
        <p v-else class="ml-3">
          {{  $_('You have not added any Web apps yet.') }}
        </p>
        <div class="text-left mt-3 mx-3 flex flex-column sm:flex-row">
          <Button
            :label="$_('Add a new Web app')"
            icon="pi pi-plus"
            @click="$refs['proxyCreate'].show()"
          />
        </div>
      </div>
    </div>
    <ContextMenu ref="contextMenu" :model="contextMenu"/>
    <DomainPHPDialog ref="domainPHP"/>
    <CronAddDialog ref="cronAdd"/>
    <CronRemoveDialog ref="cronRemove"/>
    <CronModifyDialog ref="cronModify"/>
    <ProxyCreateDialog ref="proxyCreate"/>
    <ProxyDeleteDialog ref="proxyDelete"/>
    <ProxyModifyDialog ref="proxyModify"/>
</ViewRoot>
</template>

<script>

import ViewRoot from "../components/ViewRoot.vue";
import CopyIcon from "../components/CopyIcon.vue";

import DomainPHPDialog from "../dialogs/domain/DomainPHPDialog.vue";
import CronAddDialog from "../dialogs/cron/CronAddDialog.vue";
import CronRemoveDialog from "../dialogs/cron/CronRemoveDialog.vue";
import CronModifyDialog from "../dialogs/cron/CronModifyDialog.vue";
import ProxyCreateDialog from "../dialogs/proxy/ProxyCreateDialog.vue";
import ProxyDeleteDialog from "../dialogs/proxy/ProxyDeleteDialog.vue";
import ProxyModifyDialog from "../dialogs/proxy/ProxyModifyDialog.vue";

import { cron } from "../mixins/cron.js";
import { upgrade } from "../mixins/upgrade.js";

export default {
  name: "AdvancedView",
  components: {
    ViewRoot,
    CopyIcon,
    DomainPHPDialog,
    CronAddDialog,
    CronRemoveDialog,
    CronModifyDialog,
    ProxyCreateDialog,
    ProxyDeleteDialog,
    ProxyModifyDialog,
  },
  mixins: [ cron, upgrade ],
  mounted() {
    const redirect = this.$store.getters["redirect"];
    switch(redirect.action) {
      case "php":
        this.currentTab = 0;
        this.$refs["domainPHP"].show(redirect.domain);
        break;
      case "cron":
        this.currentTab = 1;
        break;
      case "apps":
        this.currentTab = 2;
        break;
    }
    this.$store.commit("redirectClear");
    this.$store.dispatch("keepalive");
  },
  data() {
    return {
      filter: "",
      currentTab: 0,
      contextDomain: "",
      contextDirectory: "",
      contectId: 0,
      contextMenu: [],
    }
  },
  computed: {
    tabMenu() {
      return [
        { label: this.$_("PHP version"), command: () => this.currentTab = 0 },
        { label: this.$_("CRON jobs"), command: () => this.currentTab = 1 },
        { label: this.$_("Web apps"), command: () => this.currentTab = 2 },
      ];
    },
    mobileMenu() {
      return [
        { label: this.$_("PHP"), command: () => this.currentTab = 0 },
        { label: this.$_("CRON"), command: () => this.currentTab = 1 },
        { label: this.$_("Apps"), command: () => this.currentTab = 2 },
      ];
    },
  },
  methods: {
    showCronMenu(event, id) {
      this.contextId = id;

      this.contextMenu = [];
      this.contextMenu.push({ label: this.$_("Modify CRON job"), icon: "pi pi-pencil", command: () => this.$refs["cronModify"].show(this.contextId) });
      this.contextMenu.push({ label: this.$_("Remove CRON job"), icon: "pi pi-trash", command: () => this.$refs["cronRemove"].show(this.contextId) });
      this.$refs["contextMenu"].show(event);
    },
    showAppMenu(event, domain, directory) {
      this.contextDomain = domain;
      this.contextDirectory = directory;

      this.contextMenu = [];
      this.contextMenu.push({ label: this.$_("Modify Web app"), icon: "pi pi-pencil", command: () => this.$refs["proxyModify"].show(this.contextDomain, this.contextDirectory) });
      this.contextMenu.push({ label: this.$_("Remove Web app"), icon: "pi pi-trash", command: () => this.$refs["proxyDelete"].show(this.contextDomain, this.contextDirectory) });
      this.$refs["contextMenu"].show(event);
    },
  }
}

</script>
