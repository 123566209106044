<template>
<ViewRoot active="access">
    <div class="hidden md:block">
        <TabMenu :model="tabMenu" :activeIndex="currentTab"/>
    </div>
    <div class="block md:hidden">
        <TabMenu :model="mobileMenu" :activeIndex="currentTab"/>
    </div>
    <div v-if="currentTab == 0" class="p-3">
        <div class="flex flex-column lg:flex-row">
            <div class="w-12 lg:w-4">
                <label class="block text-left font-semibold text-gray-700 mb-2">{{ $_('FTP server') }}:</label>
                <div class="text-left">
                    {{  $store.getters["ftpHost"] }}
                    <CopyIcon :text="$store.getters['ftpHost']" :message="$_('FTP server name copied to clipboard.')"/>
                </div>
            </div>
            <div class="w-12 lg:w-4 mt-3 lg:mt-0">
                <label class="block text-left font-semibold text-gray-700 mb-2">{{ $_('FTP user') }}:</label>
                <div class="text-left">
                    {{  $store.getters["ftp"]["login"] }}
                    <CopyIcon :text="$store.getters['ftp']['login']" :message="$_('FTP user name copied to clipboard.')"/>
                </div>
            </div>
            <div class="w-12 lg:w-4 mt-3 lg:mt-0">
                <label class="block text-left font-semibold text-gray-700 mb-1">{{ $_('FTP password') }}:</label>
                <div class="text-left">
                    <Button
                        size="small"
                        severity="secondary"
                        icon="pi pi-unlock"
                        :label="$_('Change password')"
                        @click="$refs['ftpPassword'].show()"
                    />
                </div>
            </div>
        </div>
    </div>
    <div v-if="currentTab == 1">
        <div v-if="!$store.getters['sshAvailable']" class="p-3">
            <p class="mt-0 mb-2">
                {{  $_('Your current plan does not include SSH access.') }}
            </p>
            <Button severity="secondary" :label="$_('Upgrade your plan')" icon="pi pi-external-link" iconPos="right" @click="upgradePackage"/>
        </div>
      <div v-else-if="!$store.getters['isPaid']">
        <p class="ml-3">
          {{  $_('To use SSH, you need to pay for your hosting for the first year.') }}
        </p>
        <div class="text-left mt-2 mx-3 flex flex-column sm:flex-row">
          <Button
            :label="$_('Pay for your hosting')"
            icon="pi pi-shopping-cart"
            @click="payPackage()"
          />
        </div>
      </div>
      <div v-else>
        <div class="flex flex-column lg:flex-row pt-3 px-3 pb-2">
            <div class="w-12 lg:w-4">
                <label class="block text-left font-semibold text-gray-700 mb-2">{{ $_('SSH server') }}:</label>
                <div class="text-left">
                    {{  $store.getters["sshHost"] }}
                    <CopyIcon :text="$store.getters['sshHost']" :message="$_('SSH server name copied to clipboard.')"/>
                </div>
            </div>
            <div class="w-12 lg:w-4 mt-3 lg:mt-0">
                <label class="block text-left font-semibold text-gray-700 mb-2">{{ $_('SSH user') }}:</label>
                <div class="text-left">
                    {{  $store.getters["login"] }}
                    <CopyIcon :text="$store.getters['login']" :message="$_('SSH user name copied to clipboard.')"/>
                </div>
            </div>
        </div>
        <DataTable :value="$store.getters['sshKeys']" v-if="$store.getters['sshKeys'].length">
          <Column field="name" :header="$_('Key name')"/>
          <Column field="fingerprint" :header="$_('Key fingerprint')" headerClass="hidden md:cell" bodyClass="hidden md:cell">
          </Column>
          <Column>
            <template #body="slot">
              <div class="table-buttons hidden lg:block">
                  <Button 
                    :label="$_('Remove SSH key')"
                    severity="secondary"
                    size="small"
                    icon="pi pi-trash"
                    @click="$refs['keyDelete'].show(slot.data.id)"
                  />
                </div>
                <a v-ripple class="cursor-pointer block lg:hidden text-700 p-ripple text-right" @click="$refs['keyDelete'].show(slot.id)">
                  <i class="pi pi-trash"></i>
                </a>
            </template>
          </Column>
        </DataTable>
        <p v-else class="ml-3">
          {{  $_('To log in using SSH, you need to add your public SSH keys.') }}
        </p>
        <div class="text-left mt-2 mx-3 flex flex-column sm:flex-row">
          <Button
            :label="$_('Add a new SSH key')"
            icon="pi pi-plus"
            @click="$refs['keyUpload'].show()"
          />
        </div>
      </div>
    </div>
    <FtpPasswordDialog ref="ftpPassword"/>
    <KeyUploadDialog ref="keyUpload"/>
    <KeyDeleteDialog ref="keyDelete"/>
</ViewRoot>
</template>

<script>

import FtpPasswordDialog from "../dialogs/ftp/FtpPasswordDialog.vue";
import KeyUploadDialog from "../dialogs/sshkey/KeyUploadDialog.vue";
import KeyDeleteDialog from "../dialogs/sshkey/KeyDeleteDialog.vue";

import ViewRoot from "../components/ViewRoot.vue";
import CopyIcon from "../components/CopyIcon.vue";

import { upgrade } from "../mixins/upgrade.js";

export default {
  name: "AccessView",
  components: {
    ViewRoot,
    CopyIcon,
    FtpPasswordDialog,
    KeyUploadDialog,
    KeyDeleteDialog,
  },
  mixins: [ upgrade ],
  mounted() {
    const redirect = this.$store.getters["redirect"];
    switch(redirect.action) {
      case "ftp":
        this.currentTab = 0;
        break;
      case "ssh":
        this.currentTab = 1;
        break;
    }
    this.$store.commit("redirectClear");
    this.$store.dispatch("keepalive");
  },
  data() {
    return {
        currentTab: 0,
    }
  },
  computed: {
    tabMenu() {
      return [
        { label: this.$_("FTP access"), command: () => this.currentTab = 0 },
        { label: this.$_("SSH access"), command: () => this.currentTab = 1 },
      ];
    },
    mobileMenu() {
      return [
        { label: this.$_("FTP"), command: () => this.currentTab = 0 },
        { label: this.$_("SSH"), command: () => this.currentTab = 1 },
      ];
    },
  },
}

</script>
