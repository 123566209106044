<template>
    <div>
    <Toast ref="toast"/>
    <Dialog 
        v-model:visible="visible" 
        modal
        :draggable="false"
        :header="$_('Uninstall the script')"
        class="w-full lg:w-4 mx-2"
        >
        <div v-if="error">
            <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
        </div>
        <div v-else>
            <Message severity="info" class="mt-0" :closable="false">
                {{  $_('The website and its database contents will be irrevocably deleted!') }}
            </Message>
        </div>
        <div>
            <label class="block text-left font-semibold text-gray-700 mb-2">{{ $_('Website address') }}:</label>
            <div>
                <a :href="'http://' + item.domain + item.directory" target="_blank" class="text-primary">
                  http://{{ item.domain + item.directory }}
                </a>
            </div>
        </div>
        <div class="mt-3">
            <div class="flex align-items-center">
                <Checkbox v-model="remove" inputId="removeDomain" name="remove" value="true"></Checkbox>
                <label for="removeDomain" class="ml-2">{{  $_('Yes, remove the website and database data.') }}</label>
            </div>
        </div>
        <div class="flex justify-content-between gap-2 mt-4">
            <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"></Button>
            <Button type="button" :label="$_('Uninstall script')" severity="danger" :disabled="!remove.length" @click="action" :loading="loading"></Button>
        </div>
    </Dialog>
    </div>
    </template>
    
    <script>
    export default {
      name: "ScriptUninstallDialog",
      data() {
        return {
          visible: false,
          id: "",
          item: null,
          remove: [],
          loading: false,
          error: "",
        }
      },
      methods: {
        show(id) {
            this.id = id;
            this.item = this.$store.getters["scripts"].find((item) => item.id == id);
            this.remove = [];
            this.error = "";
            this.visible = true;
        },
        hide() {
            this.visible = false;
        },
        action() {
            this.error = "";
            
            this.loading = true;
            this.$store.dispatch("scriptUninstall", { 
                id: this.id
            }).then(() => {
                this.loading = false;
                this.visible = false;
                this.$refs["toast"].add({
                    severity: 'success', 
                    summary: this.$_("Operation performed successfully."), 
                    detail: this.$_("Domain was removed") + ": " + this.domain, 
                    life: 3000
                });
            }).catch((error) => {
                switch(error.code) {
                    default:
                        this.error = this.$_("Unknown error.");
                }
                this.loading = false;
            });
        }
      },
    }
    </script>