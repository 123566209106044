<template>
    <div>
    <Toast ref="toast"/>
    <Dialog 
        v-model:visible="visible" 
        modal
        :draggable="false"
        :header="$_('Change e-mail monitoring')"
        class="w-full lg:w-5 mx-2"
        >
        <div v-if="error">
            <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
        </div>
        <div>
            <div class="mb-3">
                {{  $_('E-mail monitoring allows you to send a copy of all outgoing e-mail messages to a specified address.') }}
            </div>
            <label class="block text-left font-semibold text-gray-700 mb-2">{{ $_('Domain name') }}:</label>
            <div>
                {{ domain }}
            </div>
        </div>
        <div class="mt-3">
            <div class="flex align-items-center">
                <RadioButton v-model="enabled" inputId="isDisabled" name="enabled" :value="false"/>
                <label for="isDisabled" class="ml-2">{{ $_('Disable outgoing e-mail monitoring') }}</label>
            </div>
            <div class="flex align-items-center mt-2">
                <RadioButton v-model="enabled" inputId="isEnabled" name="enabled" :value="true"/>
                <label for="isEnabled" class="ml-2">{{ $_('Enable outgoing e-mail monitoring') }}</label>
            </div>
            <div v-if="enabled">
                <label for="emailField" class="block text-left font-semibold text-gray-700 mb-1 mt-3">{{ $_('Destination e-mail address') }}:</label>
                <Dropdown v-model="email" :options="emails" :placeholder="$_('Select e-mail address')" class="w-full"/>
                <div v-if="emailInvalid" class="text-left text-red-500">
                    <small>{{  $_('You need to select an e-mail address.') }}</small>
                </div>
            </div>
        </div>
        <div class="flex justify-content-between gap-2 mt-4">
            <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"></Button>
            <Button type="button" :label="$_('Change monitoring')" @click="action" :loading="loading"></Button>
        </div>
    </Dialog>
    </div>
</template>
    
<script>
    export default {
      name: "MonitoringSetDialog",
      data() {
        return {
          visible: false,
          domain: "",
          enabled: false,
          email: "",
          emailInvalid: false,
          loading: false,
          error: "",
        }
      },
      computed: {
        emails() { 
            return this.$store.getters["emailAccounts"].map((item) => item.name + "@" + item.domain);
        }
      },
      methods: {
        show(domain) {
            this.domain = domain;
            this.enabled = false;
            this.email = "";
            var record = this.$store.getters["domains"].find((item) => item.domain == this.domain);
            if(record && record.bccEmail) {
                this.enabled = true;
                this.email = record.bccEmail;
            }
            this.emailInvalid = false;
            this.error = "";
            this.visible = true;
        },
        hide() {
            this.visible = false;
        },
        action() {
            this.emailInvalid = false;
            this.error = "";

            if(this.enabled && !this.email.trim().toLowerCase().match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
                this.emailInvalid = true;
                return;
            }
            
            this.loading = true;
            this.$store.dispatch("emailMonitoring", { 
                domain: this.domain,
                email: this.enabled ? this.email.trim().toLowerCase() : null
            }).then(() => {
                this.loading = false;
                this.visible = false;
                this.$refs["toast"].add({
                    severity: 'success', 
                    summary: this.$_("Operation performed successfully."), 
                    detail: this.enabled ?
                        this.$_("E-mail monitoring set to") + ": " + this.email.trim().toLowerCase()
                        : this.$_("E-mail monitoring disabled."), 
                    life: 3000
                });
            }).catch((error) => {
                switch(error.code) {
                    default:
                        this.error = this.$_("Unknown error.");
                }
                this.loading = false;
            });
        }
      },
    }
    </script>