<template>
    <div>
    <Toast ref="toast"/>
    <Dialog 
        v-model:visible="visible" 
        modal
        :draggable="false"
        :header="$_('Change your password')"
        class="w-full lg:w-4 mx-2"
        >
        <div v-if="error">
            <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
        </div>
        <div>
            <label for="passwordField" class="block text-left font-medium mb-1">{{ $_('Your current password') }}:</label>
            <InputText
                id="passwordField"
                type="password"
                v-model="oldPassword"
                :invalid="oldInvalid"
                class="w-full"
                ref="input"
            />
            <div v-if="oldInvalid" class="text-left text-red-500">
                <small>{{  $_('You need to provide your current password.') }}</small>
            </div>
        </div>
        <div>
            <label for="passwordField" class="block text-left font-medium mb-1 mt-3">{{ $_('Your new password') }}:</label>
            <InputGroup>
                <InputText
                    id="passwordField"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="password"
                    :invalid="passwordInvalid"
                    class="w-full"
                    ref="password"
                />
                <Button severity="secondary" icon="pi pi-eye" @click="showPassword=true" v-if="!showPassword"/>
                <Button severity="secondary" icon="pi pi-eye-slash" @click="showPassword=false" v-if="showPassword"/>
            </InputGroup>
            <div v-if="passwordInvalid" class="text-left text-red-500">
                <small>{{  $_('Password must be at least 8 characters long.') }}</small>
            </div>
            <label for="password2Field" class="block text-left font-medium mb-1 mt-3">{{ $_('Repeat the password') }}:</label>
            <InputGroup>
                <InputText
                    id="password2Field"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="password2"
                    :invalid="password2Invalid"
                    class="w-full"
                />
                <Button severity="secondary" icon="pi pi-eye" @click="showPassword=true" v-if="!showPassword"/>
                <Button severity="secondary" icon="pi pi-eye-slash" @click="showPassword=false" v-if="showPassword"/>
            </InputGroup>
            <div v-if="password2Invalid" class="text-left text-red-500">
                <small>{{  $_('The passwords do not match.') }}</small>
            </div>
            <div class="mt-1">
                <Button severity="secondary" icon="pi pi-pencil" :label="$_('Generate password')" size="small" @click="generatePassword"/>
            </div>
        </div>
        <div class="flex justify-content-between gap-2 mt-4">
            <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"></Button>
            <Button type="button" :label="$_('Change password')" @click="action" :loading="loading"></Button>
        </div>
    </Dialog>
    </div>
    </template>
    
<script>
import { password } from "../../mixins/password.js";

export default {
      name: "FtpPasswordDialog",
      mixins: [ password ],
      data() {
        return {
          oldPassword: "",
          oldInvalid: false,
          visible: false,
          loading: false,
          error: "",
        }
      },
      methods: {
        show() {
            this.oldInvalid = false;
            this.oldPassword = "";
            this.password = "";
            this.passwordInvalid = false;
            this.password2 = "";
            this.password2Invalid = false;
            this.visible = true;
            this.error = "";
            this.$nextTick(() => {
                this.$refs["input"].$el.focus();
            });
        },
        hide() {
            this.visible = false;
        },
        action() {
            this.oldInvalid = false;
            this.passwordInvalid = false;
            this.password2Invalid = false;
            this.error = "";
            
            if(this.oldPassword.trim().length < 8) {
                this.oldInvalid = true;
                return;
            }            
            if(this.password.trim().length < 8) {
                this.passwordInvalid = true;
                return;
            }            
            if(this.password2.trim() != this.password.trim()) {
                this.password2Invalid = true;
                return;
            }
    
            this.loading = true;
            this.$store.dispatch("password", { 
                oldPassword: this.oldPassword.trim(),
                newPassword: this.password.trim()
            }).then(() => {
                this.loading = false;
                this.visible = false;
                this.$refs["toast"].add({
                    severity: 'success', 
                    summary: this.$_("Operation performed successfully."), 
                    detail: this.$_("Account password was changed."), 
                    life: 3000
                });
            }).catch((error) => {
                switch(error.code) {
                    case 403:
                        this.error = this.$_('Incorrect current password.');
                        break;
                    case 406:
                        this.error = this.$_('Try using a different password.');
                        break;
                    default:
                        this.error = this.$_("Unknown error.");
                }
                this.loading = false;
            });
        }
      },
      watch: {
        oldPassword() { this.oldInvalid = false; },
        password() { this.passwordInvalid = false; },
        password2() { this.password2Invalid = false; }
      }
    }
    </script>