<template>
<div>
<Toast ref="toast"/>
<Dialog 
    v-model:visible="visible" 
    modal
    :draggable="false"
    :header="$_('Change PHP version')"
    class="w-full lg:w-4 mx-2"
    >
    <div v-if="error">
        <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
    </div>
    <div v-if="errorPHP">
        <Message severity="warn" class="mt-0" :closable="false">
            {{ errorPHP }}
        </Message>
    </div>
    <div>
        <label class="block text-left font-semibold text-gray-700 mb-2">{{ $_('Domain name') }}:</label>
        <div>
            {{ domain }}
        </div>
    </div>
    <div class="mt-3">
        <label class="block text-left font-semibold text-gray-700 mb-1">{{ $_('PHP version') }}:</label>
        <Dropdown v-model="version" :options="phpVersions" :placeholder="$_('Select PHP version')" class="w-full"/>
    </div>
    <div class="flex justify-content-between gap-2 mt-4">
        <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"></Button>
        <Button type="button" :label="$_('Change version')" @click="action" :loading="loading"></Button>
    </div>
</Dialog>
</div>
</template>

<script>

export default {
  name: "DomainPHPDialog",
  data()
  {
    return {
      visible: false,
      domain: "",
      version: "",
      loading: false,
      error: "",
    }
  },
  computed: {
    phpVersions() {
        var ret = [];
        this.$store.getters["phpVersions"].forEach((version) => {
            ret.push(version)
        });
        return ret;
    },
    errorPHP() {
        if(this.error) return null;
        var ret = null;
        this.$store.getters['scripts'].forEach((item) => {
            if(item.domain == this.domain) {
                this.$store.getters['availableScripts'].forEach((item2) => {
                    if(item.type == item2.type) {
                        if(item2.minPHP == item2.maxPHP)
                            ret = this.$_("%1 requires PHP version %2!").replace("%1", item2.name).replace("%2", item2.minPHP);
                        else
                            ret = this.$_("%1 requires PHP version %2!").replace("%1", item2.name).replace("%2", item2.minPHP + "-" + item2.maxPHP);
                    }
                });
            }
        });
        return ret;
    },
  },
  methods: {
    show(domain) {
        this.domain = domain;
        this.version = this.$store.getters["phpVersions"][this.$store.getters["phpVersions"].length-1];
        this.error = "";
        this.visible = true;
        this.$store.getters["domains"].forEach((item) => {
            if(item.domain == domain) this.version = item.phpVersion;
        })
    },
    hide() {
        this.visible = false;
    },
    action() {
        this.error = "";
        
        this.loading = true;
        this.$store.dispatch("domainPHP", { 
            domain: this.domain.toLowerCase().trim(),
            version: this.version
        }).then(() => {
            this.loading = false;
            this.visible = false;
            this.$refs["toast"].add({
                severity: 'success', 
                summary: this.$_("Operation performed successfully."), 
                detail: this.$_("PHP version changed to") + ": " + this.version, 
                life: 3000
            });
        }).catch((error) => {
            switch(error.code) {
                default:
                    this.error = this.$_("Unknown error.");
            }
            this.loading = false;
        });
    }
  },
}
</script>