<template>
    <div>
    <Toast ref="toast"/>
    <Dialog 
        v-model:visible="visible" 
        modal
        :draggable="false"
        :header="$_('Create an e-mail alias')"
        class="w-full lg:w-5 mx-2"
        >
        <div v-if="error">
            <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
        </div>
        <div>
            <label for="nameField" class="block text-left font-semibold text-gray-700 mb-1">{{ $_('E-mail address') }}:</label>
            <div class="flex flex-column sm:flex-row email-group">
                <InputText
                    id="nameField"
                    type="text"
                    v-model="name"
                    :invalid="nameInvalid"
                    class="w-full sm:w-6"
                    :placeholder="$_('yourname')"
                    ref="input"
                />
                <Dropdown
                class="mt-1 sm:mt-0 ml-0 w-full sm:w-6"
                    v-model="domain"
                    :options="domains"
                    :placeholder="$_('Select a domain')"
                    optionLabel="text"
                    optionValue="value"
                />
            </div>
            <div v-if="nameInvalid" class="text-left text-red-500">
                <small>{{  $_('You need to provide a valid e-mail address.') }}</small>
            </div>
            <div v-if="domainInvalid" class="text-left text-red-500">
                <small>{{  $_('You need to select a domain.') }}</small>
            </div>
        </div>
        <div class="mt-3">
            <label for="quotaField" class="block text-left font-semibold text-gray-700">{{ $_('Destination addresses') }}:</label>
            <div v-for="(email, index) in destinations" :key="index" class="mt-1">
                <InputGroup class="w-full">
                    <InputText
                        type="text"
                        v-model="destinations[index]"
                        :invalid="destinationsInvalid[index]"
                        class="w-full"
                        :placeholder="$_('yourname@anotherdomain.com')"
                    />
                    <Button severity="secondary" icon="pi pi-trash" @click="deleteItem(index)" :disabled="destinations.length==1"/>
                </InputGroup>
                <div v-if="destinationsInvalid[index]" class="text-left text-red-500">
                    <small>{{  $_('You need to enter a valid e-mail address.') }}</small>
                </div>
            </div>
            <div class="mt-2">
                <Button severity="secondary" icon="pi pi-plus" :label="$_('Add destination')" size="small" @click="addItem()"/>
            </div>
        </div>
        <div class="flex justify-content-between gap-2 mt-4">
            <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"></Button>
            <Button type="button" :label="$_('Create alias')" @click="action" :loading="loading"></Button>
        </div>
    </Dialog>
    </div>
</template>
    
<script>
    
    export default {
      name: "AliasCreateDialog",
      data() {
        return {
          visible: false,
          domain: "",
          name: "",
          nameInvalid: false,
          domainInvalid: false,
          destinations: [""],
          destinationsInvalid: [false],
          loading: false,
          error: "",
        }
      },
      computed: {
        domains() {
            return this.$store.getters["domains"].map((item) => { return {
                value: item.domain,
                text: "@" + item.domain
            }});
        },
      },
      methods: {
        show(domain) {
            this.domain = domain ?? "";
            this.name = "";
            this.nameInvalid = false;
            this.domainInvalid = false;
            this.destinations = [""];
            this.destinationsInvalid = [false];
            this.visible = true;
            this.error = "";
            this.$nextTick(() => {
                this.$refs["input"].$el.focus();
            });
        },
        hide() {
            this.visible = false;
        },
        addItem() {
            this.destinations.push("");
            this.destinationsInvalid.push(false);
        },
        deleteItem(index) {
            this.destinations.splice(index, 1);
            this.destinationsInvalid.splice(index, 1);
        },
        action() {
            this.nameInvalid = false;
            this.domainInvalid = false;
            for(let i in this.destinations) this.destinationsInvalid[i] = false;
            this.error = "";
            
            if(!this.domain.length) {
                this.domainInvalid = true;
                return;
            }
            if(!this.name.trim().length || this.name.trim().match(/[^a-zA-Z0-9_.-]/) || this.name.trim().match(/^[^a-z]/) || this.name.trim().length > 40) {
                this.nameInvalid = true;
                return;
            }
            var empty = true;
            for(let i in this.destinations) {
                if(this.destinations[i].trim().length > 0) empty = false;
            }
            if(empty) {
                this.destinationsInvalid[0] = true;
                return;
            }
            var destinations = [];
            for(let i in this.destinations) {
                this.destinations[i].trim().toLowerCase()
                if(!this.destinations[i].trim().toLowerCase().match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
                    this.destinationsInvalid[i] = true;
                    return;
                }
                if(this.destinations[i].trim().length > 0)
                    destinations.push(this.destinations[i].trim().toLowerCase());
            }

            this.$store.getters["emailAccounts"].forEach((item) => {
                if(item.domain == this.domain && item.name == this.name) {
                    this.error = this.$_('An e-mail account with this name already exists.')
                }
            });
            this.$store.getters["emailAliases"].forEach((item) => {
                if(item.domain == this.domain && item.name == this.name) {
                    this.error = this.$_('An e-mail alias with this name already exists.')
                }
            });
            if(this.error) return;
    
            this.loading = true;
            this.$store.dispatch("emailAliasCreate", { 
                domain: this.domain,
                name: this.name.trim().toLowerCase(),
                destination: destinations.join(","),
            }).then(() => {
                this.loading = false;
                this.visible = false;
                this.$refs["toast"].add({
                    severity: 'success', 
                    summary: this.$_("Operation performed successfully."), 
                    detail: this.$_("E-mail alias was created") + ": " + this.name.toLowerCase().trim() + '@' + this.domain, 
                    life: 3000
                });
            }).catch((error) => {
                switch(error.code) {
                    default:
                        this.error = this.$_("Unknown error.");
                }
                this.loading = false;
            });
        }
      },
      watch: {
        name() { this.nameInvalid = false; },
        quota() { this.quotaInvalid = false; },
        password() { this.passwordInvalid = false; },
        password2() { this.password2Invalid = false; },
      }
    }
    </script>