<template>
    <div>
    <Toast ref="toast"/>
    <Dialog 
        v-model:visible="visible" 
        modal
        :draggable="false"
        :header="$_('Remove a SSH key')"
        class="w-full lg:w-4 mx-2"
        >
        <div v-if="error">
            <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
        </div>
        <div>
            <label class="block text-left font-semibold text-gray-700 mb-1">{{ $_('Key name') }}:</label>
            {{ name }}
        </div>
        <div>
            <label class="block text-left font-semibold text-gray-700 mb-1 mt-3">{{ $_('Key fingerprint') }}:</label>
            <div class="ellipsis">
                {{ fingerprint }}
            </div>
        </div>
        <div class="flex justify-content-between gap-2 mt-4">
            <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"></Button>
            <Button type="button" :label="$_('Remove key')" severity="danger" @click="action" :loading="loading"></Button>
        </div>
    </Dialog>
    </div>
</template>
    
<script>
    export default {
      name: "KeyDeleteDialog",
      data() {
        return {
          visible: false,
          id: 0,
          name: "",
          fingerprint: "",
          loading: false,
          error: "",
        }
      },
      methods: {
        show(id) {
            this.id = id;
            var item = this.$store.getters["sshKeys"].find((item) => item.id == id);
            this.name = item.name;
            this.fingerprint = item.fingerprint;
            this.error = "";
            this.visible = true;
        },
        hide() {
            this.visible = false;
        },
        action() {
            this.error = "";

            this.loading = true;
            this.$store.dispatch("sshKeyDelete", { 
                id: this.id,
            }).then(() => {
                this.loading = false;
                this.visible = false;
                this.$refs["toast"].add({
                    severity: 'success', 
                    summary: this.$_("Operation performed successfully."), 
                    detail: this.$_("SSH key was removed."),
                    life: 3000
                });
            }).catch((error) => {
                switch(error.code) {
                    default:
                        this.error = this.$_("Unknown error.");
                }
                this.loading = false;
            });
        }
      },
      watch: {
        accordion(value) {
            if(value === 0) 
                this.$refs["accordion"].$el.getElementsByClassName("p-toggleable-content")[0].style.display = "block";
        },
        domain() { this.domainInvalid = false; },
        folder() { this.folderInvalid = false; },
        destination() { this.domainInvalid = false; },
      }
    }
    </script>