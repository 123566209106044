<template>
    <div>
    <Toast ref="toast"/>
    <Dialog 
        v-model:visible="visible" 
        modal
        :draggable="false"
        :header="$_('Generate a certificate')"
        class="w-full lg:w-4 mx-2"
        >
        <div v-if="error">
            <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
        </div>
        <div>
            <label class="block text-left font-semibold text-gray-700 mb-1">{{ $_('Domain name') }}:</label>
            <div>
                {{ domain }}
            </div>
        </div>
        <div class="mt-3">
            {{ $_('To generate a free certificate for your domain, you need to point it to our DNS servers:') }}
            <div class="mt-3 text-gray-700">
                {{ $store.getters["nameservers"][0] }}
                <CopyIcon :text="$store.getters['nameservers'][0]" :message="$_('Nameserver name copied to clipboard.')"/>
            </div>
            <div class="mt-3 text-gray-700">
                {{ $store.getters["nameservers"][1] }}
                <CopyIcon :text="$store.getters['nameservers'][1]" :message="$_('Nameserver name copied to clipboard.')"/>
            </div>
        </div>
        <div class="flex justify-content-between gap-2 mt-4">
            <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"></Button>
            <Button type="button" :label="$_('Generate certificate')" @click="action" :loading="loading"></Button>
        </div>
    </Dialog>
    </div>
</template>
    
<script>
import CopyIcon from "../../components/CopyIcon.vue";

export default {
    name: "CertificateGenerateDialog",
    components: {
        CopyIcon,
    },
    data() {
        return {
            visible: false,
            domain: "",
            loading: false,
            error: "",
        }
    },
    methods: {
        show(domain) {
            this.domain = domain;
            this.error = "";
            this.visible = true;
        },
        hide() {
            this.visible = false;
        },
        action() {
            this.error = "";
            
            this.loading = true;
            this.$store.dispatch("certificateGenerate", { 
                domain: this.domain
            }).then(() => {
                this.loading = false;
                this.visible = false;
                this.$refs["toast"].add({
                    severity: 'success', 
                    summary: this.$_("Operation performed successfully."), 
                    detail: this.$_("SSL certificate generated") + ": " + this.domain, 
                    life: 3000
                });
            }).catch((error) => {
                switch(error.code) {
                    case 405:
                        this.error = this.$_("Domain is not pointed to our nameservers.");
                        break;
                    default:
                        this.error = this.$_("Unknown error.");
                }
                this.loading = false;
            });
        }
    },
}
</script>