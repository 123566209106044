<template>
<ViewRoot active="scripts">
  <DataTable :value="$store.getters['scripts']" v-if="$store.getters['scripts'].length">
    <Column :header="$_('Website address')">
        <template #body="slot">
            <div class="flex align-items-center">
                <img :src="'assets/scripts/' + slot.data.type + '.svg'" width="20" height="20" class="mr-2"/>
                <a :href="'http://' + slot.data.domain + slot.data.directory" target="_blank" class="text-primary">
                  <span class="hidden sm:inline">http://</span>{{ slot.data.domain + slot.data.directory }}
                </a>
                <CopyIcon :text="'http://' + slot.data.domain + slot.data.directory" :message="$_('Website address copied to clipboard.')"/>
            </div>
        </template>
    </Column>
    <!--
    <Column :header="$_('Installed script')" headerClass="hidden xl:cell" bodyClass="hidden xl:cell">
        <template #body="slot">
            <div class="flex align-items-center">
                <img :src="'assets/scripts/' + slot.data.type + '.svg'" width="20" height="20"/>
                <div class="ml-1">{{ $store.getters['availableScripts'].find((item) => item.type == slot.data.type).name }} {{ slot.data.version }}</div>                
            </div>
        </template>
    </Column>
    -->
    <Column :header="$_('Database')" headerClass="hidden md:cell" bodyClass="hidden md:cell">
        <template #body="slot">
            {{ slot.data.database }}
            <CopyIcon :text="slot.data.database" :message="$_('Database name copied to clipboard.')"/>
        </template>
    </Column>
    <Column>
      <template #body="slot">
        <div class="table-buttons hidden lg:block">
          <Button 
            :label="$_('Script operations')"
            severity="secondary"
            size="small"
            icon="pi pi-chevron-down"
            iconPos="right"
            @click="showMenu($event, slot.data.id, true)"
          />
        </div>
        <a v-ripple class="cursor-pointer block lg:hidden text-700 p-ripple text-right" @click="showMenu($event, slot.data.id, false)">
          <i class="pi pi-ellipsis-v"></i>
        </a>
      </template>
    </Column>
  </DataTable>
  <p v-else class="ml-3">
    {{  $_('You have not installed any scripts yet.') }}
  </p>
  <div class="text-left mt-3 mx-3 flex flex-column sm:flex-row">
    <Button
      :label="$_('Install a new script')"
      icon="pi pi-plus"
      @click="$refs['scriptInstall'].show('wordpress')"
    />
  </div>
  <ContextMenu ref="contextMenu" :model="contextMenu"/>
  <ScriptInstallDialog ref="scriptInstall"/>
  <ScriptUninstallDialog ref="scriptUninstall"/>
</ViewRoot>
</template>

<script>

import ViewRoot from "../components/ViewRoot.vue";
import CopyIcon from "../components/CopyIcon.vue";

import ScriptInstallDialog from "../dialogs/script/ScriptInstallDialog.vue";
import ScriptUninstallDialog from "../dialogs/script/ScriptUninstallDialog.vue";

export default {
  name: "DomainsView",
  components: {
    ViewRoot,
    CopyIcon,
    ScriptInstallDialog,
    ScriptUninstallDialog,
  },
  mounted() {
    const redirect = this.$store.getters["redirect"];
    switch(redirect.action) {
      case "script":
        this.$refs["scriptInstall"].show(redirect.domain);
        break;
    }
    this.$store.commit("redirectClear");
    this.$store.dispatch("keepalive");
  },
  data() {
    return {
      contextId: "",
      contextMenu: [],
    }
  },
  methods: {
    manage(item) {
        var url = "http://" + item.domain + item.directory;
        var script = this.$store.getters["availableScripts"].find((i) => i.type == item.type);
        url += script.admin;
        window.open(url);
    },
    showMenu(event, id) {
      this.contextId = id;
      var item = this.$store.getters["scripts"].find((item) => item.id == id);
      this.contextMenu = [];
      this.contextMenu.push({ label: this.$_("Show admin panel"), icon: "pi pi-cog", command: () => this.manage(item) });
      this.contextMenu.push({ label: this.$_("Uninstall script"), icon: "pi pi-trash", command: () => this.$refs["scriptUninstall"].show(this.contextId) });
      this.$refs["contextMenu"].show(event);
    },
  }
}

</script>

