<template>
    <div>
    <Toast ref="toast"/>
    <Dialog 
        v-model:visible="visible" 
        modal
        :draggable="false"
        :header="$_('Create a symbolic link')"
        class="w-full lg:w-4 mx-2"
        >
        <div v-if="error">
            <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
        </div>
        <div>
            <label for="nameField" class="block text-left font-semibold text-gray-700 mb-1">{{ $_('Link name') }}:</label>
            <InputText
                id="nameField"
                type="text"
                v-model="name"
                :invalid="nameInvalid"
                class="w-full"
                ref="input"
            />
            <div v-if="nameInvalid" class="text-left text-red-500">
                <small>{{  $_('You need to enter a valid file name.') }}</small>
            </div>
        </div>
        <div>
            <label for="destinationField" class="block text-left font-semibold text-gray-700 mb-1 mt-3">{{ $_('Link destination') }}:</label>
            <InputText
                id="destinationField"
                type="text"
                v-model="destination"
                :invalid="destinationInvalid"
                :placeholder="$_('Existing file or directory')"
                class="w-full"
            />
            <div v-if="destinationInvalid" class="text-left text-red-500">
                <small>{{  $_('You need to enter a valid link destination.') }}</small>
            </div>
        </div>
        <div class="flex justify-content-between gap-2 mt-4">
            <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"></Button>
            <Button type="button" :label="$_('Create link')" @click="action" :loading="loading"></Button>
        </div>
    </Dialog>
    </div>
</template>
    
<script>
    
    const requester = require("../../libraries/api.js");

    export default {
      name: "LinkCreateDialog",
      data() {
        return {
          root: "",
          visible: false,
          name: "",
          nameInvalid: false,
          destination: "",
          destinationInvalid: false,
          loading: false,
          error: "",
        }
      },
      methods: {
        show(root, fileNames) {
            this.root = root;
            this.fileNames = fileNames;
            this.name = "";
            this.nameInvalid = false;
            this.destination = "";
            this.destinationInvalid = false;
            this.visible = true;
            this.error = "";
            this.$nextTick(() => {
                this.$refs["input"].$el.focus();
            });
        },
        hide() {
            this.visible = false;
        },
        action() {
            this.nameInvalid = false;
            this.error = "";
            
            if(!this.name.trim().length || this.name.match(/[/<>:"|?*\\]/)) {
                this.nameInvalid = true;
                return;
            }
            if(!this.destination.trim().length || this.destination.match(/[<>:"|?*\\]/)) {
                this.destinationInvalid = true;
                return;
            }
            this.fileNames.forEach((name) => {
                if(name == this.name.trim()) {
                    this.error = this.$_('File or directory already exists.')
                }
            });
            if(this.error) return;
    
            this.loading = true;
            requester.post("/file/link/create", {
                name: this.name.trim(),
                directory: this.root,
                destination: this.destination.trim(),
            }).then(() => {
                this.loading = false;
                this.visible = false;
                this.$refs["toast"].add({
                    severity: 'success', 
                    summary: this.$_("Operation performed successfully."), 
                    detail: this.$_("Link was created") + ": " + this.name.trim(), 
                    life: 3000
                });
                this.$emit("created", this.name.trim());
            }).catch((error) => {
                switch(error.code) {
                    case 404:
                        this.error = this.$_("Link destination does not exist.");
                        break;
                    default:
                        this.error = this.$_("Unknown error.");
                }
                this.loading = false;
            });
        }
      },
      watch: {
        name() { this.nameInvalid = false; },
      }
    }
    </script>