<template>
<ViewRoot active="files">
<div class="relative">
    <div class="p-2 file-buttons">
        <div class="flex justify-content-between">
            <div class="flex gap-2">
                <Dropdown
                    :options="directoryList"
                    :modelValue="directory"
                    optionGroupLabel="label"
                    optionGroupChildren="items"
                    @update:modelValue="loadDirectory($event)"
                    class="file-path"
                >
                    <template #optiongroup="slot">
                        <div :class="{ 'file-line': slot.option.label }"></div>
                    </template>
                </Dropdown>
                <Button
                    class="hidden pl-1 md:block"
                    severity="secondary"
                    icon="pi pi-sync"
                    @click="loadDirectory(directory)"
                />
            </div>
            <div class="gap-2 flex">
                <div class="relative">
                    <Button
                        class="hidden md:block"
                        severity="secondary"
                        icon="pi pi-plus"
                        :label="$_('Create...')"
                        v-styleclass="{ selector: '#createMenu', enterClass: 'hidden', enterActiveClass: 'fadein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }"
                    />
                    <Button
                        class="block md:hidden"
                        severity="secondary"
                        icon="pi pi-plus"
                        v-styleclass="{ selector: '#createMenu', enterClass: 'hidden', enterActiveClass: 'fadein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }"
                    />
                    <ul id="createMenu" class="list-none p-0 m-0 hidden select-none 
                        surface-section border-1 surface-border z-1 shadow-2 absolute">
                        <li>
                            <a v-ripple class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 border-round cursor-pointer
                                transition-duration-150 transition-colors p-ripple" @click="$refs['directoryCreate'].show(directory, fileNames)">
                                <i class="pi pi-folder text-base mr-2 text-gray-400"></i>
                                <span class="block">{{ $_('Create directory') }}</span>
                            </a>
                        </li>
                        <li>
                            <a v-ripple class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 border-round cursor-pointer
                                transition-duration-150 transition-colors p-ripple" @click="$refs['fileCreate'].show(directory, fileNames)">
                                <i class="pi pi-file text-base mr-2 text-gray-400"></i>
                                <span class="block">{{ $_('Create empty file') }}</span>
                            </a>
                        </li>
                        <li>
                            <a v-ripple class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 border-round cursor-pointer
                                transition-duration-150 transition-colors p-ripple" @click="$refs['linkCreate'].show(directory, fileNames)">
                                <i class="pi pi-link text-base mr-2 text-gray-400"></i>
                                <span class="block">{{ $_('Create symbolic link') }}</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="relative">
                    <Button
                        class="hidden md:block"
                        severity="secondary"
                        icon="pi pi-upload"
                        :label="$_('Upload...')"
                        v-styleclass="{ selector: '#uploadMenu', enterClass: 'hidden', enterActiveClass: 'fadein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }"
                    />
                    <Button
                        class="block md:hidden"
                        severity="secondary"
                        icon="pi pi-upload"
                        v-styleclass="{ selector: '#uploadMenu', enterClass: 'hidden', enterActiveClass: 'fadein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }"
                    />
                    <ul id="uploadMenu" class="list-none p-0 m-0 hidden select-none 
                        surface-section border-1 surface-border z-1 shadow-2 absolute">
                        <li>
                            <a v-ripple class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 border-round cursor-pointer
                                transition-duration-150 transition-colors p-ripple" @click="$refs['fileUpload'].show(directory)">
                                <i class="pi pi-upload text-base mr-2 text-gray-400"></i>
                                <span class="block">{{ $_('Upload files from disk') }}</span>
                            </a>
                        </li>
                        <li>
                            <a v-ripple class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 border-round cursor-pointer
                                transition-duration-150 transition-colors p-ripple" @click="$refs['zipUpload'].show(directory)">
                                <i class="pi pi-upload text-base mr-2 text-gray-400"></i>
                                <span class="block">{{ $_('Upload ZIP or TAR.GZ') }}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="file-root">
        <table class="file-table w-full" cellpadding="3" cellspacing="0" :key="tableKey">
            <tr v-if="directory != '/'" @click="goUp()">
                <td width="32" class="pl-3 pr-2">
                    <i class="pi pi-arrow-up file-icon text-gray-500"></i>
                </td>
                <td class="file-name" colspan="5" width="99%">
                    {{ $_('Parent directory') }}
                </td>
            </tr>
            <tr v-if="!files.length">
                <td width="32" class="pl-3 pr-2">
                </td>
                <td class="text-gray-300" colspan="5" width="99%">
                    {{ $_('This directory is empty.') }}
                </td>
            </tr>
            <tr v-for="file in files" :key="file.number">
                <td width="32" class="pl-3 pr-2" @click="fileClick(file)">
                    <i class="pi pi-folder file-icon text-gray-600" v-if="file.type=='directory'"></i>
                    <i class="pi pi-file file-icon text-gray-500" v-else-if="file.type=='file'"></i>
                    <i class="pi pi-link file-icon text-gray-500" v-else-if="file.type=='link'"></i>
                    <i class="pi pi-question file-icon text-gray-500" v-else></i>
                </td>
                <td width="95%" @click="fileClick(file)" nowrap>
                    <div class="ellipsis">
                        {{ file.name }}
                        <span v-if="file.type == 'link'">
                            <i class="pi pi-arrow-right text-gray-400 px-1" style="font-size: 0.8rem"></i>
                            {{ file.target }}
                        </span>
                    </div>
                </td>
                <td class="text-right hidden sm:cell text-gray-600 pr-2" nowrap @click="fileClick(file)">
                    <span v-if="file.type == 'file'">{{ showSize(file.size) }}</span>
                </td>
                <td class="text-right hidden lg:cell text-gray-600 pr-2" nowrap @click="fileClick(file)">
                    {{ file.permissions }}
                </td>
                <td class="text-right hidden md:cell text-gray-600 pr-2" nowrap @click="fileClick(file)">
                    {{ file.day + ' ' + file.month + ' ' + file.time }}
                </td>
                <td class="text-right pr-3" width="32" @click="showMenu($event, file)">
                    <a v-ripple class="cursor-pointer block text-700 p-ripple">
                        <i class="pi pi-cog file-icon"></i>
                    </a>
                </td>
            </tr>
        </table>
        <div class="file-loader" v-if="loading">
            <ProgressSpinner/>
        </div>
        <div class="file-loader" v-if="error">
            <div style="font-size: 1.2rem; color: var(--red-500)">
                <i class="pi pi-exclamation-circle"></i>
                {{ $_('Cannot load file list.') }}
            </div>
        </div>
    </div>
    <form :action="formAction()" method="post" ref="form" target="_blank">
        <input type="hidden" name="session" :value="$store.getters['session']"/>
        <input type="hidden" name="file" :value="currentFile"/>
    </form>
    <ContextMenu ref="contextMenu" :model="contextMenu"/>
    <DirectoryCreateDialog ref="directoryCreate" @created="loadDirectory($event)"/>
    <DirectoryDeleteDialog ref="directoryDelete" @deleted="fileUploaded"/>
    <DirectoryRenameDialog ref="directoryRename" @renamed="loadDirectory(directory)"/>
    <FileChmodDialog ref="fileChmod" @modified="loadDirectory(directory)"/>
    <FileCreateDialog ref="fileCreate" @created="fileCreated($event)"/>
    <FileDeleteDialog ref="fileDelete" @deleted="loadDirectory(directory)"/>
    <FileRenameDialog ref="fileRename" @renamed="loadDirectory(directory)"/>
    <FileUploadDialog ref="fileUpload" @uploaded="fileUploaded"/>
    <ZipUploadDialog ref="zipUpload" @uploaded="fileUploaded"/>
    <LinkCreateDialog ref="linkCreate" @created="loadDirectory(directory)"/>
    <LinkRenameDialog ref="linkRename" @renamed="loadDirectory(directory)"/>
    <LinkDeleteDialog ref="linkDelete" @deleted="loadDirectory(directory)"/>
    <FileEditDialog ref="fileEdit"/>
</div>
</ViewRoot>
</template>

<script>

import ViewRoot from "../components/ViewRoot.vue";

import DirectoryCreateDialog from "../dialogs/file/DirectoryCreateDialog.vue";
import DirectoryDeleteDialog from "../dialogs/file/DirectoryDeleteDialog.vue";
import DirectoryRenameDialog from "../dialogs/file/DirectoryRenameDialog.vue";
import FileChmodDialog from "../dialogs/file/FileChmodDialog.vue";
import FileCreateDialog from "../dialogs/file/FileCreateDialog.vue";
import FileDeleteDialog from "../dialogs/file/FileDeleteDialog.vue";
import FileEditDialog from "../dialogs/file/FileEditDialog.vue";
import FileRenameDialog from "../dialogs/file/FileRenameDialog.vue";
import FileUploadDialog from "../dialogs/file/FileUploadDialog.vue";
import LinkCreateDialog from "../dialogs/file/LinkCreateDialog.vue";
import LinkRenameDialog from "../dialogs/file/LinkRenameDialog.vue";
import LinkDeleteDialog from "../dialogs/file/LinkDeleteDialog.vue";
import ZipUploadDialog from "../dialogs/file/ZipUploadDialog.vue";

import { size } from "../mixins/size.js";

const requester = require("../libraries/api.js");

const extensions = require("../config/extensions.js");

export default {
  name: "AccessView",
  components: {
    ViewRoot,
    DirectoryCreateDialog,
    DirectoryDeleteDialog,
    DirectoryRenameDialog,
    FileChmodDialog,
    FileCreateDialog,
    FileDeleteDialog,
    FileEditDialog,
    FileRenameDialog,
    FileUploadDialog,
    LinkCreateDialog,
    LinkRenameDialog,
    LinkDeleteDialog,
    ZipUploadDialog,
  },
  mixins: [
    size
  ],
  data() {
    return {
        loading: false,
        error: false,
        directory: this.$store.getters["fileDir"],
        files: [],
        tableKey: 0,
        currentAction: "",
        currentFile: "",
        contextFile: null,
        contextMenu: [],
    }
  },
  computed: {
    directoryList() {
        var dir = this.directory;
        var path = [];
        // eslint-disable-next-line no-constant-condition
        while(true) {
            path.push(dir);
            if(dir == "/") break;
            dir = dir.replace(/\/[^/]*$/, "");
            if(dir == "") dir = "/";
        }
        return [
            { label: false, items: path },
            { label: true, items: this.$store.getters["domains"].map((item) => "/" + item.directory ) },
        ];
    },
    fileNames() {
        return this.files.map((item) => item.name);
    },
  },
  mounted() {
    const redirect = this.$store.getters["redirect"];
    this.$store.commit("redirectClear");
    var dir = this.$store.getters["fileDir"];
    if(redirect && redirect.action == "browse" && redirect.domain) {
        this.$store.getters["domains"].forEach((item) => { if(item.domain == redirect.domain) dir = "/" + item.directory; });
    }
    this.loadDirectory(dir);
  },
  methods: {
    loadDirectory(directory) {
        directory = directory.replace(/^\/\//, "/", directory);
        this.loading = true;
        this.error = false;
        requester.post("/file/list", {
            directory: directory
        }).then((list) => {
            this.loading = false;
            this.files = list.files.sort((a, b) => {
                if(a.type == 'directory' && b.type != 'directory')
                    return -1;
                if(a.type != 'directory' && b.type == 'directory')
                    return 1;
                return a.name.localeCompare(b.name);
            });
            this.directory = directory ? directory : "/";
            this.$store.commit("fileDir", directory);
            this.tableKey++;
        }).catch(() => {
            this.loading = false;
            this.error = true;
        });
    },
    fileClick(file) {
        if(file.type == 'directory') {
            this.loadDirectory(this.directory + "/" + file.name);
        } else if(file.type == 'file') {
            const ext = file.name.split(".").pop();
            if(extensions.textExtensions.includes(ext) || file.name.substr(0, 1) == ".") {
                this.$refs["fileEdit"].show(this.directory + "/" + file.name);
            } else {
                this.fileDownload(file);
            }
        }
    },
    fileCreated(name) {
        this.loadDirectory(this.directory);
        const ext = name.split(".").pop();
        if(extensions.textExtensions.includes(ext)) {
            this.$refs["fileEdit"].show(this.directory + "/" + name);
        }
    },
    fileUploaded() {
        this.loadDirectory(this.directory);
        this.$store.dispatch("sync");
    },
    fileDownload(file) {
        this.currentFile = this.directory + "/" + file.name;
        this.currentAction = "/file/file/download";
        this.$nextTick(() => {
            this.$refs["form"].submit();
        });
    },
    goUp() {
        this.loadDirectory(this.directory.replace(/\/[^/]+$/, ""));
    },
    formAction() {
        return window.Config.apiHost + this.currentAction;
    },
    showMenu(event, file) {
      this.contextFile = file;

      this.contextMenu = [];
      if(file.type == 'directory') {
        this.contextMenu.push({ label: this.$_("Rename directory"), icon: "pi pi-file-edit", command: () => this.$refs["directoryRename"].show(this.contextFile, this.directory, this.fileNames) });
        this.contextMenu.push({ label: this.$_("Permissions (CHMOD)"), icon: "pi pi-unlock", command: () => this.$refs["fileChmod"].show(this.contextFile, this.directory) });
        this.contextMenu.push({ label: this.$_("Delete directory"), icon: "pi pi-trash", command: () => this.$refs["directoryDelete"].show(this.contextFile, this.directory) });
      } else if(file.type == 'file') {
        const ext = file.name.split(".").pop();
        if(extensions.textExtensions.includes(ext)) {
            this.contextMenu.push({ label: this.$_("Edit file"), icon: "pi pi-pencil", command: () => this.$refs["fileEdit"].show(this.directory + "/" + this.contextFile.name) });
        }
        this.contextMenu.push({ label: this.$_("Download file"), icon: "pi pi-download", command: () => this.fileDownload(this.contextFile) });
        this.contextMenu.push({ label: this.$_("Rename file"), icon: "pi pi-file-edit", command: () => this.$refs["fileRename"].show(this.contextFile, this.directory, this.fileNames) });
        this.contextMenu.push({ label: this.$_("Permissions (CHMOD)"), icon: "pi pi-unlock", command: () => this.$refs["fileChmod"].show(this.contextFile, this.directory) });
        this.contextMenu.push({ label: this.$_("Delete file"), icon: "pi pi-trash", command: () => this.$refs["fileDelete"].show(this.contextFile, this.directory) });
      } else if(file.type == 'link') {
        this.contextMenu.push({ label: this.$_("Rename link"), icon: "pi pi-file-edit", command: () => this.$refs["linkRename"].show(this.contextFile, this.directory, this.fileNames) });
//        this.contextMenu.push({ label: this.$_("Permissions (CHMOD)"), icon: "pi pi-unlock", command: () => this.$refs["fileChmod"].show(this.contextFile, this.directory) });
        this.contextMenu.push({ label: this.$_("Delete link"), icon: "pi pi-trash", command: () => this.$refs["linkDelete"].show(this.contextFile, this.directory) });
      }
      this.$refs["contextMenu"].show(event);
    },
  }
}

</script>

<style>

#createMenu {
    width: 240px;
    right: 0;
}
#uploadMenu {
    width: 240px;
    right: 0;
}

.file-buttons {
    border-bottom: 1px solid var(--gray-200);
}

.file-path {
    width: calc(100vw - 690px);
}
@media screen and (max-width:1199px) {
    .file-path { width: calc(100vw - 410px); }
}
@media screen and (max-width:1023px) {
    .file-path { width: calc(100vw - 385px); }
}
@media screen and (max-width:767px) {
    .file-path { width: calc(100vw - 165px); }
}

.file-root {
    height: calc(100vh - 133px);
    padding: 0;
    overflow-y: auto;
}
@media screen and (min-width:768px) {
    .file-root { height: calc(100vh - 163px); }
}
@media screen and (min-width:992px) {
    .file-root { height: calc(100vh - 178px); }
}

.file-table TR {
    cursor: pointer;
}
.file-table TR:hover {
    background: var(--gray-100);
}

.file-icon {
    padding-top: 3px;
}

.p-dropdown-item-group {
    padding: 0 10px;
}
.file-line {
    border-top: 1px solid var(--gray-400);
}

.file-loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
}

</style>