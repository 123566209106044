<template>
    <li class="mb-1">
        <a class="flex align-items-center cursor-pointer menu-item hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
            transition-duration-150 transition-colors p-ripple" v-if="!active" @click="click">
            <i :class="'mr-2 ' + icon"></i>
            <span class="font-medium">{{ text }}</span>
            <span v-if="badge>0" class="inline-flex align-items-center justify-content-center ml-auto bg-yellow-500 text-bluegray-900 menu-badge">{{ badge }}</span>
        </a>
        <div class="flex align-items-center cursor-pointer menu-item bg-bluegray-900 border-round text-bluegray-50" v-else>
            <i :class="'mr-2 ' + icon"></i>
            <span class="font-medium">{{ text }}</span>
            <span v-if="badge>0" class="inline-flex align-items-center justify-content-center ml-auto bg-yellow-500 text-bluegray-900 menu-badge">{{ badge }}</span>
        </div>
    </li>
</template>

<script>

export default {
  name: "MenuItem",
  props: {
    text: String,
    icon: String,
    link: String,
    active: Boolean,
    badge: Number
  },
  methods: {
    click() {
      this.$router.push({ name: this.link });
    }
  }
}
</script>

<style>
.menu-item {
    padding: .8rem .8rem;
}
.menu-badge {
    min-width: 1.5rem;
    height: 1.5rem;
    margin: -0.25rem 0;
    padding: 0 0.25rem;
    border-radius: 0.75rem;
}
</style>