const axios = require("axios");

import store from "../store";
import router from "../router";

function post(url, data) {
    const session = store.getters["session"];
    var headers = {};
    if(session) headers = {
        'Authorization' : 'Bearer ' + session,
    };
    return axios.post(window.Config.apiHost + url, data, { headers })
    .then((data) => {
        return data.data;
    }).catch((error) => {
        if(error.response) {
            if(error.response.status == 403) {
                window.setTimeout(() => {
                    store.commit("logout");
                    router.push({ path: "/login"});
                }, 1);
            } 
            throw { 
                code: error.response.status,
                type: error.response.data.type,
                message: error.response.data.message,
            };
        } else {
            throw { 
                code: 0,
                type: "networkError",
                message: "Network error",
            };
        }
    });
}

function upload(url, data, files, progress) {
    const session = store.getters["session"];

    let form = new FormData();
    for(let key in data) form.append(key, data[key]);
    for(let i = 0; i < files.length; i++) form.append("files[]", files[i]);

    var headers = {
        "Content-Type": "multipart/form-data",
    };
    if(session) headers = {
        'Authorization' : 'Bearer ' + session,
    };

    return axios.post(window.Config.apiHost + url, form, { 
        headers,
        onUploadProgress: progress
        })
    .then((data) => {
        return data.data;
    }).catch((error) => {
        if(error.response) {
            if(error.response.status == 403) {
                router.push({ name: "login "});
            } else throw { 
                code: error.response.status,
                type: error.response.data.type,
                message: error.response.data.message,
            };
        } else {
            throw { 
                code: 0,
                type: "networkError",
                message: "Network error",
            };
        }
    });
}

export { post, upload };