<template>
    <div>
    <Dialog 
        v-model:visible="visible" 
        modal
        :draggable="false"
        :header="$_('Domain configuration')"
        class="w-full lg:w-4 mx-2"
        >
        <div>
            <label class="block text-left font-semibold text-gray-700 mb-3">{{ $_('DNS servers') }}:</label>
            {{ $_('You need to point your domain to DNS servers:') }}
            <table cellpadding="0" cellspacing="0" class="mt-2">
                <tr v-for="ns in $store.getters['nameservers']" :key="ns">
                    <td class="py-2">{{ ns }}</td>
                    <td>
                        <CopyIcon :text="ns" :message="$_('Nameserver name copied to clipboard.')"/>
                    </td>
                </tr>
            </table>
        </div>
        <div class="flex justify-content-center gap-2 mt-4">
            <Button type="button" :label="$_('Close window')" severity="secondary" @click="hide"></Button>
        </div>
    </Dialog>
    </div>
</template>
    
<script>
import CopyIcon from "../../components/CopyIcon.vue";

export default {
    name: "InfoDomainsDialog",
    components: {
        CopyIcon,
    },
    data() {
        return {
            visible: false,
        }
    },
    methods: {
        show() {
            this.visible = true;
        },
        hide() {
            this.visible = false;
        },
    },
}
</script>