import { createRouter, createWebHashHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import DomainsView from '../views/DomainsView.vue'
import EmailsView from '../views/EmailsView.vue'
import DatabasesView from '../views/DatabasesView.vue'
import RedirectsView from '../views/RedirectsView.vue'
import AccessView from '../views/AccessView.vue'
import SslView from '../views/SslView.vue'
import LogsView from '../views/LogsView.vue'
import ScriptsView from '../views/ScriptsView.vue'
import AdvancedView from '../views/AdvancedView.vue'
import FilesView from '../views/FilesView.vue'
import AuthorizeView from '../views/AuthorizeView.vue'

import store from "../store";

const routes = [
  {
    path: '/',
    redirect: store.getters["session"] ? "/domains" : "/login",
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/domains',
    name: 'domains',
    component: DomainsView
  },
  {
    path: '/emails',
    name: 'emails',
    component: EmailsView
  },
  {
    path: '/databases',
    name: 'databases',
    component: DatabasesView
  },
  {
    path: '/redirects',
    name: 'redirects',
    component: RedirectsView
  },
  {
    path: '/ssl',
    name: 'ssl',
    component: SslView
  },
  {
    path: '/logs',
    name: 'logs',
    component: LogsView
  },
  {
    path: '/scripts',
    name: 'scripts',
    component: ScriptsView
  },
  {
    path: '/access',
    name: 'access',
    component: AccessView
  },
  {
    path: '/files',
    name: 'files',
    component: FilesView
  },
  {
    path: '/advanced',
    name: 'advanced',
    component: AdvancedView
  },
  {
    path: '/authorize/:token',
    name: 'authorize',
    component: AuthorizeView
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
