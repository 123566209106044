<template>
    <div>
    <Toast ref="toast"/>
    <Dialog 
        v-model:visible="visible" 
        modal
        :draggable="false"
        :header="$_('Upload a certificate')"
        class="w-full lg:w-6 mx-2"
        >
        <div class="certificate-step" v-if="step == 0">
            <label for="certField" class="block text-left font-semibold text-gray-700 mb-1">{{ $_('Domain certificate') }}:</label>
            <div>
                <Textarea
                    id="certField"
                    v-model="cert"
                    :autoresize="false"
                    :placeholder="certPlaceholder"
                    :invalid="certInvalid"
                    rows="3"
                    class="w-full certificate"
                    ref="certInput"
                />
                <div v-if="certInvalid" class="text-left text-red-500">
                    <small>{{  $_('You need to provide a valid SSL certificate.') }}</small>
                </div>
            </div>
            <label for="keyField" class="block text-left font-semibold text-gray-700 mb-1 mt-3">{{ $_('Private key') }}:</label>
            <div>
                <Textarea
                    id="keyField"
                    v-model="key"
                    :autoresize="false"
                    :placeholder="keyPlaceholder"
                    :invalid="keyInvalid"
                    rows="3"
                    class="w-full certificate"
                />
                <div v-if="keyInvalid" class="text-left text-red-500">
                    <small>{{  $_('You need to provide a valid certificate key.') }}</small>
                </div>
            </div>
            <label for="caField" class="block text-left font-semibold text-gray-700 mb-1 mt-3">{{ $_('Intermediate certificates') }}:</label>
            <div>
                <Textarea
                    id="caField"
                    v-model="ca"
                    :autoresize="false"
                    :placeholder="caPlaceholder"
                    :invalid="caInvalid"
                    rows="3"
                    class="w-full certificate"
                />
            </div>
            <div v-if="caInvalid" class="text-left text-red-500">
                <small>{{  $_('You need to provide a valid certificate chain.') }}</small>
            </div>
        </div>
        <div class="certificate-step" v-if="step == 1">
            <div v-if="error">
                <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
            </div>
            <label class="block text-left font-semibold text-gray-700 mb-1">{{ $_('Certificate issuer') }}:</label>
            <div>{{ issuer }}</div>
            <label class="block text-left font-semibold text-gray-700 mb-1 mt-3">{{ $_('Expiration date') }}:</label>
            <div>{{ expires }}</div>
            <label class="block text-left font-semibold text-gray-700 mb-1 mt-3">{{ $_('Domains in the certificate') }}:</label>
            <div v-for="(wildcard, domain) in domains" :key="domain">{{ wildcard ? '*.' + domain : domain }}</div>
            <label class="block text-left font-semibold text-gray-700 mb-1 mt-3">{{ $_('Matching domains') }}:</label>
            <div v-if="matchedDomains.length">
                <div v-for="domain in matchedDomains" :key="domain">{{ domain }}</div>
            </div>
            <div v-else>
                {{  $_('None') }}
            </div>
        </div>
        <div class="flex justify-content-between gap-2 mt-4" v-if="step == 0">
            <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"></Button>
            <Button type="button" :label="$_('Check certificate')" @click="check" :loading="checking" icon="pi pi-arrow-right" iconPos="right"></Button>
        </div>
        <div class="flex justify-content-between gap-2 mt-4" v-if="step == 1">
            <Button type="button" :label="$_('Go back')" severity="secondary" icon="pi pi-arrow-left" @click="folderInvalid=false; step--;"/>
            <Button type="button" :label="$_('Install certificate')" @click="action" :disabled="invalid" :loading="loading"/>
        </div>
    </Dialog>
    </div>
</template>
    
<script>
//import CopyIcon from "../../components/CopyIcon.vue";

const requester = require("../../libraries/api.js");

export default {
    name: "CertificateUploadDialog",
    components: {
//        CopyIcon,
    },
    data() {
        return {
            visible: false,
            step: 0,
            domain: "",
            cert: "",
            certInvalid: false,
            key: "",
            keyInvalid: false,
            ca: "",
            caInvalid: false,
            checking: false,
            loading: false,
            error: "",
            certPlaceholder: "-----BEGIN CERTIFICATE-----\nMIIGRTCCBS2gAwIBAgIQW04U/0hKW99NvJ9u0q9CoTANBgkqhkiG9w0BAQsFADCB\nMB4XDTIyMDExMDAwMDAwMFoXDTIzMDExMDIzNTk1OVowHjEcMBoGA1UEAxMTbmll\nYmllc2tpZWJhbG9ueS5wbDCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEB\nrPmZPf3xW1NpuA8+Nv8A3wPRqKgRXIFs/w==\n-----END CERTIFICATE-----",
            keyPlaceholder: "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDBFlIycca5PQFV\nSV5vpad7dG7oA6Byd7NfW2mem500/zWyEcFFmZk8I8Zd7mbarabLEEYIH0zS8YiM\nuIam7XtSw6yu+H35bHdermhxJQPizID7GNZOF+JS08ZnssSvmffN9Ak0uGmXAU6l\nDrrlmQj3TdWXg42KJmSmV3k=\n-----END PRIVATE KEY-----",
            caPlaceholder: "-----BEGIN CERTIFICATE-----\nMIIEMjCCAxqgAwIBAgIBATANBgkqhkiG9w0BAQUFADB7MQswCQYDVQQGEwJHQjEb\nBwwHU2FsZm9yZDEaMBgGA1UECgwRQ29tb2RvIENBIExpbWl0ZWQxITAfBgNVBAMM\nGEFBQSBDZXJ0aWZpY2F0ZSBTZXJ2aWNlczCCASIwDQYJKoZIhvcNAQEBBQADggEP\n00u/I5sUKUErmgQfky3xxzlIPK1aEn8=",
            invalid: false,
            subjects: [],
            domains: [],
            matchedDomains: [],
            expires: "",
        }
    },
    methods: {
        show(domain) {
            this.step = 0;
            this.domain = domain;
            this.cert = "";
            this.key = "";
            this.ca = "";
            this.certInvalid = false,
            this.keyInvalid = false,
            this.caInvalid = false,
            this.error = "";
            this.visible = true;
            this.$nextTick(() => {
                this.$refs["certInput"].$el.focus();
            });
        },
        hide() {
            this.visible = false;
        },
        check() {
            this.certInvalid = false;
            this.keyInvalid = false;
            this.caInvalid = false;

            if(!this.cert.trim().length || !this.cert.match(/-----BEGIN CERTIFICATE-----/)) {
                this.certInvalid = true;
                return;
            }
            if(!this.key.trim().length || !this.key.match(/-----BEGIN PRIVATE KEY-----/)) {
                this.keyInvalid = true;
                return;
            }
            if(!this.ca.trim().length || !this.ca.match(/-----BEGIN CERTIFICATE-----/)) {
                this.caInvalid = true;
                return;
            }

            this.matchedDomains = [];
            this.checking = true;
            return requester.post("/certificate/check", {
                cert: this.cert,
                key: this.key,
                ca: this.ca,
            }).then((data) => {
                this.checking = false;
                this.step++;

                this.subjects = data.info.subjects;
                this.domains = data.info.domains;
                this.expires = data.info.expires;
                this.issuer = data.info.issuer;

                if(!data.info.validKey) {
                    this.invalid = true;
                    this.error = this.$_("The provided private key does not match the certificate.");
                    return;
                }

                if(data.info.selfSigned) {
                    this.invalid = true;
                    this.error = this.$_("The certificate is self-signed.");
                    return;
                }

                if(data.info.expired) {
                    this.invalid = true;
                    this.error = this.$_("The certificate is expired.");
                    return;
                }

                if(!data.info.allSigned) {
                    this.invalid = true;
                    this.error = this.$_("One or more intermediate certificates is missing.");
                    return;
                }

                this.subjects.forEach((subject) => {
                    this.$store.getters["domains"].forEach((item) => {
                        if(item.domain == subject) this.matchedDomains.push(subject);
                    });
                });
                // Check if wildcard certificates match any added subdomains.
                for(let domain in this.domains) if(this.domains[domain]){
                    this.$store.getters["domains"].forEach((item) => {
                        if(item.domain.endsWith("." + domain) && !this.matchedDomains.includes(item.domain)) this.matchedDomains.push(item.domain);
                    });
                }
                if(!this.matchedDomains.length) {
                    this.invalid = true;
                    this.error = this.$_("The certificate does not match any domain in your account.");
                    return;
                }

                this.invalid = false;
            }).catch(() => {
                this.certInvalid = true;
                this.checking = false;
                this.step--;
            });
        },
        action() {
            this.error = "";
            this.loading = true;

            var promises = [];

            this.matchedDomains.forEach((domain) => {
                promises.push(this.$store.dispatch("certificateUpload", { 
                    domain: domain,
                    cert: this.cert,
                    key: this.key,
                    ca: this.ca,
                }));
            });
            Promise.all(promises).then(() => {
                this.loading = false;
                this.visible = false;
                this.$refs["toast"].add({
                    severity: 'success', 
                    summary: this.$_("Operation performed successfully."), 
                    detail: this.$_("SSL certificate uploaded") + ": " + this.matchedDomains[0], 
                    life: 3000
                });
            }).catch((error) => {
                switch(error.code) {
                    default:
                        this.error = this.$_("Unknown error.");
                }
                this.loading = false;
            });
        }
    },
    watch: {
        cert() { this.certInvalid = false; },
        key() { this.keyInvalid = false; },
        ca() { this.caInvalid = false; },
    }
}
</script>

<style>
TEXTAREA.certificate {
    height: 70px;
    font-size: 13px;
}

.certificate-step {
    min-height: 330px;
}
</style>