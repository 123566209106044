<template>
<div>
    <Toast ref="toast"/>
    <Dialog 
        v-model:visible="visible" 
        modal
        :draggable="false"
        :header="$_('Add a new Web app')"
        class="w-full lg:w-4 mx-2"
        >
        <div v-if="error">
            <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
        </div>
        <label class="block text-left font-semibold text-gray-700 mb-1">{{ $_('Domain name') }}:</label>
        <Dropdown v-model="domain" :options="domains" :placeholder="$_('Select a domain')" class="w-full"/>
        <div v-if="domainInvalid" class="text-left text-red-500">
            <small>{{  $_('You need to select a domain name.') }}</small>
        </div>
        <label class="block text-left font-semibold text-gray-700 mb-2 mt-3">{{ $_('App location') }}:</label>
        <div class="flex align-items-center">
            <RadioButton v-model="root" inputId="locationRoot" name="root" :value="true"/>
            <label for="locationRoot" class="ml-2">{{ $_('Install in the main directory') }}</label>
        </div>
        <div class="flex align-items-center mt-2">
            <RadioButton v-model="root" inputId="locationFolder" name="root" :value="false"/>
            <label for="locationFolder" class="ml-2">{{ $_('Install in a subdirectory') }}</label>
        </div>
        <div v-if="!root">
            <label for="folderField" class="block text-left font-semibold text-gray-700 mb-1 mt-3">{{ $_('Subdirectory name') }}:</label>
            <InputText
                id="folderField"
                type="text"
                v-model="folder"
                :invalid="folderInvalid"
                class="w-full"
                :placeholder="$_('/myscript')"
                ref="input"
            />
            <div v-if="folderInvalid" class="text-left text-red-500">
                <small>{{  $_('You need to enter a valid subdirectory name.') }}</small>
            </div>
        </div>
        <div class="mt-3">
            <label for="portField" class="block text-left font-semibold text-gray-700 mb-1">{{ $_('Local port number') }}:</label>
                <InputNumber
                    class="w-full"
                    id="portField"
                    type="text"
                    v-model="port"
                    :invalid="portInvalid"
                    :minFractionDigits="0"
                    :useGrouping="false"
                    :min="1024"
                    :max="65535"
                    ref="input"
                />
            <div v-if="portInvalid" class="text-left text-red-500">
                <small>{{  $_('Choose a value between 1024 and 65535.') }}</small>
            </div>
        </div>
        <div class="flex justify-content-between gap-2 mt-4">
            <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"/>
            <Button type="button" :label="$_('Add Web app')" @click="action" :loading="loading"/>
        </div>
    </Dialog>
</div>
</template>
    
<script>

export default {
    name: "ProxyCreateDialog",
    data() {
        return {
          visible: false,
          domain: "",
          domainInvalid: false,
          root: true,
          folder: "",
          folderInvalid: false,
          port: null,
          portInvalid: false,
          loading: false,
          error: "",
        }
      },
      computed: {
        domains() {
            return this.$store.getters["domains"].map(item => item.domain);
        },
      },
      methods: {
        show(domain = "") {
            this.domain = domain;
            this.domainInvalid = false;
            this.root = true;
            this.folder = "";
            this.folderInvalid = false;
            this.port = null;
            this.portInvalid = false;
            this.visible = true;
            this.error = "";
        },
        hide() {
            this.visible = false;
        },
        action() {
            this.error = "";
            this.folderInvalid = false;
            this.portInvalid = false;

            if(!this.domain.length) {
                this.domainInvalid = true;
                return;
            }
            if(!this.root && (!this.folder.trim() || this.folder.match(/[^a-zA-Z0-9_./-]/) || this.folder.match(/\/\//) || this.folder.trim() == "/")) {
                this.folderInvalid = true;
                return;
            }            
            if(this.port < 1024 || this.port > 65535) {
                this.portInvalid = true;
                return;
            }

            var folder = "/";
            if(!this.root) {
                folder = this.folder.trim();
                if(!folder.match(/^\//)) folder = "/" + folder;
                if(!folder.match(/\/$/)) folder = folder + "/";        
            }

            this.$store.getters["proxies"].forEach((item) => {
                if(item.port == this.port) this.error = this.$_("This port is used by another Web app.");
            })
            this.$store.getters["proxies"].forEach((item) => {
                if(item.domain == this.domain && item.directory == folder) this.error = this.$_("You have already added this Web app.");
            })
            if(this.error) return;

            this.loading = true;
            this.$store.dispatch("proxyCreate", { 
                domain: this.domain,
                directory: folder,
                port: this.port,
            }).then(() => {
                this.loading = false;
                this.visible = false;
                this.$refs["toast"].add({
                    severity: 'success', 
                    summary: this.$_("Operation performed successfully."), 
                    detail: this.$_("Web app was added") + ": http://" + this.domain + folder, 
                    life: 3000
                });
            }).catch((error) => {
                switch(error.code) {
                    default:
                        this.error = this.$_("Unknown error.");
                }
                this.loading = false;
            });
        }
      },
      watch: {
        folder() { this.folderInvalid = false; },
        login() { this.loginInvalid = false; },
        password() { this.passwordInvalid = false; },
        password2() { this.password2Invalid = false; },
      }
    }
</script>

<style>
.install-step {
    min-height: 250px;
}
</style>