<template>
    <div>
    <Toast ref="toast"/>
    <Dialog 
        v-model:visible="visible" 
        modal
        :draggable="false"
        :header="$_('Remove the trusted sender')"
        class="w-full lg:w-4 mx-2"
        >
        <div v-if="error">
            <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
        </div>
        <div>
            <label class="block text-left font-semibold text-gray-700 mb-2">{{ $_('Recipient domain name') }}:</label>
            <div>
                {{ domain }}
            </div>
        </div>
        <div>
            <label class="block text-left font-semibold text-gray-700 mt-3 mb-2">{{ $_('Sender address') }}:</label>
            <div>
                {{ sender }}
            </div>
        </div>
        <div class="flex justify-content-between gap-2 mt-4">
            <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"></Button>
            <Button type="button" :label="$_('Remove sender')" severity="danger" @click="action" :loading="loading"></Button>
        </div>
    </Dialog>
    </div>
</template>
    
<script>
    export default {
      name: "AliasDeleteDialog",
      data() {
        return {
          visible: false,
          domain: "",
          sender: "",
          loading: false,
          error: "",
        }
      },
      methods: {
        show(domain, sender) {
            this.domain = domain;
            this.sender = sender;
            this.error = "";
            this.visible = true;
        },
        hide() {
            this.visible = false;
        },
        action() {
            this.error = "";
            
            this.loading = true;
            this.$store.dispatch("emailWhitelistRemove", { 
                domain: this.domain,
                sender: this.sender,
            }).then(() => {
                this.loading = false;
                this.visible = false;
                this.$refs["toast"].add({
                    severity: 'success', 
                    summary: this.$_("Operation performed successfully."), 
                    detail: this.$_("Trusted sender was removed") + ": " + this.sender, 
                    life: 3000
                });
            }).catch((error) => {
                switch(error.code) {
                    default:
                        this.error = this.$_("Unknown error.");
                }
                this.loading = false;
            });
        }
      },
    }
    </script>