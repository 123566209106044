<template>
<span>
    <a v-ripple class="copy-icon align-items-center text-600 hover:text-900 surface-100 hover:surface-200 font-medium border-round cursor-pointer
        transition-duration-150 transition-colors p-ripple" @click="copy">
        <i class="pi pi-copy text-base"></i>
    </a>
    <Toast ref="toast"/>
</span>
</template>

<script>

export default {
  name: "CopyIcon",
  props: {
    text: String,
    message: String,
  },
  methods: {
    copy() {
        navigator.clipboard.writeText(this.text).then(() => {
            this.$refs["toast"].add({
                severity: 'info', 
                summary: this.$_("Copied to clipboard."), 
                detail: this.message, 
                life: 3000
            });
        });
    },
  },
}

</script>

<style>
.copy-icon {
    padding: .4rem .5rem;
    margin-left: .25rem;
}
</style>
