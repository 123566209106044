<template>
<ViewRoot active="logs">
  <div class="hidden md:block">
    <TabMenu :model="tabMenu" :activeIndex="currentTab"/>
  </div>
  <div class="block md:hidden">
    <TabMenu :model="mobileMenu" :activeIndex="currentTab"/>
  </div>
    <div v-if="currentTab == 0">
        <div class="w-full px-5 mt-3 align-items-center text-gray-400 hidden sm:flex" v-if="$store.getters['syncing']">
            <i class="pi pi-spinner spin"></i>
            <div class="ml-2">{{ $_('The statistics are being updated...') }}</div>
        </div>
        <div class="w-full px-3 mt-3 stat-panels1">
            <div class="flex flex-column lg:flex-row">
                <div class="w-full lg:w-9 lg:mr-1 mb-2 md:mb-0">
                    <Panel :header="$_('Total disk usage') + ':'">
                        <MeterGroup :value="diskUsage" class="w-12"/>
                    </Panel>
                </div>
                <div class="w-full lg:w-3 lg:ml-1 mt-2 lg:mt-0">
                    <Panel :header="$_('Available disk space') + ':'">
                        <div class="mb-3 stat-size">
                            {{ showSize($store.getters["diskLimit"])  }}
                        </div>
                        <Button
                            class="lg:w-full"
                            icon="pi pi-external-link"
                            iconPos="right"
                            :label="$_('Get more space')"
                            @click="upgradePackage"
                        />
                    </Panel>
                </div>
            </div>
        </div>
        <div class="w-full px-3 stat-panels2">
            <div class="hidden sm:flex mt-2 flex-column md:flex-row">
                <div class="w-full md:w-6 md:mr-1 mb-2 md:mb-0">
                    <Panel :header="$_('Biggest websites') + ':'">
                        <div class="stat-button">
                            <Button
                                :label="$_('Show all')"
                                size="small"
                                severity="secondary"
                                icon="pi pi-angle-right"
                                iconPos="right"
                                @click="$router.push({ name: 'domains'})"
                            />
                        </div>
                        <table cellpadding="0" cellspacing="0" class="w-full">
                            <tr v-for="item in biggestDomains" :key="item.domain">
                                <td class="pt-1 pb-1"><div class="ellipsis ellipsis-250">{{ item.domain }}</div></td>
                                <td class="pt-1 pb-1 text-right">{{ showSize(item.diskUsed) }}</td>
                            </tr>
                        </table>
                    </Panel>
                </div>
                <div class="w-full md:w-6 md:ml-1">
                    <Panel :header="$_('Biggest databases') + ':'">
                        <div class="stat-button" v-if="biggestDatabases.length">
                            <Button
                                :label="$_('Show all')"
                                size="small"
                                severity="secondary"
                                icon="pi pi-angle-right"
                                iconPos="right"
                                @click="$router.push({ name: 'databases'})"
                            />
                        </div>
                        <table cellpadding="0" cellspacing="0" class="w-full" v-if="biggestDatabases.length">
                            <tr v-for="item in biggestDatabases" :key="item.name">
                                <td class="pt-1 pb-1"><div class="ellipsis ellipsis-250">{{ item.name }}</div></td>
                                <td class="pt-1 pb-1 text-right">{{ showSize(item.diskUsed) }}</td>
                            </tr>
                        </table>
                        <div v-else>
                            {{  $_('You have not created any databases.') }}
                        </div>
                    </Panel>
                </div>
            </div>
            <div class="hidden sm:flex mt-2 flex-column md:flex-row pb-3">
                <div class="w-full md:w-6 md:mr-1 mb-2 md:mb-0">
                    <Panel :header="$_('Biggest e-mail usage') + ':'">
                        <div class="stat-button" v-if="biggestEmails.length">
                            <Button
                                :label="$_('Show all')"
                                size="small"
                                severity="secondary"
                                icon="pi pi-angle-right"
                                iconPos="right"
                                @click="$router.push({ name: 'emails'})"
                            />
                        </div>
                        <table cellpadding="0" cellspacing="0" class="w-full" v-if="biggestEmails.length">
                            <tr v-for="item in biggestEmails" :key="item.name + '@' + item.domain">
                                <td class="pt-1 pb-1"><div class="ellipsis ellipsis-250">{{ item.name + '@' + item.domain }}</div></td>
                                <td class="pt-1 pb-1 text-right">{{ showSize(item.diskUsed) }}</td>
                            </tr>
                        </table>
                        <div v-else>
                            {{  $_('You have not created any e-mail accounts.') }}
                        </div>
                    </Panel>
                </div>
                <div class="w-full md:w-6 md:ml-1">
                    <Panel :header="$_('Biggest e-mail capacity') + ':'">
                        <div class="stat-button" v-if="capacityEmails.length">
                            <Button
                                :label="$_('Show all')"
                                size="small"
                                severity="secondary"
                                icon="pi pi-angle-right"
                                iconPos="right"
                                @click="$router.push({ name: 'emails'})"
                            />
                        </div>
                        <table cellpadding="0" cellspacing="0" class="w-full" v-if="capacityEmails.length">
                            <tr v-for="item in capacityEmails" :key="item.name + '@' + item.domain">
                                <td class="pt-1 pb-1"><div class="ellipsis ellipsis-250">{{ item.name + '@' + item.domain }}</div></td>
                                <td class="pt-1 pb-1 text-right">{{ showSize(item.diskLimit) }}</td>
                            </tr>
                        </table>
                        <div v-else>
                            {{  $_('You have not created any e-mail accounts.') }}
                        </div>
                    </Panel>
                </div>
            </div>
        </div>
    </div>
    <div v-if="currentTab == 1">
        <DataTable :value="$store.getters['domains'].filter((item) => item.domain.includes(this.filter.trim().toLowerCase()))">
            <template #header>
                <IconField iconPosition="left" class="w-full">
                    <InputIcon>
                        <i class="pi pi-search"></i>
                    </InputIcon>
                    <InputText
                        class="w-full"
                        v-model="filter"
                        :placeholder="$_('Search domains...')"
                    />
                </IconField>
            </template>
            <template #empty>
                <div>{{  $_('No domain names match the search criteria.') }}</div>
            </template>
          <Column :header="$_('Domain name')">
            <template #body="slot">
                <div class="flex">
                    <div class="ellipsis ellipsis-400">{{ slot.data.domain }}</div>
                </div>
            </template>
          </Column>
          <Column>
          <template #body="slot">
              <div class="table-buttons hidden lg:block">
              <Button 
                  :label="$_('Show domain logs')"
                  severity="secondary"
                  size="small"
                  icon="pi pi-chevron-down"
                  iconPos="right"
                  @click="showLogMenu($event, slot.data.domain, true)"
              />
              </div>
              <a v-ripple class="cursor-pointer block lg:hidden text-700 p-ripple text-right" @click="showLogMenu($event, slot.data.domain, false)">
                  <i class="pi pi-ellipsis-v"></i>
              </a>
          </template>
          </Column>
      </DataTable>
    </div>
    <ContextMenu ref="contextMenu" :model="contextMenu"/>
    <LogShowDialog ref="logShow"/>
</ViewRoot>
</template>

<script>

import ViewRoot from "../components/ViewRoot.vue";

import LogShowDialog from "../dialogs/log/LogShowDialog.vue";

import { size } from "../mixins/size.js";
import { upgrade } from "../mixins/upgrade.js";

export default {
  name: "LogsView",
  components: {
    ViewRoot,
    LogShowDialog,
  },
  mixins: [
    size,
    upgrade
  ],
  mounted() {
    const redirect = this.$store.getters["redirect"];
    switch(redirect.action) {
      case "stats":
        this.currentTab = 0;
        break;
      case "logs":
        this.currentTab = 1;
        break;
    }
    this.$store.commit("redirectClear");
    this.$store.dispatch("keepalive");
  },
  data() {
    return {
        filter: "",
        currentTab: 0,
        contextDomain: "",
        contextMenu: [],
    }
  },
  computed: {
    tabMenu() {
      return [
        { label: this.$_("Disk usage"), command: () => this.currentTab = 0 },
        { label: this.$_("Server logs"), command: () => this.currentTab = 1 },
      ];
    },
    mobileMenu() {
      return [
        { label: this.$_("Disk"), command: () => this.currentTab = 0 },
        { label: this.$_("Logs"), command: () => this.currentTab = 1 },
      ];
    },
    diskUsage() {
        const limit = this.$store.getters["diskLimit"];
        if(limit > 0) {
            return [
                { label: this.$_("Websites"), value: 100*this.$store.getters["sizeDomains"]/limit, color: '#60a5fa' },
                { label: this.$_("Databases"), value: 100*this.$store.getters["sizeDatabases"]/limit, color: '#34d399' },
                { label: this.$_("E-mail messages"), value: 100*this.$store.getters["sizeEmails"]/limit, color: '#fbbf24' },
                { label: this.$_("Backups"), value: 0, color: '#ef4444' },
                { label: this.$_("Other files"), value: 100*this.$store.getters["sizeRest"]/limit, color: '#c084fc' },
                { label: this.$_("Unused e-mail capacity"), value: 100*this.$store.getters["sizeReserved"]/limit, color: '#c3c7ce' },
            ];
        } else {
            return [{ label: this.$_("Disk usage"), value: 0, color: '#3B82F6' }];
        }
    },
    biggestDomains() {
        return [...this.$store.getters["domains"]].sort((a, b) => b.diskUsed - a.diskUsed).slice(0, 4);
    },
    biggestDatabases() {
        return [...this.$store.getters["databases"]].sort((a, b) => b.diskUsed - a.diskUsed).slice(0, 4);
    },
    biggestEmails() {
        return [...this.$store.getters["emailAccounts"]].sort((a, b) => b.diskUsed - a.diskUsed).slice(0, 4);
    },
    capacityEmails() {
        return [...this.$store.getters["emailAccounts"]].sort((a, b) => b.diskLimit - a.diskLimit).slice(0, 4);
    },
  },
  methods: {
    showLogMenu(event, domain) {
      this.contextDomain = domain;

      this.contextMenu = [];
      this.contextMenu.push({ label: this.$_("Show access log"), icon: "pi pi-align-left", command: () => this.$refs["logShow"].show(this.contextDomain, 'access') });
      this.contextMenu.push({ label: this.$_("Show error log"), icon: "pi pi-align-left", command: () => this.$refs["logShow"].show(this.contextDomain, 'error') });
      this.$refs["contextMenu"].show(event);
    },
  }
}

</script>

<style>
.stat-panels1 .p-panel-content {
    min-height: 98px;
    position: relative;
}
.stat-panels2 .p-panel-content {
    min-height: 128px;
    position: relative;
}
.stat-size {
    font-size: 1.4rem;
    font-weight: 600;
}
.stat-button {
    position: absolute;
    right: 16px;
    top: -42px;
}

.spin {
  animation-name: spin;
  animation-duration: 2500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
</style>