<template>
  <div class="login-container">
    <div class="surface-card p-4 shadow-2 border-round w-full lg:w-4 mx-2">
        <div class="text-center mb-5">
            <div class="text-900 text-3xl font-medium mb-3">{{ $_('Welcome back!') }}</div>
        </div>

        <div v-if="error">
          <Message severity="error" :closable="false">{{ error }}</Message>
        </div>

        <div>
            <label for="loginField" class="block text-left font-semibold text-gray-600 mb-1">{{ $_('Your login') }}:</label>
            <InputText id="loginField" type="text" v-model="login" :invalid="loginInvalid" class="w-full" ref="login" @keyup.enter="$refs['password'].$el.focus()"/>
            <div v-if="loginInvalid" class="text-left text-red-500">
              <small>{{  $_('You need to enter a valid login.') }}</small>
            </div>

            <label for="passwordField" class="block text-left font-semibold text-gray-600 mt-3 mb-1">{{ $_('Your password') }}:</label>
            <InputText id="passwordField" type="password" v-model="password" :invalid="passwordInvalid" class="w-full" ref="password" @keyup.enter="loginClick"/>
            <div v-if="passwordInvalid" class="text-left text-red-500">
              <small>{{  $_('You need to enter a valid password.') }}</small>
            </div>

            <div class="mt-4">
              <Button :label="$_('Sign In')" class="w-full" @click="loginClick" :loading="loading"></Button>
            </div>
        </div>
    </div>
  </div>
</template>
  
<script>

export default {
  name: "LoginView",
  data() {
    return {
      login: "",
      password: "",
      loginInvalid: false,
      passwordInvalid: false,
      error: "",
      loading: false,
    }
  },
  methods: {
    loginClick() {
      this.loginInvalid = false;
      this.passwordInvalid = false;
      this.error = "";
      this.loading = false;

      if(!this.login.trim().length) {
        this.loginInvalid = true;
        return;
      }
      if(!this.password.trim().length) {
        this.passwordInvalid = true;
        return;
      }

      this.loading = true;
      this.$store.dispatch("login", {
        login: this.login,
        password: this.password,
      }).then(() => {
        this.loading = false;
        this.$router.push({ name: "domains" });
      }).catch((error) => {
        switch(error.code) {
          case 403:
            this.error = this.$_("Invalid login or password.");
            break;
          default:
            this.error = this.$_("Unknown error.");
        }
        this.loading = false;
      })
    },
  },
  watch: {
    login() {
      this.loginInvalid = false;
    },
    password() {
      this.passwordInvalid = false;
    },
  }
}
</script>


<style>
.login-container
{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>