<template>
    <div>
    <Dialog 
        v-model:visible="visible" 
        modal
        :draggable="false"
        :header="$_('Postgres access data')"
        class="w-full lg:w-4 mx-2"
        >
        <table cellpadding="0" cellspacing="0">
            <tr>
                <td colspan="2" class="py-1">
                    <label class="block text-left font-semibold text-gray-700">{{ $_('Postgres server') }}:</label>
                </td>
            </tr>
            <tr>
                <td class="py-1">
                    {{  $store.getters["postgresHost"] }}
                </td>
                <td>
                    <CopyIcon :text="$store.getters['postgresHost']" :message="$_('Postgres server name copied to clipboard.')"/>
                </td>
            </tr>
            <tr>
                <td colspan="2" class="pb-1 pt-2">
                    <label class="block text-left font-semibold text-gray-700">{{ $_('Postgres user') }}:</label>
                </td>
            </tr>
            <tr>
                <td class="py-1">
                    {{  $store.getters["postgresUser"] }}
                </td>
                <td>
                    <CopyIcon :text="$store.getters['postgresUser']" :message="$_('Postgres user name copied to clipboard.')"/>
                </td>
            </tr>
            <tr>
                <td colspan="2" class="pb-1 pt-2">
                    <label class="block text-left font-semibold text-gray-700">{{ $_('Postgres password') }}:</label>
                </td>
            </tr>
            <tr>
                <td class="py-1">
                    {{  $store.getters["postgresPassword"] }}
                </td>
                <td>
                    <CopyIcon :text="$store.getters['postgresPassword']" :message="$_('Postgres password copied to clipboard.')"/>
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <div class="mt-1">
                        <Button severity="secondary" icon="pi pi-pencil" :label="$_('Change password')" size="small" @click="$refs['databasePassword'].show('postgres')"/>
                    </div>
                </td>
            </tr>
        </table>
        <div class="flex justify-content-center gap-2 mt-4">
            <Button type="button" :label="$_('Close window')" severity="secondary" @click="hide"></Button>
        </div>
    </Dialog>
    <DatabasePasswordDialog ref="databasePassword"/>
    </div>
</template>
    
<script>

import CopyIcon from "../../components/CopyIcon.vue";
import DatabasePasswordDialog from "../database/DatabasePasswordDialog.vue";

export default {
    name: "InfoPostgresDialog",
    components: {
        CopyIcon,
        DatabasePasswordDialog,
    },
    data() {
        return {
            visible: false,
        }
    },
    methods: {
        show() {
            this.visible = true;
        },
        hide() {
            this.visible = false;
        },
    },
}
</script>