<template>
    <div>
    <Toast ref="toast"/>
    <Dialog 
        v-model:visible="visible" 
        modal
        :draggable="false"
        :header="$_('Add a SSH key')"
        class="w-full lg:w-4 mx-2"
        >
        <div v-if="error">
            <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
        </div>
        <div>
            <label for="keyField" class="block text-left font-semibold text-gray-700 mb-1">{{ $_('Public SSH key') }}:</label>
            <InputText
                id="keyField"
                type="text"
                v-model="key"
                :invalid="keyInvalid"
                class="w-full"
                placeholder="sh-rsa AAAAB3NzaC1yc2EAADAQABAAABAQDByuNNi2dMzJ2rGU7I8a0GBeZINwLYv9tTB84B154NqAz2I2FJZ imported-openssh-key"
                ref="input"
            />
            <div v-if="keyInvalid" class="text-left text-red-500">
                <small>{{  $_('You need to provide a valid public SSH key.') }}</small>
            </div>
        </div>
        <div>
            <label for="nameField" class="block text-left font-semibold text-gray-700 mb-1 mt-3">{{ $_('Key name (optional)') }}:</label>
            <InputText
                id="nameField"
                type="text"
                v-model="name"
                :invalid="nameInvalid"
                class="w-full"
                :placeholder="$_('My Linux laptop')"
            />
            <div v-if="destinationInvalid" class="text-left text-red-500">
                <small>{{  $_('You need to enter a name for the SSH key.') }}</small>
            </div>
        </div>
        <div class="flex justify-content-between gap-2 mt-4">
            <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"></Button>
            <Button type="button" :label="$_('Add key')" @click="action" :loading="loading"></Button>
        </div>
    </Dialog>
    </div>
</template>
    
<script>
    export default {
      name: "KeyUploadDialog",
      data() {
        return {
          visible: false,
          key: "",
          keyInvalid: false,
          name: "",
          nameInvalid: false,
          loading: false,
          error: "",
        }
      },
      methods: {
        show() {
            this.key = "";
            this.keyInvalid = false;
            this.name = "";
            this.nameInvalid = false;
            this.error = "";
            this.visible = true;
            this.$nextTick(() => {
                this.$refs["input"].$el.focus();
            });
        },
        hide() {
            this.visible = false;
        },
        action() {
            this.error = "";

            this.keyInvalid = false;
            this.nameInvalid = false;

            if(!this.key.trim().length) {
                this.keyInvalid = true;
                return;
            }

            var name = this.name.trim();
            if(name) {
                if(this.$store.getters["sshKeys"].filter((item) => {
                    return item.name == name;
                }).length) {
                    this.error = this.$_("You already have a key with this name.")
                    return;
                }
            } else {
                for(let i = 1; ; i++) {
                    name = this.$_("SSH key") + " #" + i.toString();
                    var found = false;
                    if(this.$store.getters["sshKeys"].filter((item) => {
                        return item.name == name;
                    }).length) found = true;
                    if(!found) break;
                }
            }

            this.loading = true;
            this.$store.dispatch("sshKeyUpload", { 
                key: this.key,
                name: name,
            }).then(() => {
                this.loading = false;
                this.visible = false;
                this.$refs["toast"].add({
                    severity: 'success', 
                    summary: this.$_("Operation performed successfully."), 
                    detail: this.$_("SSH key added to your account."),
                    life: 3000
                });
            }).catch((error) => {
                switch(error.code) {
                    case 406:
                        this.error = this.$_("Incorrect public SSH key.");
                        break;
                    case 409:
                        this.error = this.$_("You have already added this key.");
                        break;
                    default:
                        this.error = this.$_("Unknown error.");
                }
                this.loading = false;
            });
        }
      },
      watch: {
        accordion(value) {
            if(value === 0) 
                this.$refs["accordion"].$el.getElementsByClassName("p-toggleable-content")[0].style.display = "block";
        },
        domain() { this.domainInvalid = false; },
        folder() { this.folderInvalid = false; },
        destination() { this.domainInvalid = false; },
      }
    }
    </script>