<template>
    <div>
        <Toast ref="toast"/>
        <Dialog
            v-model:visible="visible" 
            modal
            :draggable="false"
            :header="$_('Modify the Web app')"
            class="w-full lg:w-4 mx-2"
            >
            <div v-if="error">
                <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
            </div>
            <label class="block text-left font-semibold text-gray-700 mb-2">{{ $_('Web app address') }}:</label>
            <div>
                {{ 'http://' + domain + folder }}
            </div>
            <div class="mt-3">
                <label for="portField" class="block text-left font-semibold text-gray-700 mb-1">{{ $_('Local port number') }}:</label>
                    <InputNumber
                        class="w-full"
                        id="portField"
                        type="text"
                        v-model="port"
                        :invalid="portInvalid"
                        :minFractionDigits="0"
                        :useGrouping="false"
                        :min="1024"
                        :max="65535"
                        ref="input"
                    />
                <div v-if="portInvalid" class="text-left text-red-500">
                    <small>{{  $_('Choose a value between 1024 and 65535.') }}</small>
                </div>
            </div>
            <div class="flex justify-content-between gap-2 mt-4">
                <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"/>
                <Button type="button" :label="$_('Modify Web app')" @click="action" :loading="loading"/>
            </div>
        </Dialog>
    </div>
    </template>
        
<script>

export default {
    name: "ProxyCreateDialog",
    data() {
        return {
          visible: false,
          domain: "",
          folder: "",
          loading: false,
          error: "",
        }
      },
      methods: {
        show(domain = "", folder = "") {
            this.domain = domain;
            this.folder = folder;
            this.port = this.$store.getters["proxies"].find((item) => 
                item.domain == domain && item.directory == folder
            ).port;
            this.portInvalid = false;
            this.visible = true;
            this.error = "";
        },
        hide() {
            this.visible = false;
        },
        action() {
            this.error = "";

            if(this.port < 1024 || this.port > 65535) {
                this.portInvalid = true;
                return;
            }
            this.$store.getters["proxies"].forEach((item) => {
                if(item.port == this.port && (item.domain != this.domain || item.directory != this.folder)) this.error = this.$_("This port is used by another Web app.");
            })
            if(this.error) return;

            this.loading = true;
            this.$store.dispatch("proxyModify", { 
                domain: this.domain,
                directory: this.folder,
                port: this.port
            }).then(() => {
                this.loading = false;
                this.visible = false;
                this.$refs["toast"].add({
                    severity: 'success', 
                    summary: this.$_("Operation performed successfully."), 
                    detail: this.$_("Web app was removed") + ": http://" + this.domain + this.folder, 
                    life: 3000
                });
            }).catch((error) => {
                switch(error.code) {
                    default:
                        this.error = this.$_("Unknown error.");
                }
                this.loading = false;
            });
        }
      },
      watch: {
        folder() { this.folderInvalid = false; },
        login() { this.loginInvalid = false; },
        password() { this.passwordInvalid = false; },
        password2() { this.password2Invalid = false; },
      }
    }
</script>

<style>
.install-step {
    min-height: 250px;
}
</style>