<template>
<div class="min-h-screen flex relative lg:static surface-ground">
    <div id="appSidebar" class="bg-bluegray-800 hidden xl:block flex-shrink-0 fixed left-0 top-0 bottom-0 bottom-0 z-1 select-none" style="width:280px">
        <div class="flex flex-column h-full">
            <div class="flex align-items-center px-5 bg-bluegray-900 flex-shrink-0 text-bluegray-100" style="height:60px">
                {{ $_("Hosting panel v1.0.0") }}
            </div>
            <div class="overflow-y-auto mt-3">
                <ul class="list-none px-3 m-0">
                    <MenuItem :text="$_('Domains')" icon="pi pi-globe" link="domains" :active="active=='domains'" :badge="$store.getters['domains'].length"></MenuItem>
                    <MenuItem :text="$_('E-mail')" icon="pi pi-envelope" link="emails" :active="active=='emails'" :badge="$store.getters['emailAccounts'].length + $store.getters['emailAliases'].length"></MenuItem>
                    <MenuItem :text="$_('Databases')" icon="pi pi-database" link="databases" :active="active=='databases'" :badge="$store.getters['databases'].length"></MenuItem>
                    <MenuItem :text="$_('Redirections')" icon="pi pi-arrow-right-arrow-left" link="redirects" :active="active=='redirects'" :badge="$store.getters['redirects'].length"></MenuItem>
                    <MenuItem :text="$_('SSL certificates')" icon="pi pi-lock" link="ssl" :active="active=='ssl'" :badge="$store.getters['certificateCount']"></MenuItem>
                    <MenuItem :text="$_('Script installer')" icon="pi pi-bolt" link="scripts" :active="active=='scripts'" :badge="$store.getters['scripts'].length"></MenuItem>
                    <MenuItem :text="$_('Statistics and logs')" icon="pi pi-chart-bar" link="logs" :active="active=='logs'"></MenuItem>
                    <MenuItem :text="$_('FTP and SSH')" icon="pi pi-user" link="access" :active="active=='access'"></MenuItem>
                    <MenuItem :text="$_('File browser')" icon="pi pi-folder-open" link="files" :active="active=='files'"></MenuItem>
                    <MenuItem :text="$_('Advanced settings')" icon="pi pi-cog" link="advanced" :active="active=='advanced'"></MenuItem>
                </ul>
            </div>
        </div>
    </div>
    <div class="min-h-screen flex flex-column relative flex-auto" id="mainContent">
        <div class="flex justify-content-between align-items-center px-4 lg:px-5 surface-section shadow-2 relative lg:static border-bottom-1 surface-border" style="height:60px">
            <div class="flex">
            <a v-ripple class="cursor-pointer block xl:hidden text-700 mr-3 mt-1 p-ripple"
                v-styleclass="{ selector: '#appSidebar', enterClass: 'hidden', enterActiveClass: 'fadeinleft', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutleft', hideOnOutsideClick: true }">
                <i class="pi pi-bars text-3xl"></i>
            </a>
            <div class="flex">
                <div class="hidden lg:flex align-items-center">
                    <div style="width: 170px">
                        <MeterGroup :value="diskUsage" class="w-12 plain"/>
                    </div>
                    <div class="ml-2">
                        {{ $_('%1% of %2').replace('%1', totalUsage).replace('%2', showSize(this.$store.getters["diskLimit"])) }}
                    </div>
                    <div class="ml-2 hidden lg:block">
                        <Button
                            :label="$_('Details')"
                            severity="secondary"
                            icon="pi pi-chart-bar"
                            @click="$router.push({ name: 'logs' })"
                        />
                    </div>
                </div>
            </div>
            </div>
            <div class="flex">
                <div class="mr-2 hidden md:block">
                    <SplitButton :label="$_('Open WebMail')" @click="webMail" :model="mailButton" severity="secondary" />
                </div>
                <div class="mr-2 hidden md:block mt-1 lg:mt-0">
                    <SplitButton :label="$_('Add a domain')" @click="$refs['domainAdd'].show()" :model="topButton" severity="success" />
                </div>
                <a v-ripple class="cursor-pointer flex align-items-center mr-2 text-700 p-ripple lg:py-1 p-2 text-600 hover:text-900 surface-100 hover:surface-200 border-round"
                    v-styleclass="{ selector: '#localeMenu', enterClass: 'hidden', enterActiveClass: 'fadein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }">
                    <img :src="images[$store.getters['locale']]" width="24" height="16" class="locale"/>
                </a>
                <a v-ripple class="cursor-pointer block text-700 p-ripple lg:py-1 p-2 text-600 hover:text-900 surface-100 hover:surface-200 border-round"
                    v-styleclass="{ selector: '#userMenu', enterClass: 'hidden', enterActiveClass: 'fadein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }">
                    <i class="pi pi-user text-xl mt-1 mx-1"></i>
                </a>
                <ul id="localeMenu" class="list-none p-0 m-0 hidden select-none 
                    surface-section border-1 surface-border z-1 shadow-2 absolute" ref="localeMenu">
                    <li>
                        <a v-ripple class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 border-round cursor-pointer
                            transition-duration-150 transition-colors p-ripple" @click="$store.dispatch('locale', 'en'); $refs['localeMenu'].classList.add('hidden')">
                            <img :src="images['en']" width="24" height="16" class="locale"/>
                            <span class="block ml-2">English</span>
                        </a>
                    </li>
                    <li>
                        <a v-ripple class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 border-round cursor-pointer
                            transition-duration-150 transition-colors p-ripple" @click="$store.dispatch('locale', 'pl'); $refs['localeMenu'].classList.add('hidden')">
                            <img :src="images['pl']" width="24" height="16" class="locale"/>
                            <span class="block ml-2">Polski</span>
                        </a>
                    </li>
                </ul>
                <ul id="userMenu" class="list-none p-0 m-0 hidden select-none 
                    surface-section border-1 surface-border z-1 shadow-2 absolute">
                    <li>
                        <a v-ripple class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 border-round cursor-pointer
                            transition-duration-150 transition-colors p-ripple" @click="$refs['accountPassword'].show()">
                            <i class="pi pi-unlock text-base mr-2 text-gray-400"></i>
                            <span class="block">{{ $_('Change password') }}</span>
                        </a>
                    </li>
                    <li>
                        <a v-ripple class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 border-round cursor-pointer
                            transition-duration-150 transition-colors p-ripple" @click="logout">
                            <i class="pi pi-sign-out text-base mr-2 text-gray-400"></i>
                            <span class="block">{{ $_('Log out') }}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="p-3 md:p-4 lg:p-5 flex flex-column flex-auto">
            <div class="border-1surface-border border-round surface-section flex-auto">
                <slot></slot>
            </div>
        </div>
    </div>
    <DomainAddDialog ref="domainAdd"/>
    <AccountCreateDialog ref="accountCreate"/>
    <AliasCreateDialog ref="aliasCreate"/>
    <DatabaseCreateDialog ref="databaseCreate"/>
    <RedirectCreateDialog ref="redirectCreate"/>
    <ScriptInstallDialog ref="scriptInstall"/>
    <CertificateUploadDialog ref="certificateUpload"/>
    <AccountPasswordDialog ref="accountPassword"/>
    <BlacklistAddDialog ref="blacklistAdd"/>
    <WhitelistAddDialog ref="whitelistAdd"/>
    <Toast/>
</div>

</template>

<script>

import MenuItem from "./MenuItem.vue"

import DomainAddDialog from "../dialogs/domain/DomainAddDialog.vue";
import AccountCreateDialog from "../dialogs/email/AccountCreateDialog.vue";
import AliasCreateDialog from "../dialogs/email/AliasCreateDialog.vue";
import DatabaseCreateDialog from "../dialogs/database/DatabaseCreateDialog.vue";
import RedirectCreateDialog from "../dialogs/redirect/RedirectCreateDialog.vue";
import ScriptInstallDialog from "../dialogs/script/ScriptInstallDialog.vue";
import CertificateUploadDialog from "../dialogs/certificate/CertificateUploadDialog.vue";
import AccountPasswordDialog from "../dialogs/account/AccountPasswordDialog.vue";
import BlacklistAddDialog from "../dialogs/email/BlacklistAddDialog.vue";
import WhitelistAddDialog from "../dialogs/email/WhitelistAddDialog.vue";

import { size } from "../mixins/size.js";

const imgEn = require("../assets/en.png")
const imgPl = require("../assets/pl.png")

export default {
  name: "ViewRoot",
  components: {
    MenuItem,
    CertificateUploadDialog,
    DomainAddDialog,
    AccountCreateDialog,
    AliasCreateDialog,
    DatabaseCreateDialog,
    RedirectCreateDialog,
    ScriptInstallDialog,
    AccountPasswordDialog,
    BlacklistAddDialog,
    WhitelistAddDialog,
  },
  mixins: [
    size
  ],
  props: {
    active: String,
  },
  data() {
    return {
        images: {
            en: imgEn,
            pl: imgPl
        }
    }
  },
  computed: {
    diskUsage() {
        const limit = this.$store.getters["diskLimit"];
        if(limit > 0) {
            /*
            const emailReserve = this.emailReserve;
            return [
                { label: this.$_("Disk usage"), value: 100*used/limit, color: '#3B82F6' },
                { label: this.$_("Mail capacity"), value: 100*emailReserve/limit, color: '#c3c7ce' },
            ];
            */
            return [
                { label: this.$_("Websites"), value: 100*this.$store.getters["sizeDomains"]/limit, color: '#60a5fa' },
                { label: this.$_("Databases"), value: 100*this.$store.getters["sizeDatabases"]/limit, color: '#34d399' },
                { label: this.$_("Emails"), value: 100*this.$store.getters["sizeEmails"]/limit, color: '#fbbf24' },
                { label: this.$_("Other"), value: 100*this.$store.getters["sizeRest"]/limit, color: '#c084fc' },
                { label: this.$_("Reserved"), value: 100*this.$store.getters["sizeReserved"]/limit, color: '#c3c7ce' },
            ];
        } else {
            return [{ label: this.$_("Disk usage"), value: 0, color: '#3B82F6' }];
        }
    },
    emailReserve() {
        return this.$store.getters["emailAccounts"].reduce((acc, item) => acc+item.diskLimit-item.diskUsed, 0);
    },
    spaceLimit() {
        return parseInt(this.$store.getters["diskLimit"]/1024/1024);
    },
    totalUsage() {
        const used = this.$store.getters["diskUsage"];
        const limit = this.$store.getters["diskLimit"];
        if(limit > 0) {
            return (100*(used)/limit).toFixed(1);
        } else {
            return 0;
        }
    },
    topButton() {
        return [
            { label: this.$_("Create e-mail account"), command: () => this.$refs['accountCreate'].show(), icon: "pi pi-envelope" },
            { label: this.$_("Create e-mail alias"), command: () => this.$refs['aliasCreate'].show(), icon: "pi pi-envelope" },
            { label: this.$_("Create database"), command: () => this.$refs['databaseCreate'].show('mysql'), icon: "pi pi-database" },
            { label: this.$_("Create redirection"), command: () => this.$refs['redirectCreate'].show(), icon: "pi pi-arrow-right-arrow-left" },
            { label: this.$_("Upload SSL certificate"), command: () => this.$refs['certificateUpload'].show(), icon: "pi pi-lock" },
            { label: this.$_("Install WordPress"), command: () => this.$refs['scriptInstall'].show('wordpress', '', 0), icon: "pi pi-bolt" },
            { label: this.$_("Add trusted e-mail sender"), command: () => this.$refs['whitelistAdd'].show(), icon: "pi pi-check-circle" },
            { label: this.$_("Add blocked e-mail sender"), command: () => this.$refs['blacklistAdd'].show(), icon: "pi pi-times-circle" },
        ]
    },
    mailButton() {
        return [
            { label: this.$_("Open phpMyAdmin"), command: () => this.phpMyAdmin() },
            { label: this.$_("Open phpPgAdmin"), command: () => this.phpPgAdmin() },
        ]
    },
  },
  mounted() {
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({ name: "login" });
    },
    webMail() {
      window.open(this.$store.getters["webmailUrl"]);
    },
    phpMyAdmin() {
      window.open(this.$store.getters["phpmyadminUrl"]);
    },
    phpPgAdmin() {
      window.open(this.$store.getters["phppgadminUrl"]);
    },
  },
}

</script>

<style>
#userMenu {
    top: 55px;
    right: 10px;
    min-width: 150px;
}
#localeMenu {
    top: 55px;
    right: 65px;
    min-width: 150px;
}
IMG.locale {
    border: 1px solid var(--gray-300)
}
@media screen and (min-width:1200px) {
    #mainContent { margin-left: 280px; }
}
</style>