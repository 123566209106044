import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import bubble from './plugins/bubble.js'
import translate from './plugins/translate.js'
import PrimeVue from 'primevue/config'; 

import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Badge from 'primevue/badge';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Checkbox from 'primevue/checkbox';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import ConfirmDialog from 'primevue/confirmdialog';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import FileUpload from 'primevue/fileupload';
import IconField from 'primevue/iconfield';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import InputIcon from 'primevue/inputicon';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import ListBox from 'primevue/listbox';
import Message from 'primevue/message';
import MeterGroup from './components/MeterGroup.vue';
import Panel from 'primevue/panel';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import RadioButton from 'primevue/radiobutton';
import Row from 'primevue/row';
import Slider from 'primevue/slider';
import Steps from 'primevue/steps';
import SplitButton from 'primevue/splitbutton';
import TabMenu from 'primevue/tabmenu';
import Toast from 'primevue/toast';
import Textarea from 'primevue/textarea';

//import Badge from 'primevue/badge';
import Ripple from 'primevue/ripple';
import Styleclass from 'primevue/styleclass';

createApp(App)
    .use(store)
    .use(router)
    .use(bubble)
    .use(translate)
    .use(PrimeVue, { ripple: true })
    .use(ToastService)
    .use(ConfirmationService)
    .component("Accordion", Accordion)
    .component("AccordionTab", AccordionTab)
    .component("Badge", Badge)
    .component("Button", Button)
    .component("Checkbox", Checkbox)
    .component("Card", Card)
    .component("Column", Column)
    .component("ColumnGroup", ColumnGroup)
    .component("ConfirmDialog", ConfirmDialog)
    .component("ContextMenu", ContextMenu)
    .component("DataTable", DataTable)
    .component("Dialog", Dialog)
    .component("Dropdown", Dropdown)
    .component("FileUpload", FileUpload)
    .component("IconField", IconField)
    .component("InputGroup", InputGroup)
    .component("InputGroupAddon", InputGroupAddon)
    .component("InputIcon", InputIcon)
    .component("InputNumber", InputNumber)
    .component("InputText", InputText)
    .component("ListBox", ListBox)
    .component("Message", Message)
    .component("MeterGroup", MeterGroup)
    .component("Panel", Panel)
    .component("ProgressBar", ProgressBar)
    .component("ProgressSpinner", ProgressSpinner)
    .component("RadioButton", RadioButton)
    .component("Row", Row)
    .component("Slider", Slider)
    .component("Steps", Steps)
    .component("TabMenu", TabMenu)
    .component("Textarea", Textarea)
    .component("Toast", Toast)
    .component("SplitButton", SplitButton)
    .directive("badge", Badge)
    .directive("ripple", Ripple)
    .directive("styleclass", Styleclass)
    .mount('#app');
