<template>
    <div>
    <Dialog 
        v-model:visible="visible" 
        modal
        :draggable="false"
        :header="$_('E-mail configuration')"
        class="w-full lg:w-4 mx-2"
        >
        <table cellpadding="0" cellspacing="0">
            <tr>
                <td colspan="3" class="py-1">
                    <label class="block text-left font-semibold text-gray-700">{{ $_('IMAP server') }}:</label>
                </td>
            </tr>
            <tr>
                <td class="py-1">
                    {{  $store.getters["imapHost"] }}
                </td>
                <td>
                    <CopyIcon :text="$store.getters['imapHost']" :message="$_('IMAP server name copied to clipboard.')"/>
                </td>
                <td class="pl-3">
                    {{  $_('Port') }}:
                    {{  $store.getters["imapPort"] }}
                    <span v-if="$store.getters['imapPort'] != 993">(STARTTLS)</span>
                    <span v-if="$store.getters['imapPort'] == 993">(SSL/TLS)</span>
                </td>
            </tr>
            <tr>
                <td colspan="3" class="pb-1 pt-3">
                    <label class="block text-left font-semibold text-gray-700">{{ $_('SMTP server') }}:</label>
                </td>
            </tr>
            <tr>
                <td class="py-1">
                    {{  $store.getters["smtpHost"] }}
                </td>
                <td>
                    <CopyIcon :text="$store.getters['smtpHost']" :message="$_('SMTP server name copied to clipboard.')"/>
                </td>
                <td class="pl-3">
                    {{  $_('Port') }}:
                    {{  $store.getters["smtpPort"] }}
                    <span v-if="$store.getters['smtpPort'] == 465">(SSL/TLS)</span>
                    <span v-if="$store.getters['smtpPort'] == 587">(STARTTLS)</span>
                </td>
            </tr>
        </table>
        <div class="flex justify-content-center gap-2 mt-4">
            <Button type="button" :label="$_('Close window')" severity="secondary" @click="hide"></Button>
        </div>
    </Dialog>
    </div>
</template>
    
<script>
import CopyIcon from "../../components/CopyIcon.vue";

export default {
    name: "InfoEmailsDialog",
    components: {
        CopyIcon,
    },
    data() {
        return {
            visible: false,
        }
    },
    methods: {
        show() {
            this.visible = true;
        },
        hide() {
            this.visible = false;
        },
    },
}
</script>