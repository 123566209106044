<template>
  <router-view/>
</template>

<style lang="scss">
@import "~primevue/resources/primevue.min.css";
@import "~primevue/resources/themes/aura-light-blue/theme.css";
@import "primeicons/primeicons.css";
@import "primeflex/primeflex.css";

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

BODY
{
  background-color: rgb(249, 250, 251);
  margin: 0;
  padding: 0;
  font-size: 16px;
}

*::-webkit-scrollbar-track
{
	background-color: transparent;
}

*::-webkit-scrollbar
{
	width: 8px;
	height: 8px;
	background-color: transparent;
}

*::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: var(--gray-400);
}

TH {
  background: none !important;
}

.p-inputtext, .p-dropdown, .p-message, .p-checkbox-box {
  box-shadow: none !important;
}

.p-message-wrapper {
  padding: 0.3rem 0.75rem !important;
}

.p-message-text {
  padding: 0.2rem 0;
}

.p-metergroup.plain .p-metergroup-labels {
  display: none !important;
}

LABEL {
  cursor: pointer;
}

.steps-5 .p-steps-item {
  width: 20%;
}

.p-accordion-header-link {
  padding: 1rem 0  !important;
}

.p-accordion-content {
  padding: 0 !important;
}

TEXTAREA::placeholder, INPUT::placeholder, .p-placeholder {
  color: var(--gray-300)
}

.p-inputgroup BUTTON:last-of-type {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}

.table-buttons {
  margin: -0.75rem 0;
  text-align: right;
}

STRONG {
  font-weight: 600;
}

.ellipsis {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis-400 {
  width: auto;
  max-width: 400px;
}
.ellipsis-300 {
  width: auto;
  max-width: 300px;
}
.ellipsis-250 {
  width: auto;
  max-width: 250px;
}
@media screen and (max-width:550px){
  .ellipsis-250, .ellipsis-300, .ellipsis-400 { max-width: calc(100vw - 150px) }
}

@media screen and (min-width:576px){
  .sm\:cell{display:table-cell!important}
}
@media screen and (min-width:768px){
  .md\:cell{display:table-cell!important}
}
@media screen and (min-width:992px){
  .lg\:cell{display:table-cell!important}
}
@media screen and (min-width:1200px){
  .xl\:cell{display:table-cell!important}
}

TEXTAREA {
  resize: none;
}

</style>
