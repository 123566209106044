export const upgrade = {

    methods: {
        upgradePackage() {
            window.open(this.$store.getters["upsell"].upgrade.replace("%1", this.$store.getters["login"]), "_blank");
        },
        payPackage() {
            window.open(this.$store.getters["upsell"].pay.replace("%1", this.$store.getters["login"]), "_blank");
        },
    }
}
