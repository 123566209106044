<template>
    <div>
    <Dialog 
        v-model:visible="visible" 
        modal
        :draggable="false"
        :header="type == 'error' ? $_('Domain error log') : $_('Domain access log')"
        class="w-full lg:w-10 mx-2"
        >
        <div style="position:relative">
            <label class="block text-left font-semibold text-gray-700 mb-1">{{ $_('Last 100 log lines') }}:</label>
            <Textarea
                v-model="log"
                :autoresize="false"
                readonly
                class="w-full log"
                ref="log"
            />
            <div class="log-loader" v-if="loading">
                <ProgressSpinner/>
            </div>
            <div class="log-loader" v-if="error">
                <div style="font-size: 1.2rem; color: var(--red-500)">
                    <i class="pi pi-exclamation-circle"></i>
                    {{ $_('Cannot load server log.') }}
                </div>
            </div>
        </div>
        <div class="flex justify-content-between gap-2 mt-4">
            <Button type="button" :label="$_('Close window')" severity="secondary" @click="hide"></Button>
            <Button type="button" :label="$_('Refresh log')" icon="pi pi-sync" severity="secondary" @click="getLog"></Button>
            <Button type="button" :label="$_('Download')" icon="pi pi-download" @click="$refs['form'].submit()"></Button>
        </div>
    </Dialog>
    <form :action="formAction" method="post" ref="form" target="_blank">
        <input type="hidden" name="session" :value="$store.getters['session']"/>
        <input type="hidden" name="domain" :value="domain"/>
        <input type="hidden" name="type" :value="type"/>
    </form>
    </div>
</template>
    
<script>

const requester = require("../../libraries/api.js");

export default {
    name: "LogShowDialog",
    data() {
        return {
            type: "access",
            visible: false,
            loading: false,
            error: false,
            domain: "",
            log: "",
        }
    },
    computed: {
        formAction() {
            return window.Config.apiHost + "/log/download";
        },
    },
    methods: {
        show(domain, type) {
            this.domain = domain;
            this.type = type;
            this.visible = true;
            this.loading = false;
            this.error = false;
            this.log = "";
            this.getLog();
        },
        hide() {
            this.visible = false;
        },
        getLog() {
            this.loading = true;
            this.error = false;
            requester.post("/log/show", {
                domain: this.domain,
                type: this.type
            }).then((data) => {
                this.loading = false;
                this.log = data.log;
                this.$nextTick(() => {
                    this.$refs["log"].$el.scrollTop = this.$refs["log"].$el.scrollHeight;
                });
            }).catch(() => {
                this.loading = false;
                this.error = true;
            });
        },
    },
}
</script>

<style>
TEXTAREA.log {
    height: calc(100vh - 250px);
    max-height: 800px;
    font-size: 13px;
}

.log-loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
}

</style>