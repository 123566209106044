<template>
    <div>
    <Toast ref="toast"/>
    <Dialog 
        :visible="visible" 
        modal
        :draggable="false"
        :header="$_('Edit file')"
        class="w-full lg:w-10 mx-2"
        @update:visible="hide"
        >
        <div style="position:relative" class="editor-div">
            <PrismEditor
                v-model="file"
                class="w-full editor-control"
                :highlight="highlighter"
                line-numbers
            />
            <div class="file-loader" v-if="loading">
                <ProgressSpinner/>
            </div>
            <div class="file-loader" v-if="error">
                <div style="font-size: 1.2rem; color: var(--red-500)">
                    <i class="pi pi-exclamation-circle"></i>
                    {{ $_('Cannot load file.') }}
                </div>
            </div>
        </div>
        <div class="flex justify-content-between gap-2 mt-4">
            <Button type="button" :label="$_('Close window')" severity="secondary" @click="hide"></Button>
            <Button type="button" :label="$_('Save file')" icon="pi pi-check" @click="putFile(false)" :disabled="!dirty" :loading="saving"></Button>
        </div>
    </Dialog>
    <Dialog 
        v-model:visible="confirming" 
        modal
        :draggable="false"
        :header="$_('Save file?')"
        class="w-full md:w-4 mx-2"
        >
        <div class="flex align-items-center">
            <div>
                <i class="pi pi-question-circle text-gray-500" style="font-size: 2rem"></i>
            </div>
            <div class="ml-2 pb-1">
                {{ $_('Changes you have made to the file are not saved. Do you want to save your changes?') }}
            </div>
        </div>
        <div class="flex justify-content-between gap-2 mt-4">
            <Button type="button" :label="$_('Don\'t save')" severity="secondary" @click="visible = false; confirming = false;"></Button>
            <Button type="button" :label="$_('Save file')" icon="pi pi-check" @click="putFile(true)" :loading="saving"></Button>
        </div>
    </Dialog>
    </div>
</template>
    
<script>

const requester = require("../../libraries/api.js");

import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; 

import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-markup-templating';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-ini';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-php';
import 'prismjs/themes/prism-coy.css'; // import syntax highlighting styles

export default {
    name: "FileEditDialog",
    components: {
        PrismEditor
    },
    data() {
        return {
            name: "name",
            visible: false,
            loading: false,
            saving: false,
            error: false,
            file: "",
            dirty: false,
            confirming: false,
        }
    },
    methods: {
        show(name) {
            this.name = name;
            this.visible = true;
            this.loading = false;
            this.saving = false;
            this.error = false;
            this.file = "";
            this.confirming = false;
            this.$nextTick(() => {
                this.dirty = false;
            });
            this.getFile();
        },
        hide() {
            if(this.dirty) {
                this.confirming = true;
            } else {
                this.visible = false;
            }
        },
        getFile() {
            this.loading = true;
            this.error = false;
            requester.post("/file/file/get", {
                file: this.name,
            }).then((data) => {
                this.loading = false;
                this.file = data.file;
                this.$nextTick(() => {
                    this.dirty = false;
                });
            }).catch(() => {
                this.loading = false;
                this.error = true;
            });
        },
        putFile(close = false) {
            this.saving = true;
            requester.post("/file/file/put", {
                file: this.name,
                contents: this.file,
            }).then(() => {
                this.saving = false;
                this.dirty = false;
                this.$refs["toast"].add({
                    severity: 'success', 
                    summary: this.$_("Operation performed successfully."), 
                    detail: this.$_("File contents have been updated."), 
                    life: 3000
                });
                if(close) {
                    this.visible = false;
                    this.confirming = false;
                }
            }).catch(() => {
                this.saving = false;
            });
        },
        highlighter(code) {
            const ext = this.name.split(".").pop();
            //console.log(languages);
            switch(ext) {
                case "css":
                    return highlight(code, languages.css);
                case "htm": case "html":
                    return highlight(code, languages.markup);
                case "ini":
                    return highlight(code, languages.ini);
                case "js":
                    return highlight(code, languages.js);
                case "php":
                    return highlight(code, languages.php);
                default:
                    return highlight(code, languages.text);
            }
        },
    },
    watch: {
        file() {
            this.dirty = true;
        }
    }
}
</script>

<style>
.editor-div {
    height: calc(95vh - 220px);
    max-height: 800px;
    overflow-x: auto;
    overflow-y: auto;
    border: 1px solid var(--gray-200);
    border-radius: 5px;
    padding-left: 10px;
}

.file-loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
}

.editor-control {
    font-family: Roboto Mono, monospace;
    font-size: 14px;
    line-height: 1.25;
}

.editor-control * {
    white-space: pre !important;
}

.prism-editor__container {
    overflow-x: visible !important;
}

.prism-editor__textarea:focus {
    outline: none;
}

</style>