<template>
    <div>
    <Toast ref="toast"/>
    <Dialog 
        v-model:visible="visible" 
        modal
        :draggable="false"
        :header="$_('Install a new script')"
        class="w-full lg:w-5 mx-2"
        >
        <Steps :model="steps" v-model:activeStep="step" class="mb-3 steps-5"/>
        <div v-if="step == 0" class="install-step">
            <label class="block text-left font-semibold text-gray-700 mb-2">{{ $_('What script do you want to install?') }}</label>
            <ListBox
                v-model="type"
                :options="$store.getters['availableScripts']"
                optionLabel="name"
                optionValue="type"
                filter
                listStyle="height:175px"
                >
                <template #option="slot">
                    <div class="flex align-items-center">
                        <img :src="'assets/scripts/' + slot.option.type + '.svg'" width="20" height="20"/>
                        <div class="ml-1">{{  slot.option.name }}</div>
                    </div>
                </template>
            </ListBox>
        </div>
        <div v-if="step == 1" class="install-step">
            <label class="block text-left font-semibold text-gray-700 mb-1">{{ $_('Domain name') }}:</label>
            <Dropdown v-model="domain" :options="domains" :placeholder="$_('Select a domain')" class="w-full"/>
            <label class="block text-left font-semibold text-gray-700 mb-2 mt-3">{{ $_('Script location') }}:</label>
            <div class="flex align-items-center">
                <RadioButton v-model="root" inputId="locationRoot" name="root" :value="true"/>
                <label for="locationRoot" class="ml-2">{{ $_('Install in the main directory') }}</label>
            </div>
            <div class="flex align-items-center mt-2">
                <RadioButton v-model="root" inputId="locationFolder" name="root" :value="false"/>
                <label for="locationFolder" class="ml-2">{{ $_('Install in a subdirectory') }}</label>
            </div>
            <div v-if="!root">
                <label for="folderField" class="block text-left font-semibold text-gray-700 mb-1 mt-3">{{ $_('Subdirectory name') }}:</label>
                <InputText
                    id="folderField"
                    type="text"
                    v-model="folder"
                    :invalid="folderInvalid"
                    class="w-full"
                    :placeholder="$_('/myscript')"
                    ref="input"
                />
                <div v-if="folderInvalid" class="text-left text-red-500">
                    <small>{{  $_('You need to enter a valid subdirectory name.') }}</small>
                </div>
            </div>
        </div>
        <div v-if="step == 2" class="install-step">
            <label class="block text-left font-semibold text-gray-700 mb-2">{{ $_('Installation database') }}:</label>
            <div class="flex align-items-center">
                <RadioButton v-model="newDatabase" inputId="databaseNew" name="newDatabase" :value="true"/>
                <label for="databaseNew" class="ml-2">{{ $_('Create a new database') }}</label>
            </div>
            <div class="flex align-items-center mt-2">
                <RadioButton v-model="newDatabase" inputId="databaseOld" name="newDatabase" :value="false" :disabled="!databases.length"/>
                <label for="databaseOld" class="ml-2" :class="{ 'text-gray-300': !databases.length }">{{ $_('Use an existing database') }}</label>
            </div>
            <div v-if="!newDatabase">
                <label class="block text-left font-semibold text-gray-700 mb-1 mt-3">{{ $_('Database name') }}:</label>
                <Dropdown v-model="database" :options="databases" :placeholder="$_('Select a database')" class="w-full"/>
            </div>
        </div>
        <div v-if="step == 3" class="install-step">
            <label for="loginField" class="block text-left font-medium mb-1">{{ $_('Administrator login') }}:</label>
            <InputText
                id="loginField"
                type="text"
                v-model="login"
                :invalid="loginInvalid"
                class="w-full"
                ref="login"
            />
            <div v-if="loginInvalid" class="text-left text-red-500">
                <small>{{  $_('You need to enter a valid login.') }}</small>
            </div>
            <label for="passwordField" class="block text-left font-medium mb-1 mt-3">{{ $_('Administrator password') }}:</label>
            <InputGroup>
                <InputText
                    id="passwordField"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="password"
                    :invalid="passwordInvalid"
                    class="w-full"
                    ref="password"
                />
                <Button severity="secondary" icon="pi pi-eye" @click="showPassword=true" v-if="!showPassword"/>
                <Button severity="secondary" icon="pi pi-eye-slash" @click="showPassword=false" v-if="showPassword"/>
            </InputGroup>
            <div v-if="passwordInvalid" class="text-left text-red-500">
                <small>{{  $_('Password must be at least 8 characters long.') }}</small>
            </div>
            <label for="password2Field" class="block text-left font-medium mb-1 mt-3">{{ $_('Repeat the password') }}:</label>
            <InputGroup>
                <InputText
                    id="password2Field"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="password2"
                    :invalid="password2Invalid"
                    class="w-full"
                />
                <Button severity="secondary" icon="pi pi-eye" @click="showPassword=true" v-if="!showPassword"/>
                <Button severity="secondary" icon="pi pi-eye-slash" @click="showPassword=false" v-if="showPassword"/>
            </InputGroup>
            <div v-if="password2Invalid" class="text-left text-red-500">
                <small>{{  $_('The passwords do not match.') }}</small>
            </div>
            <div class="mt-1">
                <Button severity="secondary" icon="pi pi-pencil" :label="$_('Generate password')" size="small" @click="generatePassword"/>
            </div>
        </div>
        <div v-if="step == 4" class="install-step">
            <div v-if="error">
                <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
            </div>
            <div v-if="errorLocation">
                <Message severity="warn" class="mt-0" :closable="false">
                    {{ errorLocation }}
                </Message>
            </div>
            <div v-else-if="errorRedirection">
                <Message severity="warn" class="mt-0" :closable="false">
                    {{ errorRedirection }}
                </Message>
            </div>
            <div v-if="errorDatabase">
                <Message severity="warn" class="mt-0" :closable="false">
                    {{ errorDatabase }}
                </Message>
            </div>
            <label class="block text-left font-semibold text-gray-700 mb-1">{{ $_('Script to be installed') }}:</label>
            <div class="flex align-items-center">
                <img :src="'assets/scripts/' + type + '.svg'" width="20" height="20"/>
                <div class="ml-1">{{  scriptName }}</div>
            </div>
            <label class="block text-left font-semibold text-gray-700 mb-1 mt-3">{{ $_('Website address') }}:</label>
            <div>
                <a :href="formattedUrl" target="_blank" class="text-primary">{{ formattedUrl }}</a>
            </div>
            <label class="block text-left font-semibold text-gray-700 mb-1 mt-3">{{ $_('Database name') }}:</label>
            <div>
                {{ formattedDatabase }}
            </div>
            <label class="block text-left font-semibold text-gray-700 mb-2 mt-3">{{ $_('Administrator password') }}:</label>
            <div>
                {{ password }}
                <CopyIcon :text="password" :message="$_('Password copied to clipboard.')"/>
             </div>
        </div>
        <div class="flex justify-content-between gap-2 mt-4" v-if="step == 0">
            <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"/>
            <Button type="button" :label="$_('Next step')" @click="step++" :disabled="!type" icon="pi pi-arrow-right" iconPos="right"/>
        </div>
        <div class="flex justify-content-between gap-2 mt-4" v-if="step == 1">
            <Button type="button" :label="$_('Go back')" severity="secondary" icon="pi pi-arrow-left" @click="folderInvalid=false; step--;"/>
            <Button type="button" :label="$_('Next step')" @click="step2" :disabled="!domain" icon="pi pi-arrow-right" iconPos="right"/>
        </div>
        <div class="flex justify-content-between gap-2 mt-4" v-if="step == 2">
            <Button type="button" :label="$_('Go back')" severity="secondary" icon="pi pi-arrow-left" @click="step--"/>
            <Button type="button" :label="$_('Next step')" @click="step3" :disabled="!newDatabase && !database" icon="pi pi-arrow-right" iconPos="right"/>
        </div>
        <div class="flex justify-content-between gap-2 mt-4" v-if="step == 3">
            <Button type="button" :label="$_('Go back')" severity="secondary" icon="pi pi-arrow-left" @click="step--"/>
            <Button type="button" :label="$_('Next step')" @click="step4" icon="pi pi-arrow-right" iconPos="right"/>
        </div>
        <div class="flex justify-content-between gap-2 mt-4" v-if="step == 4">
            <Button type="button" :label="$_('Go back')" severity="secondary" icon="pi pi-arrow-left" @click="error=''; step--;"/>
            <Button type="button" :label="$_('Install script')" @click="action" :loading="loading"/>
        </div>
    </Dialog>
    </div>
</template>
    
<script>
import CopyIcon from "../../components/CopyIcon.vue";

import { password } from "../../mixins/password.js";

export default {
    name: "ScriptInstallDialog",
    components: {
        CopyIcon,
    },
    mixins: [ password ],
    data() {
        return {
          step: 0,
          visible: false,
          type: "",
          domain: "",
          root: true,
          login: "",
          loginInvalid: false,
          folder: "",
          folderInvalid: false,
          newDatabase: true,
          database: "",
          loading: false,
          error: "",
        }
      },
      computed: {
        steps() {
            return [
                { label: this.$_('Script') },
                { label: this.$_('Domain') },
                { label: this.$_('Database') },
                { label: this.$_('User') },
                { label: this.$_('Review') }
            ]
        },
        domains() {
            return this.$store.getters["domains"].map(item => item.domain);
        },
        databases() {
            return this.$store.getters["mysqlDatabases"].map(item => item.name);
        },
        scriptName() {
            var ret = "";
            this.$store.getters["availableScripts"].forEach((item) => {
                if(item.type == this.type) ret = item.name;
            });
            return ret;
        },
        formattedFolder() {
            if(this.root)
                return "/";
            else
                return "/" + this.folder.trim().replace(/^\//, "").replace(/\/$/, "") + "/";
        },
        formattedUrl() {
            return "http://www." + this.domain + this.formattedFolder;
        },
        formattedDatabase() {
            if(this.newDatabase)
                return this.$_("Create a new database.");
            else
                return this.database;
        },
        errorLocation() {
            if(this.error) return null;
            var ret = null;
            var folder = "/";
            if(!this.root) {
                folder = this.folder.trim();
                if(!folder.match(/^\//)) folder = "/" + folder;
                if(!folder.match(/\/$/)) folder = folder + "/";        
            }
            this.$store.getters['scripts'].forEach((item) => {
                if(item.domain == this.domain && item.directory == folder) {
                    this.$store.getters['availableScripts'].forEach((item2) => {
                        if(item.type == item2.type) {
                            ret = this.$_("%1 is already installed in this location!").replace("%1", item2.name);
                        }
                    });
                }
            });
            return ret;
        },
        errorRedirection() {
            if(this.error) return null;
            var ret = null;
            var folder = "/";
            if(!this.root) {
                folder = this.folder.trim();
                if(!folder.match(/^\//)) folder = "/" + folder;
                if(!folder.match(/\/$/)) folder = folder + "/";        
            }
            this.$store.getters['redirects'].forEach((item) => {
                if(item.type !='http' && (this.domain + folder).startsWith(item.domain + item.directory)) {
                    ret = this.$_("This address is redirected to another website!");
                }
            });
            return ret;
        },
        errorDatabase() {
            if(this.error) return null;
            var ret = null;
            this.$store.getters['scripts'].forEach((item) => {
                if(!this.newDatabase && item.database == this.database) {
                    this.$store.getters['availableScripts'].forEach((item2) => {
                        if(item.type == item2.type) {
                            ret = this.$_("This database is already used by %1!").replace("%1", item2.name);
                        }
                    });
                }
            });
            return ret;
        },
      },
      methods: {
        show(type = "", domain = "", step = 0, database = "") {
            this.step = step;
            this.domain = domain;
            this.type = type;
            this.root = true;
            this.folder = "";
            this.folderInvalid = false;
            this.database = database;
            this.newDatabase = !database;
            this.login = "admin";
            this.loginInvalid = false;
            this.password = "";
            this.passwordInvalid = false;
            this.password2 = "";
            this.password2Invalid = false;
            this.showPassword = false;
            this.visible = true;
            this.error = "";
        },
        hide() {
            this.visible = false;
        },
        step2() {
            this.folderInvalid = false;

            if(!this.root && (!this.folder.trim() || this.folder.match(/[^a-zA-Z0-9_./-]/) || this.folder.match(/\/\//) || this.folder.trim() == "/")) {
                this.folderInvalid = true;
                return;
            }            

            this.step++;
        },
        step3() {
            this.step++;
            this.$nextTick(() => {
                this.$refs["password"].$el.focus();
            });
        },
        step4() {
            this.loginInvalid = false;
            this.passwordInvalid = false;
            this.password2Invalid = false;

            if(!this.login.trim()) {
                this.loginInvalid = true;
                return;
            }            
            if(this.password.trim().length < 8) {
                this.passwordInvalid = true;
                return;
            }            
            if(this.password2.trim() != this.password.trim()) {
                this.password2Invalid = true;
                return;
            }

            this.step++;
        },
        action() {
            this.error = "";

            var folder = "/";
            if(!this.root) {
                folder = this.folder.trim();
                if(!folder.match(/^\//)) folder = "/" + folder;
                if(!folder.match(/\/$/)) folder = folder + "/";        
            }

            this.loading = true;
            this.$store.dispatch("scriptInstall", { 
                type: this.type,
                domain: this.domain,
                directory: folder,
                database: this.newDatabase ? null : this.database,
                login: this.login,
                password: this.password,
            }).then(() => {
                this.loading = false;
                this.visible = false;
                this.$refs["toast"].add({
                    severity: 'success', 
                    summary: this.$_("Operation performed successfully."), 
                    detail: this.$_("Script was installed") + ": http://" + this.domain + folder, 
                    life: 3000
                });
            }).catch((error) => {
                switch(error.code) {
                    case 412:
                        switch(error.message) {
                            case "Directory not empty":
                                this.error = this.$_("The selected directory is not empty.");
                                break;
                            case "Database not empty":
                                this.error = this.$_("The selected database is not empty.");
                                break;
                            default:
                                this.error = this.$_("Cannot install the script.");
                                break;
                        }
                        break;
                    case 500:
                        this.error = this.$_("Cannot install the script.");
                        break;
                    default:
                        this.error = this.$_("Unknown error.");
                }
                this.loading = false;
            });
        }
      },
      watch: {
        folder() { this.folderInvalid = false; },
        login() { this.loginInvalid = false; },
        password() { this.passwordInvalid = false; },
        password2() { this.password2Invalid = false; },
      }
    }
</script>

<style>
.install-step {
    min-height: 250px;
}
</style>