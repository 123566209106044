<template>
    <div>
    <Toast ref="toast"/>
    <Dialog 
        v-model:visible="visible" 
        modal
        :draggable="false"
        :header="$_('Change permissions')"
        class="w-full lg:w-4 mx-2"
        >
        <div v-if="error">
            <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
        </div>
        <div>
            <label class="block text-left font-semibold text-gray-700 mb-2">{{ file.type == 'directory' ? $_('Directory name') : $_('File name') }}:</label>
            <div class="ellipsis">
                {{ file.name }}
            </div>
        </div>
        <div class="flex mt-3">
            <div class="w-4">
                <label class="block text-left font-semibold text-gray-700 mb-2">{{ $_('Owner') }}:</label>
                <div class="flex align-items-center">
                    <Checkbox v-model="permissions[0]" inputId="permissions0" name="permissions0" value="true"></Checkbox>
                    <label for="permissions0" class="ml-2">{{ $_('Read') }}</label>
                </div>
                <div class="flex align-items-center mt-2">
                    <Checkbox v-model="permissions[1]" inputId="permissions1" name="permissions1" value="true"></Checkbox>
                    <label for="permissions1" class="ml-2">{{ $_('Write') }}</label>
                </div>
                <div class="flex align-items-center mt-2">
                    <Checkbox v-model="permissions[2]" inputId="permissions2" name="permissions2" value="true"></Checkbox>
                    <label for="permissions2" class="ml-2">{{ file.type == 'directory' ? $_('Browse') : $_('Execute') }}</label>
                </div>
            </div>
            <div class="w-4">
                <label class="block text-left font-semibold text-gray-700 mb-2">{{ $_('Group') }}:</label>
                <div class="flex align-items-center">
                    <Checkbox v-model="permissions[3]" inputId="permissions3" name="permissions3" value="true"></Checkbox>
                    <label for="permissions3" class="ml-2">{{ $_('Read') }}</label>
                </div>
                <div class="flex align-items-center mt-2">
                    <Checkbox v-model="permissions[4]" inputId="permissions4" name="permissions4" value="true"></Checkbox>
                    <label for="permissions4" class="ml-2">{{ $_('Write') }}</label>
                </div>
                <div class="flex align-items-center mt-2">
                    <Checkbox v-model="permissions[5]" inputId="permissions5" name="permissions5" value="true"></Checkbox>
                    <label for="permissions5" class="ml-2">{{ file.type == 'directory' ? $_('Browse') : $_('Execute') }}</label>
                </div>
            </div>
            <div class="w-4">
                <label class="block text-left font-semibold text-gray-700 mb-2">{{ $_('Others') }}:</label>
                <div class="flex align-items-center">
                    <Checkbox v-model="permissions[6]" inputId="permissions6" name="permissions6" value="true"></Checkbox>
                    <label for="permissions6" class="ml-2">{{ $_('Read') }}</label>
                </div>
                <div class="flex align-items-center mt-2">
                    <Checkbox v-model="permissions[7]" inputId="permissions7" name="permissions7" value="true"></Checkbox>
                    <label for="permissions7" class="ml-2">{{ $_('Write') }}</label>
                </div>
                <div class="flex align-items-center mt-2">
                    <Checkbox v-model="permissions[8]" inputId="permissions8" name="permissions8" value="true"></Checkbox>
                    <label for="permissions8" class="ml-2">{{ file.type == 'directory' ? $_('Browse') : $_('Execute') }}</label>
                </div>
            </div>
        </div>
        <div>
            <label for="chmodField" class="block text-left font-semibold text-gray-700 mb-1 mt-3">{{ $_('CHMOD value') }}:</label>
            <InputText
                id="chmodField"
                type="text"
                v-model="chmod"
                class="w-full"
                readonly
            />
        </div>
        <div class="flex justify-content-between gap-2 mt-4">
            <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"></Button>
            <Button type="button" :label="$_('Change permissions')" @click="action" :loading="loading"></Button>
        </div>
    </Dialog>
    </div>
</template>
    
<script>
    
    const requester = require("../../libraries/api.js");

    export default {
      name: "FileChmodDialog",
      data() {
        return {
          file: null,
          root: "",
          permissions: [[], [], [], [], [], [], [], [], []],
          chmod: "0000",
          visible: false,
          name: "",
          loading: false,
          error: "",
        }
      },
      methods: {
        show(file, root) {
            this.file = file;
            this.pemissions = [[], [], [], [], [], [], [], [], []];
            for(let i = 0; i < 9; i++) {
                if(file.permissions.substr(i+1, 1) != "-") this.permissions[i].push("true");
            }
            console.log(this.permissions);
            this.root = root;
            this.visible = true;
            this.error = "";
        },
        hide() {
            this.visible = false;
        },
        action() {
            this.error = "";
            
            this.loading = true;
            requester.post("/file/chmod", {
                file: this.root + "/" + this.file.name,
                chmod: this.chmod,
            }).then(() => {
                this.loading = false;
                this.visible = false;
                this.$refs["toast"].add({
                    severity: 'success', 
                    summary: this.$_("Operation performed successfully."), 
                    detail: this.$_("Permissions were modified") + ": " + this.file.name, 
                    life: 3000
                });
                this.$emit("modified", this.file.name);
            }).catch((error) => {
                switch(error.code) {
                    default:
                        this.error = this.$_("Unknown error.");
                }
                this.loading = false;
            });
        }
      },
      watch: {
        permissions: { 
            handler() {
                this.chmod = "0";
                for(let i = 0; i < 3; i++) {
                    var c = 0;
                    for(let j = 0; j < 3; j++) {
                        if(this.permissions[i*3+j].length) c |= (1 << (2-j));
                    }
                    this.chmod += c.toString();
                }
            },
            deep: true
        },
      }
    }
    </script>