import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

const requester = require("../libraries/api.js");

export default createStore({
  state: {
    login: null,
    session: null,
    locale: "en",
    package: {},
    connector: {},
    phpVersions: [],
    availableScripts: [],
    user: {},
    domains: [],
    emailAccounts: [],
    emailAliases: [],
    emailBlacklists: [],
    emailWhitelists: [],
    databases: [],
    ftp: {},
    databaseUsers: [],
    redirects: [],
    scripts: [],
    proxies: [],
    sshKeys: [],
    cronJobs: [],
    fileDir: "/",
    syncing: 0,
    upsell: {},
    redirect: {},
  },
  getters: {
    login(state) {
      return state.login;
    },
    session(state) {
      return state.session;
    },
    locale(state) {
      return state.locale;
    },
    domains(state) {
      return state.domains;
    },
    emailAccounts(state) {
      return state.emailAccounts;
    },
    emailAliases(state) {
      return state.emailAliases;
    },
    emailWhitelists(state) {
      return state.emailWhitelists;
    },
    emailBlacklists(state) {
      return state.emailBlacklists;
    },
    databases(state) {
      return state.databases;
    },
    mysqlDatabases(state) {
      return state.databases.filter((item) => item.type == "mysql");
    },
    postgresDatabases(state) {
      return state.databases.filter((item) => item.type == "postgres");
    },
    ftp(state) {
      return state.ftp;
    },
    diskLimit(state) {
      return state.package.diskLimit ?? 0;
    },
    diskUsage(state) {
      return state.user.diskUsed ?? 0;
    },
    emailLimit(state) {
      return state.package.emailLimit ?? 0;
    },
    ftpHost(state) {
      return state.connector.ftpHost;
    },
    smtpHost(state) {
      return state.connector.smtpHost;
    },
    smtpPort(state) {
      return state.connector.smtpPort;
    },
    pop3Host(state) {
      return state.connector.pop3Host;
    },
    pop3Port(state) {
      return state.connector.pop3Port;
    },
    imapHost(state) {
      return state.connector.imapHost;
    },
    imapPort(state) {
      return state.connector.imapPort;
    },
    sshHost(state) {
      return state.connector.ftpHost;
    },
    sshAvailable(state) {
      return state.package.sshAvailable;
    },
    postgresAvailable(state) {
      return state.package.postgresAvailable;
    },
    availableScripts(state) {
      return state.availableScripts;
    },
    mysqlHost(state) {
      return state.connector.mysqlHost;
    },
    mysqlUser(state) {
      var ret = "";
      state.databaseUsers.forEach((item) => {
        if(item.type == "mysql") ret = item.login;
      });
      return ret;
    },
    mysqlPassword(state) {
      var ret = "";
      state.databaseUsers.forEach((item) => {
        if(item.type == "mysql") ret = item.password;
      });
      return ret;
    },
    postgresHost(state) {
      return state.connector.postgresHost;
    },
    postgresUser(state) {
      var ret = "";
      state.databaseUsers.forEach((item) => {
        if(item.type == "postgres") ret = item.login;
      });
      return ret;
    },
    postgresPassword(state) {
      var ret = "";
      state.databaseUsers.forEach((item) => {
        if(item.type == "postgres") ret = item.password;
      });
      return ret;
    },
    webmailUrl(state) {
      return state.connector.webmailUrl;
    },
    phpmyadminUrl(state) {
      return state.connector.phpmyadminUrl;
    },
    phppgadminUrl(state) {
      return state.connector.phppgadminUrl;
    },
    phpVersions(state) {
      return state.phpVersions;
    },
    nameservers(state) {
      return [state.connector.ns1, state.connector.ns2];
    },
    redirects(state) {
      return state.redirects;
    },
    scripts(state) {
      return state.scripts;
    },
    proxies(state) {
      return state.proxies;
    },
    sshKeys(state) {
      return state.sshKeys;
    },
    cronJobs(state) {
      return state.cronJobs;
    },
    certificateCount(state) {
      return state.domains.reduce((acc, item) => acc + ((item.sslType == "auto") ? 0 : 1), 0);
    },
    sizeDomains(state) {
      return state.domains.reduce((acc, item) => acc + item.diskUsed, 0);
    },
    sizeDatabases(state) {
      return state.databases.reduce((acc, item) => acc + item.diskUsed, 0);
    },
    sizeEmails(state) {
      return state.emailAccounts.reduce((acc, item) => acc + item.diskUsed, 0);
    },
    sizeRest(state) {
      var s = state.user.diskUsed
        - state.domains.reduce((acc, item) => acc + item.diskUsed, 0)
        - state.databases.reduce((acc, item) => acc + item.diskUsed, 0)
        - state.emailAccounts.reduce((acc, item) => acc + item.diskUsed, 0);
      if(s < 0) s = 0;
      return s;
    },
    sizeReserved(state) {
      var s = state.emailAccounts.reduce((acc, item) => acc+item.diskLimit-item.diskUsed, 0);
      if(s + state.user.diskUsed > state.package.diskLimit) s = state.package.diskLimit - state.user.diskUsed;
      if(s < 0) s = 0;
      return s;
    },
    hideDomainConfig(state) {
      return state.user.hideDomainConfig;
    },
    hideDatabaseConfig(state) {
      return state.user.hideDatabaseConfig;
    },
    hideEmailConfig(state) {
      return state.user.hideEmailConfig;
    },
    fileDir(state) {
      return state.fileDir;
    },
    syncing(state) {
      return state.syncing > 0;
    },
    upsell(state) {
      return state.upsell;
    },
    redirect(state) {
      return state.redirect;
    },
    isPaid(state) {
      return state.user.paid;
    },
  },
  mutations: {
    login(state, {login, session}) {
      state.login = login;
      state.session = session;
    },
    logout(state) {
      state.login = null;
      state.session = null;
    },
    locale(state, {locale}) {
      state.locale = locale;
    },
    package(state, obj) {
      state.package = obj;
    },
    connector(state, obj) {
      state.connector = obj;
    },
    phpVersions(state, obj) {
      state.phpVersions = obj;
    },
    availableScripts(state, list) {
      state.availableScripts = list;
    },
    upsell(state, obj) {
      state.upsell = obj;
    },
    user(state, obj) {
      state.user = obj;
    },
    domains(state, list) {
      state.domains = list;
    },
    emailAccounts(state, list) {
      state.emailAccounts = list;
    },
    emailAliases(state, list) {
      state.emailAliases = list;
    },
    emailBlacklists(state, list) {
      state.emailBlacklists = list;
    },
    emailWhitelists(state, list) {
      state.emailWhitelists = list;
    },
    databases(state, list) {
      state.databases = list;
    },
    ftp(state, obj) {
      state.ftp = obj;
    },
    databaseUsers(state, list) {
      state.databaseUsers = list;
    },
    redirects(state, list) {
      state.redirects = list;
    },
    scripts(state, list) {
      state.scripts = list;
    },
    proxies(state, list) {
      state.proxies = list;
    },
    sshKeys(state, list) {
      state.sshKeys = list;
    },
    cronJobs(state, list) {
      state.cronJobs = list;
    },
    fileDir(state, dir) {
      state.fileDir = dir;
    },
    syncingClear(state) {
      state.syncing = 0;
    },
    syncingIncrease(state) {
      state.syncing++;
    },
    syncingDecrease(state) {
      state.syncing--;
    },
    redirect(state, obj) {
      state.redirect = obj;
    },
    redirectClear(state) {
      state.redirect = { page: null, action: null, domain: null };
    },
  },
  actions: {
    login({commit}, {login, password}) {
      return requester.post("/account/login", {
        login,
        password,
      }).then((data) => {
        commit("login", {
          login: data.user.login,
          session: data.token
        });
        commit("locale", {
          locale: "pl",
        });
        commit("package", data.package);
        commit("connector", data.connector);
        commit("phpVersions", data.phpVersions);
        commit("availableScripts", data.availableScripts);
        commit("upsell", data.upsell);
        commit("user", data.user);
        commit("domains", data.domains);
        commit("emailAccounts", data.emailAccounts);
        commit("emailAliases", data.emailAliases);
        commit("emailBlacklists", data.emailBlacklists);
        commit("emailWhitelists", data.emailWhitelists);
        commit("databases", data.databases);
        commit("ftp", data.ftp);
        commit("databaseUsers", data.databaseUsers);
        commit("redirects", data.redirects);
        commit("scripts", data.scripts);
        commit("proxies", data.proxies);
        commit("sshKeys", data.sshKeys);
        commit("cronJobs", data.cronJobs);
        commit("fileDir", "/");
        commit("syncingClear");
        commit("redirectClear");
        return data;
      });
    },
    logout({commit}) {
      return requester.post("/account/logout", {
      }).then(() => {
        commit("logout")
      }).catch(() => {
        commit("logout")
      });
    },
    redirect({commit}, {token}) {
      return requester.post("/account/redirect", {
        token,
      }).then((data) => {
        commit("login", {
          login: data.user.login,
          session: data.token
        });
        commit("locale", {
          locale: "pl",
        });
        commit("package", data.package);
        commit("connector", data.connector);
        commit("phpVersions", data.phpVersions);
        commit("availableScripts", data.availableScripts);
        commit("upsell", data.upsell);
        commit("user", data.user);
        commit("domains", data.domains);
        commit("emailAccounts", data.emailAccounts);
        commit("emailAliases", data.emailAliases);
        commit("emailBlacklists", data.emailBlacklists);
        commit("emailWhitelists", data.emailWhitelists);
        commit("databases", data.databases);
        commit("ftp", data.ftp);
        commit("databaseUsers", data.databaseUsers);
        commit("redirects", data.redirects);
        commit("scripts", data.scripts);
        commit("proxies", data.proxies);
        commit("sshKeys", data.sshKeys);
        commit("cronJobs", data.cronJobs);
        commit("fileDir", "/");
        commit("syncingClear");
        commit("redirect", data.redirect);
        return data.redirect;
      });
    },
    locale({commit}, locale) {
      commit("locale", { locale });
    },
    // eslint-disable-next-line no-unused-vars
    password({commit}, {oldPassword, newPassword}) {
      return requester.post("/account/password", {
        oldPassword,
        newPassword,
      });
    },
    keepalive({commit}) {
      return requester.post("/keepalive", {}).then((data) => {
        commit("user", data.user);
        commit("domains", data.domains);
        commit("emailAccounts", data.emailAccounts);
        commit("emailAliases", data.emailAliases);
        commit("databases", data.databases);
        commit("ftp", data.ftp);
        commit("databaseUsers", data.databaseUsers);
        commit("redirects", data.redirects);
        commit("scripts", data.scripts);
        commit("proxies", data.proxies);
        commit("sshKeys", data.sshKeys);
        commit("cronJobs", data.cronJobs);
        return data;
      }).catch(() => {});
    },
    sync({commit}) {
      commit("syncingIncrease");
      return requester.post("/account/sync", {}).then((data) => {
        commit("user", data.user);
        commit("domains", data.domains);
        commit("emailAccounts", data.emailAccounts);
        commit("databases", data.databases);
        commit("syncingDecrease");
      }).catch(() => {
        commit("syncingDecrease");
      });
    },
    displaySettings({commit}, {hideDomainConfig, hideDatabaseConfig, hideEmailConfig}) {
      return requester.post("/account/display", {
        hideDomainConfig,
        hideDatabaseConfig,
        hideEmailConfig
      }).then((data) => {
        commit("user", data.user);
      }).catch(() => {});
    },
    domainAdd({commit, dispatch}, {domain}) {
      return requester.post("/domain/add", {
        domain,
      }).then((data) => {
        commit("domains", data.domains);
        dispatch("sync");
        return data;
      });
    },
    domainRemove({commit, dispatch}, {domain}) {
      return requester.post("/domain/remove", {
        domain,
      }).then((data) => {
        commit("domains", data.domains);
        commit("scripts", data.scripts);
        dispatch("sync");
        return data;
      });
    },
    domainPHP({commit}, {domain, version}) {
      return requester.post("/domain/php", {
        domain,
        version,
      }).then((data) => {
        commit("domains", data.domains);
        return data;
      });
    },
    emailAccountCreate({commit}, {domain, name, quota, password}) {
      return requester.post("/email/account/create", {
        domain,
        name,
        quota,
        password,
      }).then((data) => {
        commit("emailAccounts", data.emailAccounts);
        return data;
      });
    },
    emailAccountDelete({commit, dispatch}, {domain, name}) {
      return requester.post("/email/account/delete", {
        domain,
        name,
      }).then((data) => {
        commit("emailAccounts", data.emailAccounts);
        dispatch("sync");
        return data;
      });
    },
    emailAccountQuota({commit}, {domain, name, quota}) {
      return requester.post("/email/account/quota", {
        domain,
        name,
        quota,
      }).then((data) => {
        commit("emailAccounts", data.emailAccounts);
        return data;
      });
    },
    emailAccountPassword({commit}, {domain, name, password}) {
      return requester.post("/email/account/password", {
        domain,
        name,
        password,
      }).then((data) => {
        commit("emailAccounts", data.emailAccounts);
        return data;
      });
    },
    emailAliasCreate({commit}, {domain, name, destination}) {
      return requester.post("/email/alias/create", {
        domain,
        name,
        destination,
      }).then((data) => {
        commit("emailAliases", data.emailAliases);
        return data;
      });
    },
    emailAliasDelete({commit}, {domain, name}) {
      return requester.post("/email/alias/delete", {
        domain,
        name,
      }).then((data) => {
        commit("emailAliases", data.emailAliases);
        return data;
      });
    },
    emailAliasModify({commit}, {domain, name, destination}) {
      return requester.post("/email/alias/modify", {
        domain,
        name,
        destination,
      }).then((data) => {
        commit("emailAliases", data.emailAliases);
        return data;
      });
    },
    emailBlacklistAdd({commit}, {domain, sender}) {
      return requester.post("/email/blacklist/add", {
        domain,
        sender,
      }).then((data) => {
        commit("emailBlacklists", data.emailBlacklists);
        return data;
      });
    },
    emailBlacklistRemove({commit}, {domain, sender}) {
      return requester.post("/email/blacklist/remove", {
        domain,
        sender,
      }).then((data) => {
        commit("emailBlacklists", data.emailBlacklists);
        return data;
      });
    },
    emailWhitelistAdd({commit}, {domain, sender}) {
      return requester.post("/email/whitelist/add", {
        domain,
        sender,
      }).then((data) => {
        commit("emailWhitelists", data.emailWhitelists);
        return data;
      });
    },
    emailWhitelistRemove({commit}, {domain, sender}) {
      return requester.post("/email/whitelist/remove", {
        domain,
        sender,
      }).then((data) => {
        commit("emailWhitelists", data.emailWhitelists);
        return data;
      });
    },
    emailCatchall({commit}, {domain, email}) {
      return requester.post("/email/catchall", {
        domain,
        email,
      }).then((data) => {
        commit("domains", data.domains);
        return data;
      });
    },
    emailMonitoring({commit}, {domain, email}) {
      return requester.post("/email/monitoring", {
        domain,
        email,
      }).then((data) => {
        commit("domains", data.domains);
        return data;
      });
    },
    databaseCreate({commit}, {name, type}) {
      return requester.post("/database/create", {
        name,
        type
      }).then((data) => {
        commit("databases", data.databases);
        return data;
      });
    },
    databaseDelete({commit, dispatch}, {name, type}) {
      return requester.post("/database/delete", {
        name,
        type
      }).then((data) => {
        commit("databases", data.databases);
        dispatch("sync");
        return data;
      });
    },
    databasePassword({commit}, {type, password}) {
      return requester.post("/database/password", {
        type,
        password
      }).then((data) => {
        commit("databaseUsers", data.databaseUsers);
        return data;
      });
    },
    redirectCreate({commit}, {domain, directory, type, destination, code}) {
      return requester.post("/redirect/create", {
        domain,
        directory,
        type,
        destination,
        code
      }).then((data) => {
        commit("redirects", data.redirects);
        return data;
      });
    },
    redirectDelete({commit}, {domain, directory}) {
      return requester.post("/redirect/delete", {
        domain,
        directory,
      }).then((data) => {
        commit("redirects", data.redirects);
        return data;
      });
    },
    redirectModify({commit}, {domain, directory, type, destination, code}) {
      return requester.post("/redirect/modify", {
        domain,
        directory,
        type,
        destination,
        code
      }).then((data) => {
        commit("redirects", data.redirects);
        return data;
      });
    },
    ftpPassword({commit}, {password}) {
      return requester.post("/ftp/password", {
        password,
      }).then((data) => {
        commit("ftp", data.ftp);
        return data;
      });
    },
    certificateGenerate({commit}, {domain}) {
      return requester.post("/certificate/generate", {
        domain,
      }).then((data) => {
        commit("domains", data.domains);
        return data;
      });
    },
    certificateUpload({commit}, {domain, cert, key, ca}) {
      return requester.post("/certificate/upload", {
        domain,
        cert,
        key,
        ca,
      }).then((data) => {
        commit("domains", data.domains);
        return data;
      });
    },
    scriptInstall({commit, dispatch}, {type, domain, directory, database, login, password}) {
      return requester.post("/script/install", {
        type,
        domain,
        directory,
        database,
        login,
        password
      }).then((data) => {
        commit("domains", data.domains);
        commit("databases", data.databases);
        commit("scripts", data.scripts);
        dispatch("sync");
        return data;
      });
    },
    scriptUninstall({commit, dispatch}, {id}) {
      return requester.post("/script/uninstall", {
        id,
      }).then((data) => {
        commit("scripts", data.scripts);
        dispatch("sync");
        return data;
      });
    },
    proxyCreate({commit}, {domain, directory, port}) {
      return requester.post("/proxy/create", {
        domain,
        directory,
        port,
      }).then((data) => {
        commit("proxies", data.proxies);
        return data;
      });
    },
    proxyDelete({commit}, {domain, directory}) {
      return requester.post("/proxy/delete", {
        domain,
        directory,
      }).then((data) => {
        commit("proxies", data.proxies);
        return data;
      });
    },
    proxyModify({commit}, {domain, directory, port}) {
      return requester.post("/proxy/modify", {
        domain,
        directory,
        port,
      }).then((data) => {
        commit("proxies", data.proxies);
        return data;
      });
    },
    sshKeyUpload({commit}, {key, name}) {
      return requester.post("/sshkey/upload", {
        key,
        name,
      }).then((data) => {
        commit("sshKeys", data.sshKeys);
        return data;
      });
    },
    sshKeyDelete({commit}, {id}) {
      return requester.post("/sshkey/delete", {
        id,
      }).then((data) => {
        commit("sshKeys", data.sshKeys);
        return data;
      });
    },
    cronAdd({commit}, {settings, command}) {
      return requester.post("/cron/add", {
        settings,
        command,
      }).then((data) => {
        commit("cronJobs", data.cronJobs);
        return data;
      });
    },
    cronRemove({commit}, {id}) {
      return requester.post("/cron/remove", {
        id,
      }).then((data) => {
        commit("cronJobs", data.cronJobs);
        return data;
      });
    },
    cronModify({commit}, {id, settings, command}) {
      return requester.post("/cron/modify", {
        id,
        settings,
        command,
      }).then((data) => {
        commit("cronJobs", data.cronJobs);
        return data;
      });
    },
  },
  plugins: [createPersistedState({ 
    storage: window.sessionStorage,
    getState: (key, storage) => {
      const value = storage.getItem(key);
      try {
        if(typeof value !== "undefined") {
          var ret = JSON.parse(value);
          if(ret) ret["syncing"] = 0;
          return ret;
        }
        else return undefined;
      } catch (err) { console.log(err); }
      return undefined;
    }
  })],
})
