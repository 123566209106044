<template>
    <div>
    <Toast ref="toast"/>
    <Dialog 
        v-model:visible="visible" 
        modal
        :draggable="false"
        :header="$_('Modify the redirection')"
        class="w-full lg:w-4 mx-2"
        >
        <div v-if="error">
            <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
        </div>
        <div>
            <label class="block text-left font-semibold text-gray-700 mb-2">{{ $_('Redirected address') }}:</label>
            <div>
                {{ 'http://' + domain + folder }}
            </div>
        </div>
        <label class="block text-left font-semibold text-gray-700 mb-2 mt-3">{{ $_('Redirection type') }}:</label>
        <div class="flex align-items-center">
            <RadioButton v-model="type" inputId="typeNormal" name="type" value="normal"/>
            <label for="typeNormal" class="ml-2">{{ $_('Redirect to a different website') }}</label>
        </div>
        <div class="flex align-items-center mt-2">
            <RadioButton v-model="type" inputId="typeHttp" name="type" value="http"/>
            <label for="typeHttp" class="ml-2">{{ $_('Redirect HTTP traffic to HTTPS') }}</label>
        </div>
        <div v-if="type == 'normal'">
            <label for="destinationField" class="block text-left font-semibold text-gray-700 mb-1 mt-3">{{ $_('Destination website') }}:</label>
            <InputText
                id="destinationField"
                type="text"
                v-model="destination"
                :invalid="destinationInvalid"
                class="w-full"
                :placeholder="$_('http://www.otherdomain.com')"
            />
            <div v-if="destinationInvalid" class="text-left text-red-500">
                <small>{{  $_('You need to enter a valid website address.') }}</small>
            </div>
        </div>
        <Accordion v-model:activeIndex="accordion" ref="accordion">
            <AccordionTab :header="$_('Advanced options')">
                <div class="flex align-items-center">
                    <RadioButton v-model="code" inputId="code301" name="code" :value="301"/>
                    <label for="code301" class="ml-2">{{ $_('Permanent redirect (301)') }}</label>
                </div>
                <div class="flex align-items-center mt-2">
                    <RadioButton v-model="code" inputId="code302" name="code" :value="302"/>
                    <label for="code302" class="ml-2">{{ $_('Temporary redirect (302)') }}</label>
                </div>
            </AccordionTab>
        </Accordion>
        <div class="flex justify-content-between gap-2 mt-4">
            <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"></Button>
            <Button type="button" :label="$_('Modify redirection')" @click="action" :loading="loading"></Button>
        </div>
    </Dialog>
    </div>
</template>
    
<script>
    export default {
      name: "RedirectCreateDialog",
      data() {
        return {
          visible: false,
          domain: "",
          type: "normal",
          destination: "",
          destinationInvalid: false,
          folder: "",
          loading: false,
          error: "",
          accordion: 0,
          code: 301,
        }
      },
      computed: {
        domains() {
            return this.$store.getters["domains"].map(item => item.domain);
        },
      },
      methods: {
        show(domain, folder) {
            this.domain = domain;
            this.folder = folder;
            this.$store.getters["redirects"].forEach((item) => {
                if(item.domain == domain && item.directory == folder) {
                    this.destination = item.destination;
                    this.type = item.type;
                    this.code = item.code;
                }
            });
            this.destinationInvalid = false;
            this.error = "";
            this.accordion = this.code == 301 ? null : 0;
            this.visible = true;
        },
        hide() {
            this.visible = false;
        },
        action() {
            this.error = "";

            this.destinationInvalid = false;

            var destination;
            if(this.type != "http") {
                if(!this.destination.trim().length || !this.destination.match(/\./)) {
                    this.destinationInvalid = true;
                    return;
                }
                destination = this.destination.trim();
                if(!destination.match(/^https?:\/\//)) destination = "http://" + destination;
            } else {
                destination = "https://" + this.domain + this.folder + "/";
            }

            this.loading = true;
            this.$store.dispatch("redirectModify", { 
                domain: this.domain,
                directory: this.folder,
                destination: destination,
                type: this.type,
                code: this.code,
            }).then(() => {
                this.loading = false;
                this.visible = false;
                this.$refs["toast"].add({
                    severity: 'success', 
                    summary: this.$_("Operation performed successfully."), 
                    detail: this.$_("Redirection was modified") + ": http://" + this.domain + this.folder, 
                    life: 3000
                });
            }).catch((error) => {
                switch(error.code) {
                    default:
                        this.error = this.$_("Unknown error.");
                }
                this.loading = false;
            });
        }
      },
      watch: {
        accordion(value) {
            if(value === 0) 
                this.$refs["accordion"].$el.getElementsByClassName("p-toggleable-content")[0].style.display = "block";
        },
        domain() { this.domainInvalid = false; },
        folder() { this.folderInvalid = false; },
        destination() { this.domainInvalid = false; },
      }
    }
    </script>