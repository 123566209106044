<template>
<ViewRoot active="emails">
  <div class="hidden lg:block">
    <TabMenu :model="tabMenu" :activeIndex="currentTab"/>
  </div>
  <div class="hidden sm:block lg:hidden">
    <TabMenu :model="tabletMenu" :activeIndex="currentTab"/>
  </div>
  <div class="block sm:hidden">
    <TabMenu :model="mobileMenu" :activeIndex="currentTab"/>
  </div>
  <div v-if="currentTab == 0">
      <DataTable :value="accounts" v-if="this.$store.getters['emailAccounts'].length > 0">
        <template #header>
            <div class="flex">
                <IconField iconPosition="left" class="w-full">
                    <InputIcon>
                    <i class="pi pi-search"></i>
                    </InputIcon>
                    <InputText
                        class="w-full"
                        v-model="filter1"
                        :placeholder="$_('Search accounts...')"
                    />
                </IconField>
                <Dropdown 
                    v-model="domain1"
                    class="hidden sm:flex ml-2"
                    :options="filterDomains"
                    :placeholder="$_('Select a domain')"
                    optionLabel="text"
                    optionValue="value"
                />
            </div>
        </template>
        <template #empty>
            <div>{{  $_('No e-mail addresses match the search criteria.') }}</div>
        </template>
        <Column :header="$_('E-mail address')">
            <template #body="slot">
                <div class="flex">
                    <div class="ellipsis ellipsis-400">{{ slot.data.name}}@{{ slot.data.domain }}</div>
                    <CopyIcon :text="slot.data.name + '@' + slot.data.domain" :message="$_('E-mail address copied to clipboard.')"/>
                </div>
            </template>
        </Column>
        <Column :header="$_('Used capacity')" headerClass="hidden md:cell" bodyClass="hidden md:cell">
            <template #body="slot">
                <div class="flex align-items-center">
                    <div style="width: 100px" class="hidden lg:block">
                        <MeterGroup :value="slot.data.usage" class="w-12 plain"/>
                    </div>
                    <div class="ml-2">
                        {{ $_('%1% of %2 MB').replace('%1', slot.data.usage[0].value).replace('%2', parseInt(slot.data.diskLimit/1024/1024)) }}
                    </div>
                </div>
            </template>
        </Column>
        <Column>
            <template #body="slot">
                <div class="table-buttons hidden lg:block">
                <Button 
                    :label="$_('Account operations')"
                    severity="secondary"
                    size="small"
                    icon="pi pi-chevron-down"
                    iconPos="right"
                    @click="showAccountMenu($event, slot.data.domain, slot.data.name, true)"
                />
                </div>
                <a v-ripple class="cursor-pointer block lg:hidden text-700 p-ripple text-right" @click="showMenu($event, slot.data.domain, slot.data.name, false)">
                    <i class="pi pi-ellipsis-v"></i>
                </a>
            </template>
        </Column>
    </DataTable>
    <p v-else class="mx-3">
        {{  $_('You have not created any e-mail accounts yet.') }}
    </p>
    <div class="m-3 flex flex-column sm:flex-row gap-2 justify-content-between">
        <Button
            :label="$_('Create an e-mail account')"
            icon="pi pi-plus"
            @click="$refs['accountCreate'].show()"
            />
        <div class="flex flex-column sm:flex-row gap-2 justify-content-between">
            <Button
                :label="$_('Open WebMail')"
                icon="pi pi-external-link"
                iconPos="right"
                severity="secondary"
                @click="webMail"
                />
            <Button
                :label="$_('E-mail configuration')"
                icon="pi pi-cog"
                severity="secondary"
                @click="$refs['infoEmails'].show()"
                />
        </div>
      </div>
  </div>
  <div v-if="currentTab == 1">
    <DataTable :value="aliases" v-if="this.$store.getters['emailAliases'].length > 0" class="mb-3">
        <template #header>
            <div class="flex">
                <IconField iconPosition="left" class="w-full">
                    <InputIcon>
                    <i class="pi pi-search"></i>
                    </InputIcon>
                    <InputText
                        class="w-full"
                        v-model="filter2"
                        :placeholder="$_('Search aliases...')"
                    />
                </IconField>
                <Dropdown
                    v-model="domain2"
                    class="hidden sm:flex ml-2"
                    :options="filterDomains"
                    :placeholder="$_('Select a domain')"
                    optionLabel="text"
                    optionValue="value"
                />
            </div>
        </template>
        <template #empty>
            <div>{{  $_('No e-mail aliases match the search criteria.') }}</div>
        </template>
        <Column :header="$_('E-mail address')">
            <template #body="slot">
                <div class="flex">
                    <div class="ellipsis ellipsis-250">{{ slot.data.name}}@{{ slot.data.domain }}</div>
                    <CopyIcon :text="slot.data.name + '@' + slot.data.domain" :message="$_('E-mail address copied to clipboard.')"/>
                </div>
            </template>
        </Column>
        <Column :header="$_('Destination')" headerClass="hidden md:cell" bodyClass="hidden md:cell">
            <template #body="slot">
                <div class="flex">
                    <div class="ellipsis ellipsis-250">{{ slot.data.destinations[0] }}</div>
                    <badge v-if="slot.data.destinations.length > 1" :value="'+'+(slot.data.destinations.length-1)" severity="secondary"/>
                </div>
            </template>
        </Column>
        <Column>
            <template #body="slot">
                <div class="table-buttons hidden lg:block">
                <Button 
                    :label="$_('Alias operations')"
                    severity="secondary"
                    size="small"
                    icon="pi pi-chevron-down"
                    iconPos="right"
                    @click="showAliasMenu($event, slot.data.domain, slot.data.name, true)"
                />
                </div>
                <a v-ripple class="cursor-pointer block lg:hidden text-700 p-ripple text-right" @click="showMenu($event, slot.data.domain, slot.data.name, false)">
                    <i class="pi pi-ellipsis-v"></i>
                </a>
            </template>
        </Column>
    </DataTable>
    <p v-else class="mx-3">
        {{  $_('You have not created any e-mail aliases yet.') }}
    </p>
    <div class="px-3">
        <Button
            :label="$_('Create an e-mail alias')"
            icon="pi pi-plus"
            @click="$refs['aliasCreate'].show()"
            />
    </div>
  </div>
  <div v-if="currentTab == 2">
    <DataTable :value="$store.getters['domains'].filter((item) => item.domain.includes(this.filter3.trim().toLowerCase()))">
        <template #header>
            <IconField iconPosition="left" class="w-full">
                <InputIcon>
                    <i class="pi pi-search"></i>
                </InputIcon>
                <InputText
                    class="w-full"
                    v-model="filter3"
                    :placeholder="$_('Search domains...')"
                />
            </IconField>
        </template>
        <template #empty>
            <div>{{  $_('No domain names match the search criteria.') }}</div>
        </template>
        <Column :header="$_('Domain name')">
            <template #body="slot">
                <div class="flex">
                    <div class="ellipsis ellipsis-250">{{ slot.data.domain }}</div>
                </div>
            </template>
        </Column>
        <Column :header="$_('Default e-mail address')" headerClass="hidden md:cell" bodyClass="hidden md:cell">
            <template #body="slot">
                <div class="flex">
                    <div class="ellipsis ellipsis-250">
                        <div class="text-gray-300" v-if="!slot.data.catchallEmail">
                            {{ $_('No default address') }}
                        </div>
                        <div v-else>
                            {{  slot.data.catchallEmail }}
                        </div>
                    </div>
                </div>
            </template>
        </Column>
        <Column>
            <template #body="slot">
            <div class="table-buttons hidden lg:block">
                <Button 
                :label="$_('Change default address')"
                severity="secondary"
                size="small"
                icon="pi pi-pencil"
                @click="$refs['catchallSet'].show(slot.data.domain)"
                />
            </div>
            <a v-ripple class="cursor-pointer block lg:hidden text-700 p-ripple text-right" @click="$refs['catchallSet'].show(slot.data.domain)">
                <i class="pi pi-pencil"></i>
            </a>
            </template>
        </Column>
    </DataTable>
  </div>
  <div v-if="currentTab == 3">
    <div class="w-full">
        <DataTable :value="this.$store.getters['emailWhitelists']" v-if="this.$store.getters['emailWhitelists'].length > 0">
            <Column :header="$_('Recipient domain name')" headerClass="hidden md:cell" bodyClass="hidden md:cell">
                <template #body="slot">
                    <div class="flex">
                        <div class="ellipsis ellipsis-250">{{ slot.data.domain }}</div>
                        <CopyIcon :text="slot.data.domain" :message="$_('Domain name copied to clipboard.')"/>
                    </div>
                </template>
            </Column>
            <Column :header="$_('Trusted e-mail sender')">
                <template #body="slot">
                    <div class="flex">
                        <div class="ellipsis ellipsis-250">{{ slot.data.sender }}</div>
                        <CopyIcon :text="slot.data.sender" :message="$_('E-mail sender name copied to clipboard.')"/>
                    </div>
                </template>
            </Column>
            <Column>
                <template #body="slot">
                    <div class="table-buttons hidden lg:block">
                    <Button 
                        :label="$_('Remove sender')"
                        severity="secondary"
                        size="small"
                        icon="pi pi-trash"
                        @click="$refs['whitelistRemove'].show(slot.data.domain, slot.data.sender)"
                    />
                    </div>
                    <a v-ripple class="cursor-pointer block lg:hidden text-700 p-ripple text-right" @click="$refs['whitelistRemove'].show(slot.data.domain, slot.data.sender)">
                        <i class="pi pi-trash"></i>
                    </a>
                </template>
            </Column>
        </DataTable>
        <p v-else class="mx-3">
            {{  $_('You have not added any trusted senders yet.') }}
        </p>
        <div class="m-3">
            <Button
                :label="$_('Add a trusted sender')"
                icon="pi pi-plus"
                @click="$refs['whitelistAdd'].show()"
                />
        </div>
    </div>
    <div class="w-full">
        <DataTable :value="this.$store.getters['emailBlacklists']" v-if="this.$store.getters['emailBlacklists'].length > 0">
            <Column :header="$_('Recipient domain name')" headerClass="hidden md:cell" bodyClass="hidden md:cell">
                <template #body="slot">
                    <div class="flex">
                        <div class="ellipsis ellipsis-250">{{ slot.data.domain }}</div>
                        <CopyIcon :text="slot.data.domain" :message="$_('Domain name copied to clipboard.')"/>
                    </div>
                </template>
            </Column>
            <Column :header="$_('Blocked e-mail sender')">
                <template #body="slot">
                    <div class="flex">
                        <div class="ellipsis ellipsis-250">{{ slot.data.sender }}</div>
                        <CopyIcon :text="slot.data.sender" :message="$_('E-mail sender name copied to clipboard.')"/>
                    </div>
                </template>
            </Column>
            <Column>
                <template #body="slot">
                    <div class="table-buttons hidden lg:block">
                    <Button 
                        :label="$_('Remove sender')"
                        severity="secondary"
                        size="small"
                        icon="pi pi-trash"
                        @click="$refs['blacklistRemove'].show(slot.data.domain, slot.data.sender)"
                    />
                    </div>
                    <a v-ripple class="cursor-pointer block lg:hidden text-700 p-ripple text-right" @click="$refs['blacklistRemove'].show(slot.data.domain, slot.data.sender)">
                        <i class="pi pi-trash"></i>
                    </a>
                </template>
            </Column>
        </DataTable>
        <p v-else class="mx-3">
            {{  $_('You have not added any blocked senders yet.') }}
        </p>
        <div class="m-3">
            <Button
                :label="$_('Add a blocked sender')"
                icon="pi pi-plus"
                @click="$refs['blacklistAdd'].show()"
                />
        </div>
    </div>
  </div>
  <div v-if="currentTab == 4">
    <DataTable :value="$store.getters['domains'].filter((item) => item.domain.includes(this.filter4.trim().toLowerCase()))">
        <template #header>
            <IconField iconPosition="left" class="w-full">
                <InputIcon>
                    <i class="pi pi-search"></i>
                </InputIcon>
                <InputText
                    class="w-full"
                    v-model="filter4"
                    :placeholder="$_('Search domains...')"
                />
            </IconField>
        </template>
        <template #empty>
            <div>{{  $_('No domain names match the search criteria.') }}</div>
        </template>
        <Column :header="$_('Domain name')">
            <template #body="slot">
                <div class="flex">
                    <div class="ellipsis ellipsis-250">{{ slot.data.domain }}</div>
                </div>
            </template>
        </Column>
        <Column :header="$_('Outgoing e-mail monitoring')" headerClass="hidden md:cell" bodyClass="hidden md:cell">
            <template #body="slot">
                <div class="flex">
                    <div class="ellipsis ellipsis-250">
                        <div  class="text-gray-300" v-if="!slot.data.bccEmail">
                            {{ $_('No mail monitoring') }}
                        </div>
                        <div v-else>
                            {{  slot.data.bccEmail }}
                        </div>
                    </div>
                </div>
            </template>
        </Column>
        <Column>
            <template #body="slot">
            <div class="table-buttons hidden lg:block">
                <Button 
                :label="$_('Change mail monitoring')"
                severity="secondary"
                size="small"
                icon="pi pi-pencil"
                @click="$refs['monitoringSet'].show(slot.data.domain)"
                />
            </div>
            <a v-ripple class="cursor-pointer block lg:hidden text-700 p-ripple text-right" @click="$refs['monitoringSet'].show(slot.data.domain)">
                <i class="pi pi-pencil"></i>
            </a>
            </template>
        </Column>
    </DataTable>
  </div>
  <ContextMenu ref="contextMenu" :model="contextMenu"/>
  <AccountCreateDialog ref="accountCreate"/>
  <AccountDeleteDialog ref="accountDelete"/>
  <AccountPasswordDialog ref="accountPassword"/>
  <AccountQuotaDialog ref="accountQuota"/>
  <AliasCreateDialog ref="aliasCreate"/>
  <AliasDeleteDialog ref="aliasDelete"/>
  <AliasModifyDialog ref="aliasModify"/>
  <CatchallSetDialog ref="catchallSet"/>
  <MonitoringSetDialog ref="monitoringSet"/>
  <BlacklistAddDialog ref="blacklistAdd"/>
  <BlacklistRemoveDialog ref="blacklistRemove"/>
  <WhitelistAddDialog ref="whitelistAdd"/>
  <WhitelistRemoveDialog ref="whitelistRemove"/>
  <InfoEmailsDialog ref="infoEmails"/>
</ViewRoot>
</template>

<script>

import ViewRoot from "../components/ViewRoot.vue";
import CopyIcon from "../components/CopyIcon.vue";

import AccountCreateDialog from "../dialogs/email/AccountCreateDialog.vue";
import AccountDeleteDialog from "../dialogs/email/AccountDeleteDialog.vue";
import AccountPasswordDialog from "../dialogs/email/AccountPasswordDialog.vue";
import AccountQuotaDialog from "../dialogs/email/AccountQuotaDialog.vue";
import AliasCreateDialog from "../dialogs/email/AliasCreateDialog.vue";
import AliasDeleteDialog from "../dialogs/email/AliasDeleteDialog.vue";
import AliasModifyDialog from "../dialogs/email/AliasModifyDialog.vue";
import CatchallSetDialog from "../dialogs/email/CatchallSetDialog.vue";
import MonitoringSetDialog from "../dialogs/email/MonitoringSetDialog.vue";
import BlacklistAddDialog from "../dialogs/email/BlacklistAddDialog.vue";
import BlacklistRemoveDialog from "../dialogs/email/BlacklistRemoveDialog.vue";
import WhitelistAddDialog from "../dialogs/email/WhitelistAddDialog.vue";
import WhitelistRemoveDialog from "../dialogs/email/WhitelistRemoveDialog.vue";
import InfoEmailsDialog from "../dialogs/info/InfoEmailsDialog.vue";

export default {
  name: "DomainsView",
  components: {
    ViewRoot,
    CopyIcon,
    AccountCreateDialog,
    AccountDeleteDialog,
    AccountPasswordDialog,
    AccountQuotaDialog,
    AliasCreateDialog,
    AliasDeleteDialog,
    AliasModifyDialog,
    CatchallSetDialog,
    MonitoringSetDialog,
    BlacklistAddDialog,
    BlacklistRemoveDialog,
    WhitelistAddDialog,
    WhitelistRemoveDialog,
    InfoEmailsDialog,
  },
  mounted() {
    const redirect = this.$store.getters["redirect"];
    switch(redirect.action) {
      case "accounts":
        this.currentTab = 0;
        if(redirect.domain) this.filter1 = redirect.domain;
        break;
      case "forwarders":
        this.currentTab = 1;
        if(redirect.domain) this.filter2 = redirect.domain;
        break;
      case "catchall":
        this.currentTab = 2;
        this.$refs["catchallSet"].show(redirect.domain);
        break;
      case "filters":
        this.currentTab = 3;
        break;
      case "monitoring":
        this.currentTab = 4;
        this.$refs["monitoringSet"].show(redirect.domain);
        break;
    }
    this.$store.commit("redirectClear");
    this.$store.dispatch("keepalive");
  },
  data() {
    return {
      filter1: "",
      filter2: "",
      domain1: "_",
      domain2: "_",
      filter3: "",
      filter4: "",
      currentTab: 0,
      contextDomain: "",
      contextName: "",
      contextMenu: [],
    }
  },
  computed: {
    filterDomains() {
        var ret = [{ value: "_", text: this.$_("in all domains") }];
        this.$store.getters["domains"].forEach((item) => {
            ret.push({ value: item.domain, text: item.domain });
        });
        return ret;
    },
    accounts() {
        var ret = [];
        if(this.domain1 == "_")
            ret = this.$store.getters["emailAccounts"];
        else
            ret = this.$store.getters["emailAccounts"].filter((item) => item.domain == this.domain1);
        if(this.filter1.trim().length)
            ret = ret.filter((item) => item.name.includes(this.filter1.trim().toLowerCase()))
        ret.forEach((item) => {
            var usage = 100 * item.diskUsed / item.diskLimit;
            if(usage > 100) usage = 100;
            item.usage = [{ label: "", value: usage.toFixed(1)}];
        });
        return ret;
    },
    aliases() {
        var ret = [];
        if(this.domain2 == "_")
            ret = this.$store.getters["emailAliases"];
        else
            ret = this.$store.getters["emailAliases"].filter((item) => item.domain == this.domain2);
        if(this.filter2.trim().length)
            ret = ret.filter((item) => item.name.includes(this.filter2.trim().toLowerCase()))
        ret.forEach((item) => {
            item.destinations = item.destination.split(",");
        });
        return ret;
    },
    tabMenu() {
      return [
        { label: this.$_("E-mail accounts"), command: () => this.currentTab = 0 },
        { label: this.$_("E-mail aliases"), command: () => this.currentTab = 1 },
        { label: this.$_("Default addresses"), command: () => this.currentTab = 2 },
        { label: this.$_("Blocks and filters"), command: () => this.currentTab = 3 },
        { label: this.$_("Mail monitoring"), command: () => this.currentTab = 4 },
      ];
    },
    tabletMenu() {
      return [
        { label: this.$_("Accounts"), command: () => this.currentTab = 0 },
        { label: this.$_("Aliases"), command: () => this.currentTab = 1 },
        { label: this.$_("Catch-all"), command: () => this.currentTab = 2 },
        { label: this.$_("Filters"), command: () => this.currentTab = 3 },
        { label: this.$_("Monitoring"), command: () => this.currentTab = 4 },
      ];
    },
    mobileMenu() {
      return [
        { label: this.$_("Accounts"), command: () => this.currentTab = 0 },
        { label: this.$_("Aliases"), command: () => this.currentTab = 1 },
        { label: this.$_("Catch-all"), command: () => this.currentTab = 2 },
      ];
    },
  },
  methods: {
    webMail() {
      window.open(this.$store.getters["webmailUrl"]);
    },
    showAccountMenu(event, domain, name) {
      this.contextDomain = domain;
      this.contextName = name;

      this.contextMenu = [];
      this.contextMenu.push({ label: this.$_("Change password"), icon: "pi pi-unlock", command: () => this.$refs["accountPassword"].show(this.contextDomain, this.contextName) });
      this.contextMenu.push({ label: this.$_("Change capacity"), icon: "pi pi-arrows-h", command: () => this.$refs["accountQuota"].show(this.contextDomain, this.contextName) });
      this.contextMenu.push({ label: this.$_("Delete account"), icon: "pi pi-trash", command: () => this.$refs["accountDelete"].show(this.contextDomain, this.contextName) });
      this.$refs["contextMenu"].show(event);
    },
    showAliasMenu(event, domain, name) {
      this.contextDomain = domain;
      this.contextName = name;

      this.contextMenu = [];
      this.contextMenu.push({ label: this.$_("Modify alias"), icon: "pi pi-pencil", command: () => this.$refs["aliasModify"].show(this.contextDomain, this.contextName) });
      this.contextMenu.push({ label: this.$_("Delete alias"), icon: "pi pi-trash", command: () => this.$refs["aliasDelete"].show(this.contextDomain, this.contextName) });
      this.$refs["contextMenu"].show(event);
    },
  }
}

</script>
