<template>
<ViewRoot active="domains">
  <DataTable :value="$store.getters['domains'].filter((item) => item.domain.includes(this.filter.trim().toLowerCase()))">
    <template #header>
      <IconField iconPosition="left" class="w-full">
        <InputIcon>
          <i class="pi pi-search"></i>
        </InputIcon>
        <InputText
          class="w-full"
          v-model="filter"
          :placeholder="$_('Search domains...')"
        />
      </IconField>
    </template>
    <template #empty>
      <div>{{  $_('No domain names match the search criteria.') }}</div>
    </template>
    <Column :header="$_('Domain name')">
      <template #body="slot">
        <div class="flex">
          <div class="ellipsis ellipsis-250">{{ slot.data.domain }}</div>
          <CopyIcon :text="slot.data.domain" :message="$_('Domain name copied to clipboard.')"/>
        </div>
      </template>
    </Column>
    <Column :header="$_('Disk usage')" headerClass="hidden md:cell" bodyClass="hidden md:cell">
      <template #body="slot">
        {{ showSize(slot.data.diskUsed) }}
      </template>
    </Column>
    <Column :header="$_('IP address')" headerClass="hidden md:cell" bodyClass="hidden md:cell">
      <template #body="slot">
        {{ slot.data.ip }}
        <CopyIcon :text="slot.data.ip" :message="$_('IP address copied to clipboard.')"/>
      </template>
    </Column>
    <Column>
      <template #body="slot">
        <div class="table-buttons hidden lg:block">
          <Button 
            :label="$_('Domain operations')"
            severity="secondary"
            size="small"
            icon="pi pi-chevron-down"
            iconPos="right"
            @click="showMenu($event, slot.data.domain, true)"
          />
        </div>
        <a v-ripple class="cursor-pointer block lg:hidden text-700 p-ripple text-right" @click="showMenu($event, slot.data.domain, false)">
          <i class="pi pi-ellipsis-v"></i>
        </a>
      </template>
    </Column>
  </DataTable>
  <div class="text-left m-3 flex flex-column sm:flex-row gap-2 justify-content-between">
    <Button
      :label="$_('Add a domain')"
      icon="pi pi-plus"
      @click="$refs['domainAdd'].show()"
    />
    <Button
      :label="$_('Domain configuration')"
      icon="pi pi-cog"
      severity="secondary"
      @click="$refs['infoDomains'].show()"
    />
  </div>
  <ContextMenu ref="contextMenu" :model="contextMenu"/>
  <DomainAddDialog ref="domainAdd"/>
  <DomainRemoveDialog ref="domainRemove"/>
  <DomainPHPDialog ref="domainPHP"/>
  <AccountCreateDialog ref="accountCreate"/>
  <AliasCreateDialog ref="aliasCreate"/>
  <RedirectCreateDialog ref="redirectCreate"/>
  <RedirectModifyDialog ref="redirectModify"/>
  <ScriptInstallDialog ref="scriptInstall"/>
  <CertificateUploadDialog ref="certificateUpload"/>
  <InfoDomainsDialog ref="infoDomains"/>
  <LogShowDialog ref="logShow"/>
  <BlacklistAddDialog ref="blacklistAdd"/>
  <WhitelistAddDialog ref="whitelistAdd"/>
  <AccountPasswordDialog ref="accountPassword"/>
</ViewRoot>
</template>

<script>

import ViewRoot from "../components/ViewRoot.vue";
import CopyIcon from "../components/CopyIcon.vue";

import DomainAddDialog from "../dialogs/domain/DomainAddDialog.vue";
import DomainRemoveDialog from "../dialogs/domain/DomainRemoveDialog.vue";
import DomainPHPDialog from "../dialogs/domain/DomainPHPDialog.vue";
import AccountCreateDialog from "../dialogs/email/AccountCreateDialog.vue";
import AliasCreateDialog from "../dialogs/email/AliasCreateDialog.vue";
import RedirectCreateDialog from "../dialogs/redirect/RedirectCreateDialog.vue";
import RedirectModifyDialog from "../dialogs/redirect/RedirectModifyDialog.vue";
import ScriptInstallDialog from "../dialogs/script/ScriptInstallDialog.vue";
import CertificateUploadDialog from "../dialogs/certificate/CertificateUploadDialog.vue";
import InfoDomainsDialog from "../dialogs/info/InfoDomainsDialog.vue";
import LogShowDialog from "../dialogs/log/LogShowDialog.vue";
import BlacklistAddDialog from "../dialogs/email/BlacklistAddDialog.vue";
import WhitelistAddDialog from "../dialogs/email/WhitelistAddDialog.vue";
import AccountPasswordDialog from "../dialogs/account/AccountPasswordDialog.vue";

import { size } from "../mixins/size.js";

export default {
  name: "DomainsView",
  components: {
    ViewRoot,
    CopyIcon,
    DomainAddDialog,
    DomainRemoveDialog,
    DomainPHPDialog,
    AccountCreateDialog,
    AliasCreateDialog,
    CertificateUploadDialog,
    RedirectCreateDialog,
    RedirectModifyDialog,
    ScriptInstallDialog,
    InfoDomainsDialog,
    LogShowDialog,
    BlacklistAddDialog,
    WhitelistAddDialog,
    AccountPasswordDialog,
  },
  mixins: [
    size
  ],
  mounted() {
    const redirect = this.$store.getters["redirect"];
    switch(redirect.action) {
      case "password":
        this.$refs["accountPassword"].show();
        break;
      case "redirect":
        if(!this.$store.getters["redirects"].find((item) => item.domain == redirect.domain && item.directory == "/"))
          this.$refs["redirectCreate"].show(redirect.domain);
        else
          this.$refs["redirectModify"].show(redirect.domain, "/");
        break;
      case "script":
        this.$refs["scriptInstall"].show(redirect.domain);
        break;
      case "ssl":
        this.$refs["certificateUpload"].show();
        break;
    }
    this.$store.commit("redirectClear");
    this.$store.dispatch("keepalive");
  },
  data() {
    return {
      filter: "",
      contextDomain: "",
      contextMenu: [],
    }
  },
  methods: {
    showMenu(event, domain) {
      this.contextDomain = domain;

      this.contextMenu = [];
      this.contextMenu.push({ label: this.$_("Create e-mail account"), icon: "pi pi-envelope", command: () => this.$refs["accountCreate"].show(this.contextDomain) });
      this.contextMenu.push({ label: this.$_("Create e-mail alias"), icon: "pi pi-envelope", command: () => this.$refs["aliasCreate"].show(this.contextDomain) });
      if(!this.$store.getters["redirects"].find((item) => item.domain == domain && item.directory == "/"))
        this.contextMenu.push({ label: this.$_("Create redirection"), icon: "pi pi-arrow-right-arrow-left", command: () => this.$refs["redirectCreate"].show(this.contextDomain) });
      else
        this.contextMenu.push({ label: this.$_("Modify redirection"), icon: "pi pi-arrow-right-arrow-left", command: () => this.$refs["redirectModify"].show(this.contextDomain, "/") });
      this.contextMenu.push({ label: this.$_("Upload SSL certificate"), icon: "pi pi-lock", command: () => this.$refs["certificateUpload"].show() });
      this.contextMenu.push({ label: this.$_("Show server log"), icon: "pi pi-align-left", command: () => this.$refs["logShow"].show(this.contextDomain, 'access') });
      this.contextMenu.push({ label: this.$_("Browse files"), icon: "pi pi-folder-open", command: () => this.browseFiles(this.contextDomain) });
      this.contextMenu.push({ label: this.$_("Change PHP version"), icon: "pi pi-sliders-h", command: () => this.$refs["domainPHP"].show(this.contextDomain) });
      this.contextMenu.push({ label: this.$_("Install WordPress"), icon: "pi pi-bolt", command: () => this.$refs["scriptInstall"].show("wordpress", this.contextDomain, 0) });
      this.contextMenu.push({ label: this.$_("Add trusted e-mail sender"), icon: "pi pi-check-circle", command: () => this.$refs["whitelistAdd"].show(this.contextDomain) });
      this.contextMenu.push({ label: this.$_("Add blocked e-mail sender"), icon: "pi pi-times-circle", command: () => this.$refs["blacklistAdd"].show(this.contextDomain) });

      var canDelete = true;
      this.$store.getters["domains"].forEach((item) => {
        if(item.domain == domain) canDelete = item.canDelete;
      });
      if(canDelete) {
        this.contextMenu.push({ label: this.$_("Delete domain"), icon: "pi pi-trash", command: () => this.$refs["domainRemove"].show(this.contextDomain) });
      }
      this.$refs["contextMenu"].show(event);
    },
    browseFiles(domain) {
      var directory = this.$store.getters["domains"].find((item) => item.domain == domain).directory;
      this.$store.commit("fileDir", "/" + directory);
      this.$nextTick(() => {
        this.$router.push({ name: "files" });
      });
    }
  }
}

</script>
