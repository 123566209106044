<template>
<ViewRoot active="databases">
  <div class="hidden md:block">
    <TabMenu :model="tabMenu" :activeIndex="currentTab"/>
  </div>
  <div class="block md:hidden" :activeIndex="currentTab">
    <TabMenu :model="mobileMenu"/>
  </div>
    <div v-if="currentTab == 0">
      <DataTable :value="$store.getters['mysqlDatabases']" v-if="$store.getters['mysqlDatabases'].length">
        <Column :header="$_('Database name')">
          <template #body="slot">
            {{ slot.data.name }}
            <CopyIcon :text="slot.data.name" :message="$_('Database name copied to clipboard.')"/>
          </template>
        </Column>
        <Column :header="$_('Disk usage')" headerClass="hidden md:cell" bodyClass="hidden md:cell">
          <template #body="slot">
            {{ showSize(slot.data.diskUsed) }}
          </template>
        </Column>
        <Column>
          <template #body="slot">
            <div class="table-buttons hidden lg:block">
              <Button 
                :label="$_('Delete database')"
                severity="secondary"
                size="small"
                icon="pi pi-trash"
                @click="$refs['databaseDelete'].show(slot.data.name, 'mysql')"
              />
            </div>
            <a v-ripple class="cursor-pointer block lg:hidden text-700 p-ripple text-right" @click="$refs['databaseDelete'].show(slot.data.name, 'mysql')">
              <i class="pi pi-trash"></i>
            </a>
          </template>
        </Column>
      </DataTable>
      <p v-else class="mx-3">
        {{  $_('You have not created any MySQL databases yet.') }}
      </p>
      <div class="m-3 flex flex-column sm:flex-row gap-2 justify-content-between">
        <Button
          :label="$_('Create new database')"
          icon="pi pi-plus"
          @click="$refs['databaseCreate'].show('mysql')"
        />
        <div class="flex flex-column sm:flex-row gap-2 justify-content-between">
          <Button
            :label="$_('Open phpMyAdmin')"
            icon="pi pi-external-link"
            iconPos="right"
            severity="secondary"
            class="md:ml-2 mt-2 md:mt-0"
            @click="phpMyAdmin"
          />
          <Button
            :label="$_('MySQL access data')"
            icon="pi pi-cog"
            severity="secondary"
            @click="$refs['infoMysql'].show()"
            />
        </div>
      </div>
    </div>
    <div v-if="currentTab == 1">
        <div class="p-3" v-if="!$store.getters['postgresAvailable']">
          <p class="mt-0 mb-2">
              {{  $_('Your current plan does not include Postgres databases.') }}
          </p>
          <Button severity="secondary" :label="$_('Upgrade your plan')" icon="pi pi-external-link" iconPos="right" @click="upgradePackage"/>
        </div>
        <div v-else>
          <DataTable :value="$store.getters['postgresDatabases']" v-if="$store.getters['postgresDatabases'].length">
            <Column :header="$_('Database name')">
              <template #body="slot">
                {{ slot.data.name }}
                <CopyIcon :text="slot.data.name" :message="$_('Database name copied to clipboard.')"/>
              </template>
            </Column>
            <Column :header="$_('Disk usage')" headerClass="hidden md:cell" bodyClass="hidden md:cell">
            <template #body="slot">
              {{ showSize(slot.data.diskUsed) }}
            </template>
          </Column>
            <Column>
              <template #body="slot">
                <div class="table-buttons hidden lg:block">
                  <Button 
                    :label="$_('Delete database')"
                    severity="secondary"
                    size="small"
                    icon="pi pi-trash"
                    @click="$refs['databaseDelete'].show(slot.data.name, 'postgres')"
                  />
                </div>
                <a v-ripple class="cursor-pointer block lg:hidden text-700 p-ripple text-right" @click="$refs['databaseDelete'].show(slot.data.name, 'mysql')">
                  <i class="pi pi-trash"></i>
                </a>
              </template>
            </Column>
          </DataTable>
          <p v-else class="mx-3">
            {{  $_('You have not created any Postgres databases yet.') }}
          </p>
          <div class="m-3 flex flex-column sm:flex-row gap-2 justify-content-between">
            <Button
              :label="$_('Create new database')"
              icon="pi pi-plus"
              @click="$refs['databaseCreate'].show('postgres')"
            />
            <div class="flex flex-column sm:flex-row gap-2 justify-content-between">
              <Button
                :label="$_('Open phpPgAdmin')"
                icon="pi pi-external-link"
                iconPos="right"
                severity="secondary"
                class="md:ml-2 mt-2 md:mt-0"
                @click="phpPgAdmin"
              />
              <Button
                :label="$_('Postgres access data')"
                icon="pi pi-cog"
                severity="secondary"
                @click="$refs['infoPostgres'].show()"
                />
            </div>
          </div>
        </div>
    </div>
    <DatabaseCreateDialog ref="databaseCreate"/>
    <DatabaseDeleteDialog ref="databaseDelete"/>
    <InfoMysqlDialog ref="infoMysql"/>
    <InfoPostgresDialog ref="infoPostgres"/>
</ViewRoot>
</template>

<script>

import ViewRoot from "../components/ViewRoot.vue";
import CopyIcon from "../components/CopyIcon.vue";

import DatabaseCreateDialog from "../dialogs/database/DatabaseCreateDialog.vue";
import DatabaseDeleteDialog from "../dialogs/database/DatabaseDeleteDialog.vue";
import InfoMysqlDialog from "../dialogs/info/InfoMysqlDialog.vue";
import InfoPostgresDialog from "../dialogs/info/InfoPostgresDialog.vue";

import { size } from "../mixins/size.js";
import { upgrade } from "../mixins/upgrade.js";

export default {
  name: "AccessView",
  components: {
    ViewRoot,
    CopyIcon,
    DatabaseCreateDialog,
    DatabaseDeleteDialog,
    InfoMysqlDialog,
    InfoPostgresDialog,
  },
  mixins: [
    size, upgrade
  ],
  mounted() {
    const redirect = this.$store.getters["redirect"];
    switch(redirect.action) {
      case "mysql":
        this.currentTab = 0;
        break;
      case "mysqlAdd":
        this.currentTab = 0;
        this.$refs["databaseCreate"].show("mysql");
        break;
      case "postgres":
        this.currentTab = 1;
        break;
      case "postgresAdd":
        this.currentTab = 1;
        if(this.$store.getters['postgresAvailable']) this.$refs["databaseCreate"].show("postgres");
        break;
    }
    this.$store.commit("redirectClear");
    this.$store.dispatch("keepalive");
  },
  data() {
    return {
        currentTab: 0,
    }
  },
  computed: {
    tabMenu() {
      return [
        { label: this.$_("MySQL databases"), command: () => this.currentTab = 0 },
        { label: this.$_("Postgres databases"), command: () => this.currentTab = 1 },
      ];
    },
    mobileMenu() {
      return [
        { label: this.$_("MySQL"), command: () => this.currentTab = 0 },
        { label: this.$_("Postgres"), command: () => this.currentTab = 1 },
      ];
    },
  },
  methods: {
    phpMyAdmin() {
      window.open(this.$store.getters["phpmyadminUrl"]);
    },
    phpPgAdmin() {
      window.open(this.$store.getters["phppgadminUrl"]);
    },
  }
}

</script>
