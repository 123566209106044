<template>
    <div>
    <Toast ref="toast"/>
    <Dialog 
        v-model:visible="visible" 
        modal
        :draggable="false"
        :header="$_('Upload files')"
        class="w-full lg:w-4 mx-2"
        >
        <div v-if="error">
            <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
        </div>
        <div class="upload-root" :class="{ 'active' : dropping > 0 }" @dragover.prevent @drop.prevent="filesDropped($event)" @dragenter="dropping++" @dragleave="dropping--">
            <input type="file" ref="files" :multiple="false" accept=".zip,.tar,.tar.gz" @change="filesSelected" v-if="!loading">
            <div v-if="!loading" class="upload-overlay">
                <div class="flex flex-column align-items-center">
                    <div class="mb-3">{{ $_('Drag and drop an archive file here to upload.') }}</div>
                    <Button
                        :label="$_('Choose a file from disk')"
                        icon="pi pi-upload"
                        severity="secondary"
                        @click="$refs['files'].click()"
                    />
                </div>
            </div>
            <div class="upload-overlay" v-else>
                <div class="flex flex-column align-items-center">
                    <div class="mb-2">
                        {{  $_('Uploading file...') }}
                    </div>
                    <div style="width: 300px">
                        <ProgressBar :value="progress"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-content-between gap-2 mt-4">
            <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"></Button>
            <Button type="button" :label="$_('Upload files')" @click="action" :loading="loading" :disabled="!loading"></Button>
        </div>
    </Dialog>
    </div>
</template>
    
<script>
    
    const requester = require("../../libraries/api.js");

    export default {
      name: "FileUploadDialog",
      data() {
        return {
          root: "",
          progress: 0,
          progress2: 0,
          visible: false,
          name: "",
          loading: false,
          error: "",
          dropping: 0,
        }
      },
      methods: {
        show(root) {
            this.root = root;
            this.progress = 0;
            this.progress2 = 0;
            this.dropping = 0;
            this.visible = true;
            this.error = "";
            this.$nextTick(() => {
                this.$refs["files"].click();
            });
        },
        hide() {
            this.visible = false;
        },
        filesDropped(event) {
            this.dropping = 0;
            this.action(event.dataTransfer.files);
        },
        filesSelected() {
            this.action(this.$refs['files'].files);
        },
        action(files) {
            this.error = "";
            
            this.loading = true;
            requester.upload("/file/zip/upload", {
                directory: this.root,
            }, files, this.onProgress).then(() => {
                this.loading = false;
                this.visible = false;
                this.$refs["toast"].add({
                    severity: 'success', 
                    summary: this.$_("Operation performed successfully."), 
                    detail: this.$_("Files were uploaded."), 
                    life: 3000
                });
                this.$emit("uploaded");
            }).catch((error) => {
                switch(error.code) {
                    case 406:
                        this.error = this.$_("Files too big for upload.");
                        break;
                    default:
                        this.error = this.$_("Unknown error.");
                }
                this.loading = false;
            });
        },
        onProgress(event) {
            this.progress = parseInt(event.progress * 100);
        },
      },
      watch: {
        name() { this.nameInvalid = false; },
      }
    }
    </script>

<style>
.upload-root {
    min-height: 100px;
    position: relative;
    border: 2px dashed transparent;
    border-radius: 5px;
}
.upload-root.active {
    border-color: var(--gray-300);
}

.upload-root INPUT {
    display: none;
}

.upload-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>