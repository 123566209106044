<template>
    <div>
    <Toast ref="toast"/>
    <Dialog 
        v-model:visible="visible" 
        modal
        :draggable="false"
        :header="$_('Add a trusted sender')"
        class="w-full lg:w-4 mx-2"
        >
        <div v-if="error">
            <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
        </div>
        <div>
            <div class="mb-3">
                {{ $_('E-mails from trusted senders will be always delivered, bypassing any spam filters.') }}
            </div>
        </div>
        <div>
            <label for="sourceField" class="block text-left font-semibold text-gray-700 mb-1">{{ $_('Recipient domain name') }}:</label>
            <Dropdown
                class="w-full"
                v-model="source"
                :options="domains"
                :placeholder="$_('Select a domain')"
            />
            <div v-if="sourceInvalid" class="text-left text-red-500">
                <small>{{  $_('You need to select a domain.') }}</small>
            </div>
        </div>
        <div class="mt-3">
            <label for="emailField" class="block text-left font-semibold text-gray-700 mb-2 mt-3">{{ $_('Add to trusted list') }}:</label>
            <div class="flex align-items-center">
                <RadioButton v-model="type" inputId="isEmail" name="type" value="email"/>
                <label for="isEmail" class="ml-2">{{ $_('A single sender e-mail address') }}</label>
            </div>
            <div class="flex align-items-center mt-2">
                <RadioButton v-model="type" inputId="isDomain" name="type" value="domain"/>
                <label for="isDomain" class="ml-2">{{ $_('A whole sender domain') }}</label>
            </div>
            <div class="flex align-items-center mt-2">
                <RadioButton v-model="type" inputId="isIp" name="type" value="ip"/>
                <label for="isIp" class="ml-2">{{ $_('A sender IP address') }}</label>
            </div>
        </div>
        <div v-if="type=='email'">
            <label for="emailField" class="block text-left font-semibold text-gray-700 mb-1 mt-3">{{ $_('E-mail address to be trusted') }}:</label>
            <InputText
                id="emailField"
                type="text"
                v-model="email"
                :invalid="emailInvalid"
                class="w-full"
                :placeholder="$_('name@anotherdomain.com')"
            />
            <div v-if="emailInvalid" class="text-left text-red-500">
                <small>{{  $_('You need to enter a valid e-mail address.') }}</small>
            </div>
        </div>
        <div v-if="type=='domain'">
            <label for="domainField" class="block text-left font-semibold text-gray-700 mb-1 mt-3">{{ $_('Sender domain to be trusted') }}:</label>
            <InputText
                id="domainField"
                type="text"
                v-model="domain"
                :invalid="domainInvalid"
                class="w-full"
                :placeholder="$_('anotherdomain.com')"
            />
            <div v-if="domainInvalid" class="text-left text-red-500">
                <small>{{  $_('You need to enter a valid domain name.') }}</small>
            </div>
        </div>
        <div v-if="type=='ip'">
            <label for="ipField" class="block text-left font-semibold text-gray-700 mb-1 mt-3">{{ $_('Server IP address to be trusted') }}:</label>
            <InputText
                id="ipField"
                type="text"
                v-model="ip"
                :invalid="ipInvalid"
                class="w-full"
                :placeholder="$_('192.168.11.22')"
            />
            <div v-if="ipInvalid" class="text-left text-red-500">
                <small>{{  $_('You need to enter a valid IP address.') }}</small>
            </div>
        </div>
        <div class="flex justify-content-between gap-2 mt-4">
            <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"></Button>
            <Button type="button" :label="$_('Add trusted sender')" @click="action" :loading="loading"></Button>
        </div>
    </Dialog>
    </div>
</template>
    
<script>
    export default {
      name: "WhitelistAddDialog",
      data() {
        return {
          visible: false,
          type: "email",
          source: "",
          sourceInvalid: false,
          email: "",
          emailInvalid: false,
          domain: "",
          domainInvalid: false,
          ip: "",
          ipInvalid: false,
          loading: false,
          error: "",
        }
      },
      computed: {
        domains() {
            return this.$store.getters["domains"].map((item) => { return item.domain; });
        },
      },
      methods: {
        show(source = "") {
            this.source = source;
            this.sourceInvalid = false;
            this.type = "email";
            this.email = "";
            this.emailInvalid = false;
            this.domain = "";
            this.domainInvalid = false;
            this.ip = "";
            this.ipInvalid = false;
            this.error = "";
            this.visible = true;
        },
        hide() {
            this.visible = false;
        },
        action() {
            this.sourceInvalid = false;
            this.emailInvalid = false;
            this.domainInvalid = false;
            this.ipInvalid = false;
            this.error = "";

            if(!this.source.length) {
                this.sourceInvalid = true;
                return;
            }

            var sender = null;
            if(this.type == "email") {
                sender = this.email.trim().toLowerCase();
                if(!sender.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
                    this.emailInvalid = true;
                    return;
                }
            } else if(this.type == "domain") {
                sender = this.domain.trim().toLowerCase();
                if(!sender.match(/^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/)) {
                    this.domainInvalid = true;
                    return;
                }
            } else if(this.type == "ip") {
                sender = this.ip.trim().toLowerCase();
                if(!sender.match(/^(\d{1,3}\.){3}\d{1,3}$/) && !sender.match(/^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/)) {
                    this.ipInvalid = true;
                    return;
                }
            }

            var found = this.$store.getters["emailWhitelists"].find((item) => item.sender == sender && item.domain == this.source);
            if(found) {
                this.error = this.$_("You have already added this sender.");
                return;
            }
            var found2 = this.$store.getters["emailBlacklists"].find((item) => item.sender == sender && item.domain == this.source);
            if(found2) {
                this.error = this.$_("This sender is already blacklisted.");
                return;
            }
            
            this.loading = true;
            this.$store.dispatch("emailWhitelistAdd", { 
                domain: this.source,
                sender: sender,
            }).then(() => {
                this.loading = false;
                this.visible = false;
                this.$refs["toast"].add({
                    severity: 'success', 
                    summary: this.$_("Operation performed successfully."), 
                    detail: this.$_("Trusted sender was added") + ": " + sender,
                    life: 3000
                });
            }).catch((error) => {
                switch(error.code) {
                    default:
                        this.error = this.$_("Unknown error.");
                }
                this.loading = false;
            });
        }
      },
    }
    </script>