<template>
    <div>
    <Toast ref="toast"/>
    <Dialog 
        v-model:visible="visible" 
        modal
        :draggable="false"
        :header="$_('Rename the file')"
        class="w-full lg:w-4 mx-2"
        >
        <div v-if="error">
            <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
        </div>
        <div>
            <label for="nameField" class="block text-left font-semibold text-gray-700 mb-1">{{ $_('File name') }}:</label>
            <InputText
                id="nameField"
                type="text"
                v-model="name"
                :invalid="nameInvalid"
                class="w-full"
                ref="input"
            />
            <div v-if="nameInvalid" class="text-left text-red-500">
                <small>{{  $_('You need to enter a valid file name.') }}</small>
            </div>
        </div>
        <div class="flex justify-content-between gap-2 mt-4">
            <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"></Button>
            <Button type="button" :label="$_('Rename file')" @click="action" :loading="loading"></Button>
        </div>
    </Dialog>
    </div>
</template>
    
<script>
    
    const requester = require("../../libraries/api.js");

    export default {
      name: "FileRenameDialog",
      data() {
        return {
          file: null,
          root: "",
          fileNames: [],
          visible: false,
          name: "",
          nameInvalid: false,
          loading: false,
          error: "",
        }
      },
      methods: {
        show(file, root, fileNames) {
            this.file = file;
            this.root = root;
            this.fileNames = fileNames;
            this.name = file.name;
            this.nameInvalid = false;
            this.visible = true;
            this.error = "";
            this.$nextTick(() => {
                this.$refs["input"].$el.focus();
            });
        },
        hide() {
            this.visible = false;
        },
        action() {
            this.nameInvalid = false;
            this.error = "";
            
            if(!this.name.trim().length || this.name.match(/[/<>:"|?*\\]/)) {
                this.nameInvalid = true;
                return;
            }
            this.fileNames.forEach((name) => {
                if(name == this.name.trim() && this.file.name != this.name.trim()) {
                    this.error = this.$_('File or directory already exists.')
                }
            });
            if(this.error) return;
    
            this.loading = true;
            requester.post("/file/move", {
                from: this.root + "/" + this.file.name,
                to: this.root + "/" + this.name.trim(),
            }).then(() => {
                this.loading = false;
                this.visible = false;
                this.$refs["toast"].add({
                    severity: 'success', 
                    summary: this.$_("Operation performed successfully."), 
                    detail: this.$_("File was renamed") + ": " + this.name.trim(), 
                    life: 3000
                });
                this.$emit("renamed", this.name.trim());
            }).catch((error) => {
                switch(error.code) {
                    default:
                        this.error = this.$_("Unknown error.");
                }
                this.loading = false;
            });
        }
      },
      watch: {
        domain() { this.domainInvalid = false; },
        added(value) {
            if(!value && this.dontShow) {
                this.$store.dispatch("displaySettings", {
                   hideDomainConfig: true,
                   hideDatabaseConfig: this.$store.getters["hideDatabaseConfig"],
                   hideEmailConfig: this.$store.getters["hideEmailConfig"],
                });
            }
        }
      }
    }
    </script>