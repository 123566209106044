<template>
<div>
<Toast ref="toast"/>
<Dialog 
    v-model:visible="visible" 
    modal
    :draggable="false"
    :header="$_('Remove the domain')"
    class="w-full lg:w-4 mx-2"
    >
    <div v-if="error">
        <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
    </div>
    <div v-else>
        <Message severity="info" class="mt-0" :closable="false">
            {{  $_('The website and all e-mail messages in this domain will be irrevocably deleted!') }}
        </Message>
    </div>
    <div>
        <label class="block text-left font-semibold text-gray-700 mb-2">{{ $_('Domain name') }}:</label>
        <div>
            {{ domain }}
        </div>
    </div>
    <div class="mt-3">
        <div class="flex align-items-center">
            <Checkbox v-model="remove" inputId="removeDomain" name="remove" value="true"></Checkbox>
            <label for="removeDomain" class="ml-2">{{  $_('Yes, remove the domain and all its data.') }}</label>
        </div>
    </div>
    <div class="flex justify-content-between gap-2 mt-4">
        <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"></Button>
        <Button type="button" :label="$_('Remove domain')" severity="danger" :disabled="!remove.length" @click="action" :loading="loading"></Button>
    </div>
</Dialog>
</div>
</template>

<script>
export default {
  name: "DomainRemoveDialog",
  data() {
    return {
      visible: false,
      domain: "",
      remove: [],
      loading: false,
      error: "",
    }
  },
  methods: {
    show(domain) {
        this.domain = domain;
        this.remove = [];
        this.error = "";
        this.visible = true;
    },
    hide() {
        this.visible = false;
    },
    action() {
        this.error = "";
        
        this.loading = true;
        this.$store.dispatch("domainRemove", { 
            domain: this.domain
        }).then(() => {
            this.loading = false;
            this.visible = false;
            this.$refs["toast"].add({
                severity: 'success', 
                summary: this.$_("Operation performed successfully."), 
                detail: this.$_("Domain was removed") + ": " + this.domain, 
                life: 3000
            });
        }).catch((error) => {
            switch(error.code) {
                default:
                    this.error = this.$_("Unknown error.");
            }
            this.loading = false;
        });
    }
  },
}
</script>