<template>
    <div>
    <Toast ref="toast"/>
    <Dialog 
        v-model:visible="visible" 
        modal
        :draggable="false"
        :header="$_('Create a redirection')"
        class="w-full lg:w-4 mx-2"
        >
        <div v-if="error">
            <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
        </div>
        <div>
            <label class="block text-left font-semibold text-gray-700 mb-1">{{ $_('Domain name') }}:</label>
            <Dropdown
                v-model="domain"
                :options="domains"
                :invalid="domainInvalid"
                :placeholder="$_('Select a domain')"
                class="w-full"
            />
            <div v-if="domainInvalid" class="text-left text-red-500">
                <small>{{  $_('You need to select the domain name.') }}</small>
            </div>
        </div>
        <label class="block text-left font-semibold text-gray-700 mb-2 mt-3">{{ $_('Redirection type') }}:</label>
        <div class="flex align-items-center">
            <RadioButton v-model="type" inputId="typeNormal" name="type" value="normal"/>
            <label for="typeNormal" class="ml-2">{{ $_('Redirect to a different website') }}</label>
        </div>
        <div class="flex align-items-center mt-2">
            <RadioButton v-model="type" inputId="typeHttp" name="type" value="http"/>
            <label for="typeHttp" class="ml-2">{{ $_('Redirect HTTP traffic to HTTPS') }}</label>
        </div>
        <div v-if="type == 'normal'">
            <label for="destinationField" class="block text-left font-semibold text-gray-700 mb-1 mt-3">{{ $_('Destination website') }}:</label>
            <InputText
                id="destinationField"
                type="text"
                v-model="destination"
                :invalid="destinationInvalid"
                class="w-full"
                :placeholder="$_('http://www.otherdomain.com')"
            />
            <div v-if="destinationInvalid" class="text-left text-red-500">
                <small>{{  $_('You need to enter a valid website address.') }}</small>
            </div>
        </div>
        <Accordion v-model:activeIndex="accordion" ref="accordion">
            <AccordionTab :header="$_('Advanced options')">
                <div class="flex align-items-center">
                    <RadioButton v-model="code" inputId="code301" name="code" :value="301"/>
                    <label for="code301" class="ml-2">{{ $_('Permanent redirect (301)') }}</label>
                </div>
                <div class="flex align-items-center mt-2">
                    <RadioButton v-model="code" inputId="code302" name="code" :value="302"/>
                    <label for="code302" class="ml-2">{{ $_('Temporary redirect (302)') }}</label>
                </div>
                <label for="folderField" class="block text-left font-semibold text-gray-700 mb-1 mt-3">{{ $_('Redirection subdirectory') }}:</label>
                <InputText
                    id="folderField"
                    type="text"
                    v-model="folder"
                    :invalid="folderInvalid"
                    class="w-full"
                    :placeholder="$_('/myscript')"
                    ref="input"
                />
                <div v-if="folderInvalid" class="text-left text-red-500">
                    <small>{{  $_('You need to enter a valid subdirectory name.') }}</small>
                </div>
            </AccordionTab>
        </Accordion>
        <div class="flex justify-content-between gap-2 mt-4">
            <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"></Button>
            <Button type="button" :label="$_('Create redirection')" @click="action" :loading="loading"></Button>
        </div>
    </Dialog>
    </div>
</template>
    
<script>
    export default {
      name: "RedirectCreateDialog",
      data() {
        return {
          visible: false,
          domain: "",
          domainInvalid: false,
          type: "normal",
          destination: "",
          destinationInvalid: false,
          folder: "",
          folderInvalid: false,
          loading: false,
          error: "",
          accordion: 0,
          code: 301,
        }
      },
      computed: {
        domains() {
            return this.$store.getters["domains"].map(item => item.domain);
        },
      },
      methods: {
        show(domain) {
            this.domain = domain;
            this.domainInvalid = false;
            this.type = "normal",
            this.destination = "";
            this.destinationInvalid = false;
            this.folder = "/";
            this.folderInvalid = false;
            this.error = "";
            this.accordion = null;
            this.visible = true;
            this.code = 301;
        },
        hide() {
            this.visible = false;
        },
        action() {
            this.error = "";

            this.domainInvalid = false;
            this.destinationInvalid = false;
            this.folderInvalid = false;

            if(!this.domain) {
                this.domainInvalid = true;
                return;
            }

            var destination;
            if(this.type != "http") {
                if(!this.destination.trim().length || !this.destination.match(/\./)) {
                    this.destinationInvalid = true;
                    return;
                }
                destination = this.destination.trim();
                if(!destination.match(/^https?:\/\//)) destination = "http://" + destination;
            }

            if(!this.folder.trim().length || this.folder.trim().match(/[^a-zA-Z0-9_./-]/) || this.folder.match(/\/\//))  {
                this.accordion = 0;
                this.folderInvalid = true;
                return;
            }
            var folder = this.folder.trim();
            if(!folder.match(/^\//)) folder = "/" + folder;
            if(!folder.match(/\/$/)) folder = folder + "/";

            if(this.type == "http") {
                destination = "https://" + this.domain + folder;
            }

            if(this.$store.getters["redirects"].filter((item) => {
                return item.domain == this.domain && item.directory == this.folder;
            }).length) {
                this.error = this.$_("You have already redirected this address.")
                return;
            }

            this.loading = true;
            this.$store.dispatch("redirectCreate", { 
                domain: this.domain,
                destination: destination,
                directory: folder,
                type: this.type,
                code: this.code,
            }).then(() => {
                this.loading = false;
                this.visible = false;
                this.$refs["toast"].add({
                    severity: 'success', 
                    summary: this.$_("Operation performed successfully."), 
                    detail: this.$_("Redirection was created") + ": http://" + this.domain + folder, 
                    life: 3000
                });
            }).catch((error) => {
                switch(error.code) {
                    default:
                        this.error = this.$_("Unknown error.");
                }
                this.loading = false;
            });
        }
      },
      watch: {
        accordion(value) {
            if(value === 0) 
                this.$refs["accordion"].$el.getElementsByClassName("p-toggleable-content")[0].style.display = "block";
        },
        domain() { this.domainInvalid = false; },
        folder() { this.folderInvalid = false; },
        destination() { this.domainInvalid = false; },
      }
    }
    </script>