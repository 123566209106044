<template>
<div>
<Toast ref="toast"/>
<Dialog 
    v-model:visible="visible" 
    modal
    :draggable="false"
    :header="$_('Add a new domain')"
    class="w-full lg:w-4 mx-2"
    >
    <div v-if="error">
        <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
    </div>
    <div>
        <label for="domainField" class="block text-left font-semibold text-gray-700 mb-1">{{ $_('Domain name') }}:</label>
        <InputText
            id="domainField"
            type="text"
            v-model="domain"
            :invalid="domainInvalid"
            class="w-full"
            :placeholder="$_('yourdomain.com')"
            ref="input"
        />
        <div v-if="domainInvalid" class="text-left text-red-500">
            <small>{{  $_('You need to enter a valid domain name.') }}</small>
        </div>
    </div>
    <div class="flex justify-content-between gap-2 mt-4">
        <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"></Button>
        <Button type="button" :label="$_('Add domain')" @click="action" :loading="loading"></Button>
    </div>
</Dialog>
<Dialog 
    v-model:visible="added" 
    modal
    :draggable="false"
    :header="$_('Domain added')"
    class="w-full lg:w-4 mx-2"
    >
    <Message severity="success" class="mt-0" :closable="false">
        {{ $_('Your domain has been added.') }}
    </Message>
    <div>
        <label class="block text-left font-semibold text-gray-700 mb-3">{{ $_('DNS servers') }}:</label>
        {{ $_('You need to point your domain to DNS servers:') }}
        <table cellpadding="0" cellspacing="0" class="mt-2 mb-2">
            <tr v-for="ns in $store.getters['nameservers']" :key="ns">
                <td class="py-2">{{ ns }}</td>
                <td>
                    <CopyIcon :text="ns" :message="$_('Nameserver name copied to clipboard.')"/>
                </td>
            </tr>
        </table>
        {{ $_('After changing the DNS servers, it may take up to 24 hours for the domain to start working.') }}
    </div>
    <div class="flex align-items-center mt-2">
        <Checkbox v-model="dontShow" inputId="dontShow" name="dontShow" value="true"></Checkbox>
        <label for="dontShow" class="ml-2">{{  $_('Don\'t show this information in the future.') }}</label>
    </div>
    <div class="flex justify-content-between gap-2 mt-4">
        <Button type="button" :label="$_('Close')" severity="secondary" @click="added = false"></Button>
        <Button type="button" :label="$_('Add another')" icon="pi pi-plus" @click="show"></Button>
    </div>
</Dialog>
</div>
</template>

<script>
import CopyIcon from "../../components/CopyIcon.vue";

export default {
  name: "DomainAddDialog",
  components: {
    CopyIcon,
  },
  data() {
    return {
      visible: false,
      added: false,
      dontShow: false,
      domain: "",
      domainInvalid: false,
      loading: false,
      error: "",
    }
  },
  methods: {
    show() {
        this.domain = "";
        this.domainInvalid = false;
        this.visible = true;
        this.added = false;
        this.error = "";
        this.$nextTick(() => {
            this.$refs["input"].$el.focus();
        });
    },
    hide() {
        this.visible = false;
    },
    action() {
        this.domainInvalid = false;
        this.error = "";
        
        if(!this.domain.trim().length || 
            (!this.domain.trim().toLowerCase().match(/^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/) 
            && !this.domain.trim().toLowerCase().match(/^xn--([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/))
        ) {
            this.domainInvalid = true;
            return;
        }
        this.$store.getters["domains"].forEach((item) => {
            if(item.domain.toLowerCase() == this.domain.trim().toLowerCase()) {
                this.error = this.$_('You have already added this domain.')
            }
        });
        if(this.error) return;

        this.loading = true;
        this.$store.dispatch("domainAdd", { 
            domain: this.domain.toLowerCase().trim()
        }).then(() => {
            this.loading = false;
            this.added = !this.$store.getters["hideDomainConfig"];
            this.visible = false;
            this.$refs["toast"].add({
                severity: 'success', 
                summary: this.$_("Operation performed successfully."), 
                detail: this.$_("Domain was added") + ": " + this.domain.toLowerCase().trim(), 
                life: 3000
            });
        }).catch((error) => {
            switch(error.code) {
                case 409:
                    this.error = this.$_('Another user has added this domain.');
                    break;
                case 406:
                    this.error = this.$_('This domain name is invalid.');
                    break;
                default:
                    this.error = this.$_("Unknown error.");
            }
            this.loading = false;
        });
    }
  },
  watch: {
    domain() { this.domainInvalid = false; },
    added(value) {
        if(!value && this.dontShow) {
            this.$store.dispatch("displaySettings", {
               hideDomainConfig: true,
               hideDatabaseConfig: this.$store.getters["hideDatabaseConfig"],
               hideEmailConfig: this.$store.getters["hideEmailConfig"],
            });
        }
    }
  }
}
</script>