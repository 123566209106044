<template>
    <div>
    <Toast ref="toast"/>
    <Dialog 
        v-model:visible="visible" 
        modal
        :draggable="false"
        :header="$_('Create an e-mail account')"
        class="w-full lg:w-5 mx-2"
        >
        <div v-if="error">
            <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
        </div>
        <div>
            <label for="nameField" class="block text-left font-semibold text-gray-700 mb-1">{{ $_('E-mail address') }}:</label>
            <div class="flex flex-column sm:flex-row email-group">
                <InputText
                    id="nameField"
                    type="text"
                    v-model="name"
                    :invalid="nameInvalid"
                    class="w-full sm:w-6"
                    :placeholder="$_('yourname')"
                    ref="input"
                />
                <Dropdown
                    class="mt-1 sm:mt-0 ml-0 w-full sm:w-6"
                    v-model="domain"
                    :options="domains"
                    :placeholder="$_('@yourdomain.com')"
                    optionLabel="text"
                    optionValue="value"
                />
            </div>
            <div v-if="domainInvalid" class="text-left text-red-500">
                <small>{{  $_('You need to select a domain.') }}</small>
            </div>
            <div v-if="nameInvalid" class="text-left text-red-500">
                <small>{{  $_('You need to enter a valid e-mail address.') }}</small>
            </div>
        </div>
        <div class="mt-3">
            <label for="quotaField" class="block text-left font-semibold text-gray-700 mb-1">{{ $_('Account capacity') }}:</label>
            <div class="flex">
                <InputGroup class="w-full sm:w-6">
                    <InputNumber
                        id="quotaField"
                        type="text"
                        v-model="quota"
                        :invalid="quotaInvalid"
                        :minFractionDigits="0"
                        :useGrouping="false"
                        :min="minQuota"
                        :max="maxQuota"
                    />
                    <InputGroupAddon>MB</InputGroupAddon>
                </InputGroup>
                <Slider
                    class="w-6 ml-2 mt-3 hidden sm:block"
                    v-model="quota"
                    :min="minQuota"
                    :max="maxQuota"
                />
            </div>
            <div v-if="quotaInvalid" class="text-left text-red-500">
                <small>{{  $_('Choose a value between %1 and %2 MB.').replace('%1', minQuota).replace('%2', maxQuota) }}</small>
            </div>
        </div>
        <div class="flex flex-column sm:flex-row mt-1 sm:align-items-center">
            <div class="sm:mr-2 mb-2 sm:mb-0" v-html="$_('Maximum account capacity is <strong>%1 MB</strong>.').replace('%1', this.maxQuota)"></div>
            <Button
                :label="$_('Need more capacity?')"
                severity="secondary"
                icon="pi pi-external-link"
                iconPos="right"
                size="small"
                @click="upgradePackage"
            />
        </div>
        <div class="flex flex-column sm:flex-row">
            <div class="mt-3 w-full sm:w-6">
                <label for="passwordField" class="block text-left font-medium mb-1">{{ $_('Account password') }}:</label>
                <InputGroup class="w-full">
                    <InputText
                        id="passwordField"
                        :type="showPassword ? 'text' : 'password'"
                        v-model="password"
                        :invalid="passwordInvalid"
                        ref="password"
                    />
                    <Button severity="secondary" icon="pi pi-eye" @click="showPassword=true" v-if="!showPassword"/>
                    <Button severity="secondary" icon="pi pi-eye-slash" @click="showPassword=false" v-if="showPassword"/>
                </InputGroup>
            </div>
            <div class="mt-2 sm:mt-0 ml-0 sm:ml-2 w-full sm:w-6">
                <label for="password2Field" class="block text-left font-medium mb-1 mt-3">{{ $_('Repeat the password') }}:</label>
                <InputGroup class="w-full">
                    <InputText
                        id="password2Field"
                        :type="showPassword ? 'text' : 'password'"
                        v-model="password2"
                        :invalid="password2Invalid"
                    />
                    <Button severity="secondary" icon="pi pi-eye" @click="showPassword=true" v-if="!showPassword"/>
                    <Button severity="secondary" icon="pi pi-eye-slash" @click="showPassword=false" v-if="showPassword"/>
                </InputGroup>
            </div>
        </div>
        <div v-if="passwordInvalid" class="text-left text-red-500">
            <small>{{  $_('Password must be at least 8 characters long.') }}</small>
        </div>
        <div v-else-if="password2Invalid" class="text-left text-red-500">
                <small>{{  $_('The passwords do not match.') }}</small>
            </div>
        <div class="mt-2 sm:mt-1">
            <Button severity="secondary" icon="pi pi-pencil" :label="$_('Generate password')" size="small" @click="generatePassword"/>
        </div>
        <div class="flex justify-content-between gap-2 mt-4">
            <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"></Button>
            <Button type="button" :label="$_('Create account')" @click="action" :loading="loading"></Button>
        </div>
    </Dialog>
    <Dialog 
        v-model:visible="added" 
        modal
        :draggable="false"
        :header="$_('E-mail account created')"
        class="w-full lg:w-4 mx-2"
        >
        <Message severity="success" class="mt-0" :closable="false">
            {{ $_('E-mail account has been created.') }}
        </Message>
        <label class="block text-left font-semibold text-gray-700">{{ $_('Account login') }}:</label>
        <div class="my-2">
            {{  name.trim().toLowerCase() }}@{{ domain }}
            <CopyIcon :text="name.trim().toLowerCase()+'@'+domain" :message="$_('Account login copied to clipboard.')"/>
        </div>
        <label class="block text-left font-semibold text-gray-700 mt-3">{{ $_('Account password') }}:</label>
        <div class="my-2">
            <span v-if="showPassword">{{ password }}</span>
            <span v-else>&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;</span>
            <CopyIcon :text="password" :message="$_('Account password copied to clipboard.')"/>
        </div>
        <table cellpadding="0" cellspacing="0" class="mt-3">
            <tr>
                <td colspan="3" class="py-1">
                    <label class="block text-left font-semibold text-gray-700">{{ $_('IMAP server') }}:</label>
                </td>
            </tr>
            <tr>
                <td class="py-1">
                    {{  $store.getters["imapHost"] }}
                </td>
                <td>
                    <CopyIcon :text="$store.getters['imapHost']" :message="$_('IMAP server name copied to clipboard.')"/>
                </td>
                <td class="pl-3">
                    {{  $_('Port') }}:
                    {{  $store.getters["imapPort"] }}
                    <span v-if="$store.getters['imapPort'] != 993">(STARTTLS)</span>
                    <span v-if="$store.getters['imapPort'] == 993">(SSL/TLS)</span>
                </td>
            </tr>
            <tr>
                <td colspan="3" class="pb-1 pt-3">
                    <label class="block text-left font-semibold text-gray-700">{{ $_('SMTP server') }}:</label>
                </td>
            </tr>
            <tr>
                <td class="py-1">
                    {{  $store.getters["smtpHost"] }}
                </td>
                <td>
                    <CopyIcon :text="$store.getters['smtpHost']" :message="$_('SMTP server name copied to clipboard.')"/>
                </td>
                <td class="pl-3">
                    {{  $_('Port') }}:
                    {{  $store.getters["smtpPort"] }}
                    <span v-if="$store.getters['smtpPort'] == 465">(SSL/TLS)</span>
                    <span v-if="$store.getters['smtpPort'] == 587">(STARTTLS)</span>
                </td>
            </tr>
        </table>
        <div class="flex align-items-center mt-3">
            <Checkbox v-model="dontShow" inputId="dontShow" name="dontShow" value="true"></Checkbox>
            <label for="dontShow" class="ml-2">{{  $_('Don\'t show this information in the future.') }}</label>
        </div>
        <div class="flex justify-content-between gap-2 mt-4">
            <Button type="button" :label="$_('Close')" severity="secondary" @click="added = false"></Button>
            <Button type="button" :label="$_('Create another')" icon="pi pi-plus" @click="show"></Button>
        </div>
    </Dialog>
    </div>
</template>
    
<script>
import CopyIcon from "../../components/CopyIcon.vue";
    
import { password } from "../../mixins/password.js";
import { upgrade } from "../../mixins/upgrade.js";

    export default {
      name: "AddressCreateDialog",
      components: {
        CopyIcon,
      },
      mixins: [ password, upgrade ],
      data() {
        return {
          visible: false,
          added: false,
          dontShow: false,
          domain: "",
          name: "",
          nameInvalid: false,
          domainInvalid: false,
          quotaInvalid: false,
          password: "",
          passwordInvalid: false,
          password2: "",
          password2Invalid: false,
          showPassword: false,
          loading: false,
          error: "",
          quota: 100,
          minQuota: 1,
          maxQuota: 1024,
        }
      },
      computed: {
        domains() {
            return this.$store.getters["domains"].map((item) => { return {
                value: item.domain,
                text: "@" + item.domain
            }});
        },
      },
      methods: {
        show(domain) {
            this.domain = domain ?? "";
            this.name = "";
            this.nameInvalid = false;
            this.domainInvalid = false;
            this.quota = 100;
            this.quotaInvalid = false;
            this.password = "";
            this.passwordInvalid = false;
            this.password2 = "";
            this.password2Invalid = false;
            this.showPassword = false;
            this.maxQuota = parseInt(this.$store.getters["emailLimit"]/1024/1024);
            if(this.quota > this.maxQuota) this.quota = this.maxQuota;
            this.visible = true;
            this.added = false;
            this.error = "";
            this.$nextTick(() => {
                this.$refs["input"].$el.focus();
            });
        },
        hide() {
            this.visible = false;
        },
        action() {
            this.nameInvalid = false;
            this.domainInvalid = false;
            this.quotaInvalid = false;
            this.passwordInvalid = false;
            this.password2Invalid = false;
            this.error = "";
            
            if(!this.domain.length) {
                this.domainInvalid = true;
                return;
            }
            if(!this.name.trim().length || this.name.trim().match(/[^a-zA-Z0-9_.-]/) || this.name.trim().match(/^[^a-z]/) || this.name.trim().length > 40) {
                this.nameInvalid = true;
                return;
            }
            if(this.quota < this.minQuota || this.quota > this.maxQuota) {
                this.quotaInvalid = true;
                return;
            }            
            if(this.password.trim().length < 8) {
                this.passwordInvalid = true;
                return;
            }            
            if(this.password2.trim() != this.password.trim()) {
                this.password2Invalid = true;
                return;
            }
            this.$store.getters["emailAccounts"].forEach((item) => {
                if(item.domain == this.domain && item.name == this.name) {
                    this.error = this.$_('You have already added this e-mail account.')
                }
            });
            if(this.error) return;
    
            this.loading = true;
            this.$store.dispatch("emailAccountCreate", { 
                domain: this.domain,
                name: this.name.trim().toLowerCase(),
                quota: this.quota * 1024 * 1024,
                password: this.password.trim(),
            }).then(() => {
                this.loading = false;
                this.added = !this.$store.getters["hideEmailConfig"];
                this.visible = false;
                this.$refs["toast"].add({
                    severity: 'success', 
                    summary: this.$_("Operation performed successfully."), 
                    detail: this.$_("E-mail address was created") + ": " + this.name.toLowerCase().trim() + '@' + this.domain, 
                    life: 3000
                });
            }).catch((error) => {
                switch(error.code) {
                    default:
                        this.error = this.$_("Unknown error.");
                }
                this.loading = false;
            });
        }
      },
      watch: {
        name() { this.nameInvalid = false; },
        domain() { this.domainInvalid = false; },
        quota() { this.quotaInvalid = false; },
        password() { this.passwordInvalid = false; },
        password2() { this.password2Invalid = false; },
        added(value) {
        if(!value && this.dontShow) {
            this.$store.dispatch("displaySettings", {
               hideDomainConfig: this.$store.getters["hideDomainConfig"],
               hideDatabaseConfig: this.$store.getters["hideDatabaseConfig"],
               hideEmailConfig: true,
            });
        }
    }
      }
    }
    </script>

<style>
.email-group .p-inputtext {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.email-group .p-dropdown {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
}

</style>