<template>
    <div>
    <Toast ref="toast"/>
    <Dialog 
        v-model:visible="visible" 
        modal
        :draggable="false"
        :header="$_('Modify the e-mail alias')"
        class="w-full lg:w-5 mx-2"
        >
        <div v-if="error">
            <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
        </div>
        <div>
            <label class="block text-left font-semibold text-gray-700 mb-2">{{ $_('E-mail address') }}:</label>
            <div>
                {{ name + '@' + domain }}
            </div>
        </div>
        <div class="mt-3">
            <label class="block text-left font-semibold text-gray-700">{{ $_('Destination addresses') }}:</label>
            <div v-for="(email, index) in destinations" :key="index" class="mt-1">
                <InputGroup class="w-full">
                    <InputText
                        type="text"
                        v-model="destinations[index]"
                        :invalid="destinationsInvalid[index]"
                        class="w-full"
                        :placeholder="$_('name@anotherdomain.com')"
                    />
                    <Button severity="secondary" icon="pi pi-trash" @click="deleteItem(index)" :disabled="destinations.length==1"/>
                </InputGroup>
                <div v-if="destinationsInvalid[index]" class="text-left text-red-500">
                    <small>{{  $_('You need to enter a valid e-mail address.') }}</small>
                </div>
            </div>
            <div class="mt-2">
                <Button severity="secondary" icon="pi pi-plus" :label="$_('Add destination')" size="small" @click="addItem()"/>
            </div>
        </div>
        <div class="flex justify-content-between gap-2 mt-4">
            <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"></Button>
            <Button type="button" :label="$_('Modify alias')" @click="action" :loading="loading"></Button>
        </div>
    </Dialog>
    </div>
</template>
    
<script>
    
    export default {
      name: "AliasModifyDialog",
      data() {
        return {
          visible: false,
          domain: "",
          name: "",
          destinations: [""],
          destinationsInvalid: [false],
          loading: false,
          error: "",
        }
      },
      methods: {
        show(domain, name) {
            this.domain = domain;
            this.name = name;
            this.destinations = this.$store.getters["emailAliases"].find((item) =>
                item.domain = domain && item.name == name
            ).destination.split(",");
            this.destinationsInvalid = [];
            for(let i in this.destinations) this.destinationsInvalid[i] = false;
            this.visible = true;
            this.error = "";
        },
        hide() {
            this.visible = false;
        },
        addItem() {
            this.destinations.push("");
            this.destinationsInvalid.push(false);
        },
        deleteItem(index) {
            this.destinations.splice(index, 1);
            this.destinationsInvalid.splice(index, 1);
        },
        action() {
            for(let i in this.destinations) this.destinationsInvalid[i] = false;
            this.error = "";
            
            var empty = true;
            for(let i in this.destinations) {
                if(this.destinations[i].trim().length > 0) empty = false;
            }
            if(empty) {
                this.destinationsInvalid[0] = true;
                return;
            }
            var destinations = [];
            for(let i in this.destinations) {
                this.destinations[i].trim().toLowerCase()
                if(!this.destinations[i].trim().toLowerCase().match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
                    this.destinationsInvalid[i] = true;
                    return;
                }
                if(this.destinations[i].trim().length > 0)
                    destinations.push(this.destinations[i].trim().toLowerCase());
            }
    
            this.loading = true;
            this.$store.dispatch("emailAliasModify", { 
                domain: this.domain,
                name: this.name.trim().toLowerCase(),
                destination: destinations.join(","),
            }).then(() => {
                this.loading = false;
                this.visible = false;
                this.$refs["toast"].add({
                    severity: 'success', 
                    summary: this.$_("Operation performed successfully."), 
                    detail: this.$_("E-mail alias was created") + ": " + this.name.toLowerCase().trim() + '@' + this.domain, 
                    life: 3000
                });
            }).catch((error) => {
                switch(error.code) {
                    default:
                        this.error = this.$_("Unknown error.");
                }
                this.loading = false;
            });
        }
      },
      watch: {
        name() { this.nameInvalid = false; },
        quota() { this.quotaInvalid = false; },
        password() { this.passwordInvalid = false; },
        password2() { this.password2Invalid = false; },
      }
    }
    </script>