<template>
    <div>
    <Toast ref="toast"/>
    <Dialog 
        v-model:visible="visible" 
        modal
        :draggable="false"
        :header="$_('Change the account capacity')"
        class="w-full lg:w-5 mx-2"
        >
        <div v-if="error">
            <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
        </div>
        <div>
            <label for="nameField" class="block text-left font-semibold text-gray-700 mb-2">{{ $_('E-mail address') }}:</label>
            <div>
                {{ name + '@' + domain }}
            </div>
        </div>
        <div class="mt-3">
            <label for="quotaField" class="block text-left font-semibold text-gray-700 mb-1">{{ $_('Account capacity') }}:</label>
            <div class="flex">
                <InputGroup class="w-full sm:w-6">
                    <InputNumber
                        id="quotaField"
                        type="text"
                        v-model="quota"
                        :invalid="quotaInvalid"
                        :minFractionDigits="0"
                        :useGrouping="false"
                        :min="minQuota"
                        :max="maxQuota"
                        ref="input"
                    />
                    <InputGroupAddon>MB</InputGroupAddon>
                </InputGroup>
                <Slider
                    class="w-6 ml-2 mt-3 hidden sm:block"
                    v-model="quota"
                    :min="minQuota"
                    :max="maxQuota"
                />
            </div>
            <div v-if="quotaInvalid" class="text-left text-red-500">
                <small>{{  $_('Choose a value between %1 and %2 MB.').replace('%1', minQuota).replace('%2', maxQuota) }}</small>
            </div>
        </div>
        <div class="flex flex-column sm:flex-row mt-3 sm:align-items-center">
            <div class="sm:mr-2 mb-2 sm:mb-0" v-html="$_('Maximum account capacity is <strong>%1 MB</strong>.').replace('%1', this.maxQuota)"></div>
            <Button
                :label="$_('Need more capacity?')"
                severity="secondary"
                icon="pi pi-external-link"
                iconPos="right"
                size="small"
                @click="upgradePackage"
            />
        </div>
        <div class="flex justify-content-between gap-2 mt-4">
            <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"></Button>
            <Button type="button" :label="$_('Change capacity')" @click="action" :loading="loading"></Button>
        </div>
    </Dialog>
    </div>
</template>
    
<script>
    
    import { upgrade } from "../../mixins/upgrade.js";

    export default {
      name: "AccountQuotaDialog",
      mixins: [ upgrade ],
      data() {
        return {
          visible: false,
          domain: "",
          name: "",
          quota: 100,
          used: 0,
          quotaInvalid: false,
          loading: false,
          error: "",
          minQuota: 1,
          maxQuota: 1024,
        }
      },
      methods: {
        show(domain, name) {
            this.domain = domain;
            this.name = name;
            var item = this.$store.getters["emailAccounts"].find((item) => 
                item.domain == domain && item.name == name,
            );
            this.quota = parseInt(item["diskLimit"]/1024/1024);
            this.used = parseInt(Math.ceil(item["diskUsed"]/1024/1024));
            this.quotaInvalid = false;
            this.maxQuota = parseInt(this.$store.getters["emailLimit"]/1024/1024);
            this.visible = true;
            this.error = "";
            this.$nextTick(() => {
                this.$refs["input"].$el.focus();
            });
        },
        hide() {
            this.visible = false;
        },
        action() {
            this.quotaInvalid = false;
            this.error = "";
            
            if(this.quota < this.minQuota || this.quota > this.maxQuota) {
                this.quotaInvalid = true;
                return;
            }            
            if(this.quota < this.used) {
                this.error = this.$_('Account already uses %1 MB of space.').replace('%1', this.used);
                return;
            }            

            this.loading = true;
            this.$store.dispatch("emailAccountQuota", { 
                domain: this.domain,
                name: this.name,
                quota: this.quota*1024*1024,
            }).then(() => {
                this.loading = false;
                this.visible = false;
                this.$refs["toast"].add({
                    severity: 'success', 
                    summary: this.$_("Operation performed successfully."), 
                    detail: this.$_("Account capacity was changed to: %1 MB").replace("%1", this.quota), 
                    life: 3000
                });
            }).catch((error) => {
                switch(error.code) {
                    default:
                        this.error = this.$_("Unknown error.");
                }
                this.loading = false;
            });
        }
      },
      watch: {
        quota() { this.quotaInvalid = false; },
      }
    }
    </script>