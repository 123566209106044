
export const size = {

    methods: {
        showSize(size) {
            var unit, value;
            if(size < 1024000) {
                value = size / 1024;
                unit = "kB";
            } else if(size < 1048576000) {
                value = size / 1024 / 1024;
                unit = "MB";
            } else {
                value = size / 1024 / 1024 / 1024;
                unit = "GB";
            }
            if(value < 10)
                return parseFloat(value).toFixed(1) + " " + unit;
            else
                return parseInt(value).toString() + " " + unit;
        }
    }

}
  
  