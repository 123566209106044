
export const password = {

    data() {
        return {
            password: "",
            passwordInvalid: false,
            password2: "",
            password2Invalid: false,
            showPassword: false
        };
    },

    methods: {
        generatePassword() {
            var characters = "qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM1234567890";
            this.password = "";
            for(let i = 0; i < 10; i++) {
                this.password += characters[parseInt(Math.random()*characters.length)];
            }
            this.password2 = this.password;
            this.showPassword = true;
            navigator.clipboard.writeText(this.password).then(() => {
                this.$refs["toast"].add({
                    severity: 'info', 
                    summary: this.$_("Copied to clipboard."), 
                    detail: this.$_("Generated password copied to clipboard."), 
                    life: 3000
                });
            });
        },
    }
}
  
