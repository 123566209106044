<template>
    <div>
    <Toast ref="toast"/>
    <Dialog 
        v-model:visible="visible" 
        modal
        :draggable="false"
        :header="$_('Delete the file')"
        class="w-full lg:w-4 mx-2"
        >
        <div v-if="error">
            <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
        </div>
        <div>
            <label class="block text-left font-semibold text-gray-700 mb-2">{{ $_('File name') }}:</label>
            <div class="ellipsis">
                {{ file.name }}
            </div>
        </div>
        <div class="flex justify-content-between gap-2 mt-4">
            <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"></Button>
            <Button type="button" :label="$_('Delete file')" severity="danger" @click="action" :loading="loading"></Button>
        </div>
    </Dialog>
    </div>
</template>
    
<script>
    
    const requester = require("../../libraries/api.js");

    export default {
      name: "FileDeleteDialog",
      data() {
        return {
          file: null,
          root: "",
          visible: false,
          name: "",
          loading: false,
          error: "",
        }
      },
      methods: {
        show(file, root) {
            this.file = file;
            this.root = root;
            this.visible = true;
            this.error = "";
        },
        hide() {
            this.visible = false;
        },
        action() {
            this.error = "";
            
            this.loading = true;
            requester.post("/file/file/delete", {
                file: this.root + "/" + this.file.name,
            }).then(() => {
                this.loading = false;
                this.visible = false;
                this.$refs["toast"].add({
                    severity: 'success', 
                    summary: this.$_("Operation performed successfully."), 
                    detail: this.$_("File was deleted") + ": " + this.file.name, 
                    life: 3000
                });
                this.$emit("deleted", this.file.name);
            }).catch((error) => {
                switch(error.code) {
                    default:
                        this.error = this.$_("Unknown error.");
                }
                this.loading = false;
            });
        }
      },
      watch: {
        name() { this.nameInvalid = false; },
      }
    }
    </script>