<template>
<ViewRoot active="redirects">
  <DataTable :value="$store.getters['redirects']" v-if="$store.getters['redirects'].length">
    <Column :header="$_('Redirected address')">
      <template #body="slot">
        <div class="flex">
          <div class="ellipsis ellipsis-300">{{ 'http://' + slot.data.domain + slot.data.directory }}</div>
        </div>
      </template>
    </Column>
    <Column :header="$_('Destination website')" headerClass="hidden lg:block" bodyClass="hidden lg:block">
      <template #body="slot">
        <div class="flex">
          <div class="ellipsis ellipsis-250">{{ slot.data.destination }}</div>
        </div>
      </template>
    </Column>
    <Column>
      <template #body="slot">
        <div class="table-buttons hidden lg:block">
          <Button 
            :label="$_('Redirection operations')"
            severity="secondary"
            size="small"
            icon="pi pi-chevron-down"
            iconPos="right"
            @click="showMenu($event, slot.data.domain, slot.data.directory, true)"
          />
        </div>
        <a v-ripple class="cursor-pointer block lg:hidden text-700 p-ripple text-right" @click="showMenu($event, slot.data.domain, slot.data.directory, false)">
          <i class="pi pi-ellipsis-v"></i>
        </a>
      </template>
    </Column>
  </DataTable>
  <p v-else class="ml-3">
    {{  $_('You have not created any redirections yet.') }}
  </p>
  <div class="text-left mt-3 mx-3 flex flex-column sm:flex-row">
    <Button
      :label="$_('Create new redirection')"
      icon="pi pi-plus"
      @click="$refs['redirectCreate'].show()"
    />
  </div>
  <ContextMenu ref="contextMenu" :model="contextMenu"/>
  <RedirectCreateDialog ref="redirectCreate"/>
  <RedirectDeleteDialog ref="redirectDelete"/>
  <RedirectModifyDialog ref="redirectModify"/>
</ViewRoot>
</template>

<script>

import ViewRoot from "../components/ViewRoot.vue";

import RedirectCreateDialog from "../dialogs/redirect/RedirectCreateDialog.vue";
import RedirectDeleteDialog from "../dialogs/redirect/RedirectDeleteDialog.vue";
import RedirectModifyDialog from "../dialogs/redirect/RedirectModifyDialog.vue";

export default {
  name: "DomainsView",
  components: {
    ViewRoot,
    RedirectCreateDialog,
    RedirectDeleteDialog,
    RedirectModifyDialog,
  },
  mounted() {
    const redirect = this.$store.getters["redirect"];
    switch(redirect.action) {
      case "redirect":
        if(!this.$store.getters["redirects"].find((item) => item.domain == redirect.domain && item.directory == "/"))
          this.$refs["redirectCreate"].show(redirect.domain);
        else
          this.$refs["redirectModify"].show(redirect.domain, "/");
        break;
    }
    this.$store.commit("redirectClear");
    this.$store.dispatch("keepalive");
  },
  data() {
    return {
      contextDomain: "",
      contextDirectory: "",
    }
  },
  computed: {
    contextMenu() {
      return [
        { label: this.$_("Modify redirection"), icon: "pi pi-pencil", command: () => this.$refs["redirectModify"].show(this.contextDomain, this.contextDirectory) },
        { label: this.$_("Delete redirection"), icon: "pi pi-trash", command: () => this.$refs["redirectDelete"].show(this.contextDomain, this.contextDirectory) },
      ];
    },
  },
  methods: {
    showMenu(event, domain, directory) {
      this.contextDomain = domain;
      this.contextDirectory = directory;
      this.$refs["contextMenu"].show(event);
    },
  }
}

</script>

<style>
.table-buttons {
  margin: -0.75rem 0;
  text-align: right;
}
</style>