<template>
<div>
<Toast ref="toast"/>
<Dialog 
    v-model:visible="visible" 
    modal
    :draggable="false"
    :header="$_('Create a new database')"
    class="w-full lg:w-4 mx-2"
    >
    <div v-if="error">
        <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
    </div>
    <div class="mt-3">
        <label class="block text-left font-semibold text-gray-700 mb-1">{{ $_('Database type') }}:</label>
        <Dropdown v-model="type" :options="types" :placeholder="$_('Select database type')" class="w-full" optionLabel="label" optionValue="value">
        </Dropdown>
    </div>
    <div class="mt-3">
        <label for="nameField" class="block text-left font-semibold text-gray-700 mb-1">{{ $_('Database name') }}:</label>
        <InputGroup>
            <InputGroupAddon>{{ $store.getters["login"] + "_" }}</InputGroupAddon>
            <InputText
                id="nameField"
                type="text"
                v-model="name"
                :invalid="nameInvalid"
                :placeholder="$_('database')"
                ref="input"
            />
        </InputGroup>
        <div v-if="nameInvalid" class="text-left text-red-500">
            <small>{{  $_('You need to enter a valid database name.') }}</small>
        </div>
    </div>
    <div class="flex justify-content-between gap-2 mt-4">
        <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"></Button>
        <Button type="button" :label="$_('Create database')" @click="action" :loading="loading"></Button>
    </div>
</Dialog>
<Dialog 
    v-model:visible="added" 
    modal
    :draggable="false"
    :header="$_('Database created')"
    class="w-full lg:w-4 mx-2"
    >
    <Message severity="success" class="mt-0" :closable="false">
        {{ $_('Your database has been created.') }}
    </Message>
    <label class="block text-left font-semibold text-gray-700">{{ $_('Database name') }}:</label>
    <div class="my-2">
        {{ this.$store.getters['login'] + '_' + name.trim().toLowerCase() }}
        <CopyIcon :text="this.$store.getters['login'] + '_' + name.trim().toLowerCase()" :message="$_('Database name copied to clipboard.')"/>
    </div>
    <table cellpadding="0" cellspacing="0" v-if="type == 'mysql'">
        <tr>
            <td colspan="2" class="py-1">
                <label class="block text-left font-semibold text-gray-700">{{ $_('MySQL server') }}:</label>
            </td>
        </tr>
        <tr>
            <td class="py-1">
                {{  $store.getters["mysqlHost"] }}
            </td>
            <td>
                <CopyIcon :text="$store.getters['mysqlHost']" :message="$_('MySQL server name copied to clipboard.')"/>
            </td>
        </tr>
        <tr>
            <td colspan="2" class="pb-1 pt-2">
                <label class="block text-left font-semibold text-gray-700">{{ $_('MySQL user') }}:</label>
            </td>
        </tr>
        <tr>
            <td class="py-1">
                {{  $store.getters["mysqlUser"] }}
            </td>
            <td>
                <CopyIcon :text="$store.getters['mysqlUser']" :message="$_('MySQL user name copied to clipboard.')"/>
            </td>
        </tr>
        <tr>
            <td colspan="2" class="pb-1 pt-2">
                <label class="block text-left font-semibold text-gray-700">{{ $_('MySQL password') }}:</label>
            </td>
        </tr>
        <tr>
            <td class="py-1">
                {{  $store.getters["mysqlPassword"] }}
            </td>
            <td>
                <CopyIcon :text="$store.getters['mysqlPassword']" :message="$_('MySQL password copied to clipboard.')"/>
            </td>
        </tr>
        <tr>
            <td colspan="2">
                <div class="mt-1">
                    <Button severity="secondary" icon="pi pi-pencil" :label="$_('Change password')" size="small" @click="$refs['databasePassword'].show('mysql')"/>
                </div>
            </td>
        </tr>
    </table>
    <table cellpadding="0" cellspacing="0" v-else>
        <tr>
            <td colspan="2" class="py-1">
                <label class="block text-left font-semibold text-gray-700">{{ $_('Postgres server') }}:</label>
            </td>
        </tr>
        <tr>
            <td class="py-1">
                {{  $store.getters["postgresHost"] }}
            </td>
            <td>
                <CopyIcon :text="$store.getters['postgresHost']" :message="$_('Postgres server name copied to clipboard.')"/>
            </td>
        </tr>
        <tr>
            <td colspan="2" class="pb-1 pt-2">
                <label class="block text-left font-semibold text-gray-700">{{ $_('Postgres user') }}:</label>
            </td>
        </tr>
        <tr>
            <td class="py-1">
                {{  $store.getters["postgresUser"] }}
            </td>
            <td>
                <CopyIcon :text="$store.getters['postgresUser']" :message="$_('Postgres user name copied to clipboard.')"/>
            </td>
        </tr>
        <tr>
            <td colspan="2" class="pb-1 pt-2">
                <label class="block text-left font-semibold text-gray-700">{{ $_('Postgres password') }}:</label>
            </td>
        </tr>
        <tr>
            <td class="py-1">
                {{  $store.getters["postgresPassword"] }}
            </td>
            <td>
                <CopyIcon :text="$store.getters['postgresPassword']" :message="$_('Postgres password copied to clipboard.')"/>
            </td>
        </tr>
        <tr>
            <td colspan="2">
                <div class="mt-1">
                    <Button severity="secondary" icon="pi pi-pencil" :label="$_('Change password')" size="small" @click="$refs['databasePassword'].show('postgres')"/>
                </div>
            </td>
        </tr>
    </table>
    <div class="flex justify-content-center gap-2 mt-4">
        <Button type="button" :label="$_('Close window')" severity="secondary" @click="added = false"></Button>
    </div>
</Dialog>
<DatabasePasswordDialog ref="databasePassword"/>
</div>
</template>

<script>

import CopyIcon from "../../components/CopyIcon.vue";
import DatabasePasswordDialog from "../database/DatabasePasswordDialog.vue";

export default {
  name: "DatabaseCreateDialog",
  components: {
    CopyIcon,
    DatabasePasswordDialog,
  },
  data() {
    return {
      visible: false,
      added: false,
      name: "",
      type: "",
      nameInvalid: false,
      loading: false,
      error: "",
    }
  },
  computed: {
    types() {
        var ret = [{ label: "MySQL", value: "mysql" }];
        if(this.$store.getters["postgresAvailable"])
            ret.push({ label: "Postgres", value: "postgres" });
        return ret;
    }
  },
  methods: {
    show(type) {
        this.name = "";
        this.nameInvalid = false;
        this.type = type;
        this.visible = true;
        this.added = false;
        this.error = "";
        this.$nextTick(() => {
            this.$refs["input"].$el.focus();
        });
    },
    hide() {
        this.visible = false;
    },
    action() {
        this.nameInvalid = false;
        this.error = "";
        
        if(!this.name.trim().length || this.name.trim().match(/[^a-zA-Z0-9_]/) || this.name.trim().length > 40) {
            this.nameInvalid = true;
            return;
        }
        this.$store.getters["databases"].forEach((item) => {
            if(item.name.toLowerCase() == this.$store.getters["login"] + "_" + this.name.trim().toLowerCase() && item.type == this.type) {
                this.error = this.$_('You have already created this database.')
            }
        });
        if(this.error) return;

        this.loading = true;
        this.$store.dispatch("databaseCreate", { 
            name: this.$store.getters["login"] + "_" + this.name.toLowerCase().trim(),
            type: this.type
        }).then(() => {
            this.loading = false;
            this.visible = false;
            this.added = !this.$store.getters["hideDatabaseConfig"];
            this.$refs["toast"].add({
                severity: 'success', 
                summary: this.$_("Operation performed successfully."), 
                detail: this.$_("Database was created") + ": " + this.$store.getters["login"] + "_" + this.name.toLowerCase().trim(), 
                life: 3000
            });
        }).catch((error) => {
            switch(error.code) {
                default:
                    this.error = this.$_("Unknown error.");
            }
            this.loading = false;
        });
    }
  },
  watch: {
    name() { this.nameInvalid = false; },
    added(value) {
        if(!value && this.dontShow) {
            this.$store.dispatch("displaySettings", {
               hideDomainConfig: this.$store.getters["hideDatabaseConfig"],
               hideDatabaseConfig: true,
               hideEmailConfig: this.$store.getters["hideEmailConfig"],
            });
        }
    }
  }
}
</script>