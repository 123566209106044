const en = require("../translations/en.js")
const pl = require("../translations/pl.js")

export default {
    install (app) {
      app.config.globalProperties.$_ = function $_(text) {
        const language = this.$store.getters["locale"];
        var strings;
        switch(language) {
          case "pl":
            strings = pl.strings;
            break;
          default:
            strings = en.strings;
        }
        if(text in strings)
          return strings[text];
        else
          return text;
      };
    }
  }
  