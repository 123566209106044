
export const cron = {

    computed: {
        cronTypes() {
            return [
                { name: this.$_('Every minute'), setting: '* * * * *' },
                { name: this.$_('Every 3 minutes'), setting: '*/3 * * * *' },
                { name: this.$_('Every 5 minutes'), setting: '*/5 * * * *' },
                { name: this.$_('Every 10 minutes'), setting: '*/10 * * * *' },
                { name: this.$_('Every 15 minutes'), setting: '*/15 * * * *' },
                { name: this.$_('Every hour'), setting: '0 * * * *' },
                { name: this.$_('Every day at midnight'), setting: '0 0 * * *' },
                { name: this.$_('Every day at noon'), setting: '0 12 * * *' },
                { name: this.$_('On server restart'), setting: '@reboot' },
                { name: this.$_('Custom settings'), setting: '_' },
            ]
        },
    },

    methods: {
        cronType(setting) {
            for(let i = 0; i < this.cronTypes.length; i++) {
                if(this.cronTypes[i].setting == setting)
                    return this.cronTypes[i].name;
            }
            return this.$_('Custom') + ": " + setting;
        },
    }
}
  
