export default {
    install (app) {
      app.config.globalProperties.$bubble = function $bubble(eventName, ...args) {
        // Emit the event on all parent components
        let component = this;
        do {
          component.$emit(eventName, ...args);
          component = component.$parent;
        } while (component);
      };
    }
  }
  