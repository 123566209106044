<template>
    <div>
    <Toast ref="toast"/>
    <Dialog 
        v-model:visible="visible" 
        modal
        :draggable="false"
        :header="$_('Delete the database')"
        class="w-full lg:w-4 mx-2"
        >
        <div v-if="error">
            <Message severity="error" class="mt-0" :closable="false">{{ error }}</Message>
        </div>
        <div v-else>
            <Message severity="info" class="mt-0" :closable="false">
                {{  $_('All the database data will be deleted.') }}
            </Message>
        </div>
        <div v-if="scriptName">
            <Message severity="warn" class="mt-0" :closable="false">
                {{ $_('This database is used by %1!').replace('%1', scriptName) }}
            </Message>
        </div>
        <div>
            <label class="block text-left font-semibold text-gray-700 mb-1">{{ $_('Database name') }}:</label>
            <div>
                {{ name }}
            </div>
        </div>
        <div class="mt-3">
            <div class="flex align-items-center">
                <Checkbox v-model="remove" inputId="deleteDatabase" name="remove" value="true"></Checkbox>
                <label for="deleteDatabase" class="ml-2">{{  $_('Yes, delete the database with all its data.') }}</label>
            </div>
        </div>
        <div class="flex justify-content-between gap-2 mt-4">
            <Button type="button" :label="$_('Cancel')" severity="secondary" @click="hide"></Button>
            <Button type="button" :label="$_('Delete database')" severity="danger" :disabled="!remove.length" @click="action" :loading="loading"></Button>
        </div>
    </Dialog>
    </div>
</template>
    
<script>
    export default {
      name: "DatabaseDeleteDialog",
      data() {
        return {
          visible: false,
          name: "",
          type: "",
          remove: [],
          loading: false,
          error: "",
        }
      },
      computed: {
        scriptName() {
            var ret = null;
            if(this.type == 'mysql') {
                this.$store.getters['scripts'].forEach((item) => {
                    if(item.database == this.name) {
                        this.$store.getters['availableScripts'].forEach((item2) => {
                            if(item.type == item2.type) ret = item2.name;
                        });
                    }
                });
            }
            return ret;
        },
      },
      methods: {
        show(name, type) {
            this.name = name;
            this.type = type;
            this.remove = [];
            this.error = "";
            this.visible = true;
        },
        hide() {
            this.visible = false;
        },
        action() {
            this.error = "";
            
            this.loading = true;
            this.$store.dispatch("databaseDelete", { 
                name: this.name,
                type: this.type
            }).then(() => {
                this.loading = false;
                this.visible = false;
                this.$refs["toast"].add({
                    severity: 'success', 
                    summary: this.$_("Operation performed successfully."), 
                    detail: this.$_("Database was deleted") + ": " + this.name, 
                    life: 3000
                });
            }).catch((error) => {
                switch(error.code) {
                    default:
                        this.error = this.$_("Unknown error.");
                }
                this.loading = false;
            });
        }
      },
    }
    </script>